<template>
  <v-fade-transition mode="out-in">
    <div class="gfk-vendor-contract-detail">
      <msal-functions :ref="refMsalFunction"></msal-functions>
      <div class="gfk-vendor-contract-detail-loading" v-if="isPageLoading">
        <v-progress-circular
          :width="5"
          color="grey"
          indeterminate
          :size="30"
        ></v-progress-circular>
        <div
          class="grey--text"
          :style="{
            'margin-top': '12px',
            'font-size': '14px'
          }"
        >
          Loading
        </div>
      </div>
      <v-layout wrap v-else>
        <v-flex
          xs12
          lg8
          order-xs2
          pt-0
          pb-0
        >
          <material-app-card
            id="gfk-vendor-contract-type"
            color="primary"
            icon="mdi-toolbox-outline"
            :title="(isPendingRequest ? '1.0 ' : '') + 'Request Type'"
          >
            <template v-slot>
              <v-form ref="gfk-vendor-contract-type-form" :lazy-validation="true">
                <v-layout row wrap class="v-card-x-layout">
                  <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-2 class="v-card-x-cell">
                    <div style="margin-top: -16px">
                      <v-select
                        v-model="request.type"
                        :items="requestTypesForSelect"
                        :disabled="!canEditRequestType"
                        label="Request Type"
                        ref="request_form_type"
                        item-text="text"
                        item-value="value"
                        :rules="[
                          v => !!requestTypesForSelect.find(el => el.value === v) || 'Please select',
                          v => !!requestTypesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                      >
                      </v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                  <v-flex xs12 pt-1 pl-3 pr-3 pb-2 class="v-card-x-cell"
                    v-if="canSelectGeneratorUsage"
                  >
                    <div style="margin-top: -16px">
                      <v-radio-group class="gfk-vendor-contract-radio-group-vertical"
                        :disabled="!canEditRequestType"
                        v-model="request.generatorUsage"
                        :rules="[v => (v === true || v === false) || 'Please select']"
                      >
                        <v-radio :value="true" color="primary">
                          <template v-slot:label>
                            <span>Contract Generator: vendor accept to use GfK template without any revision.</span>
                          </template>
                        </v-radio>
                        <v-radio :value="false" color="primary">
                          <template v-slot:label>
                            <span>Vendor request to use vendor template/ vendor request revision regarding to GfK template</span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                    <div
                      tabindex="0"
                      ref="request_form_generator_usage"
                      style="height: 1px;"
                    ></div>
                  </v-flex>
                  <template v-if="!isPageLoading && canEditRequestType">
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div class="bottom-btn-container">
                        <v-btn color="error lighten-1"
                          v-if="isNewRequest"
                          @click="clickCancelNewRequest"
                        >
                          Cancel
                        </v-btn>
                      </div>
                    </v-flex>
                    <template>
                      <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                        <div class="bottom-btn-container-right">
                          <v-btn color="primary"
                            @click="clickNextDraftStep"
                          >
                            Next
                          </v-btn>
                        </div>
                      </v-flex>
                    </template>
                  </template>
                </v-layout>
              </v-form>
            </template>
          </material-app-card>

          <material-app-card
            id="gfk-vendor-contract-drafted-contract"
            color="primary"
            icon="mdi-text-box-check-outline"
            v-if="(!isPendingRequest || isDraftStepInDraftedContract || isDraftStepInContractInfo || isDraftStepInCyberSecurity || isDraftStepInOtherSupportInfo || isDraftStepInApprovalInfo) && !request.generatorUsage"
            :title="isPendingRequest ? '2.0 Upload Drafted Contract' : 'Drafted Contract'"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pt-3 pl-3 px-4 class="v-card-x-cell">
                  <span
                    >Please ONLY upload the documents you need to sign. (Other supporting document you can upload with "comments")
                  </span>
                </v-flex>
                <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell" v-if="canEditDraftedContract">
                  <div style="width: 100%;display: flex;align-items: center;">
                    <x-upload
                      request-class="file"
                      request-function="upload"
                      accept=".doc,.docx,.pdf"
                      :params="{
                        email: userEmail,
                        loginStatus: loginStatus
                      }"
                      :before-upload="beforeUploadDraftedContract"
                      :on-error="errorUploadDraftedContract"
                      :on-success="successUploadDraftedContract"
                    >
                      <v-btn text
                        style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                      >
                        <v-icon color="primary" size="24" style="margin-right:12px;">
                          mdi-upload
                        </v-icon>
                        <div v-if="request.draftedContract.length > 0">Upload more <b>Vendor Contract</b></div>
                        <div v-else>Please upload <b>Vendor Contract</b></div>
                      </v-btn>
                    </x-upload>
                  </div>
                </v-flex>
                <v-flex xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                  <file-list
                    :files="request.draftedContract"
                    :deletable="canEditDraftedContract"
                    :multiple-rows="windowWidth < 650"
                    @click-download="downloadFile"
                    @click-delete="deleteDraftedContract"
                  ></file-list>
                  <div
                    tabindex="0"
                    ref="gfk_vendor_contract_form_draft_contract"
                    style="height: 1px;"
                  ></div>
                </v-flex>
                <template v-if="!isPageLoading && canEditDraftedContract">
                  <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div class="bottom-btn-container">
                      <v-btn color="primary"
                        @click="clickPrevDraftStep"
                      >
                        Previous
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div class="bottom-btn-container-right">
                      <v-btn color="primary"
                        :disabled="!request.draftedContract.length"
                        @click="clickNextDraftStep"
                      >
                        Next
                      </v-btn>
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            id="gfk-vendor-contract-form-card"
            color="primary"
            icon="mdi-file-document-edit-outline"
            v-if="(!isPendingRequest || isDraftStepInContractInfo || isDraftStepInGeneratedContract || isDraftStepInCyberSecurity || isDraftStepInOtherSupportInfo || isDraftStepInApprovalInfo) && needForm"
            :title="(isPendingRequest ? (request.generatorUsage ? '2.0' : '2.1') + ' ' : '') + 'Contract Information'"
            :style="{
              'margin-top': '32px'
            }"
          >
            <template v-slot>
              <v-form ref="gfk-vendor-contract-form" :lazy-validation="true">
                <v-layout pb-3 row wrap class="v-card-x-layout">
                  <v-flex xs12
                    v-if="isPageLoading"
                    :style="{
                      margin: '12px',
                      display: 'flex',
                      'align-items': 'center',
                      'justify-content': 'center'
                    }"
                  >
                    <v-progress-circular
                      :width="4"
                      color="grey"
                      indeterminate
                      :size="24"
                    ></v-progress-circular>
                    <div
                      class="grey--text text--lighten-1"
                      :style="{
                        'margin-left': '9px'
                      }"
                    >
                      Loading
                    </div>
                  </v-flex>
                  <template v-else>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needProductType"
                    >
                      <v-select
                        :disabled="!canEditRequestForm || disabledProductType"
                        label="Product Type"
                        :items="productTypesForSelect"
                        item-text="text"
                        v-model="request.productType"
                        ref="gfk_vendor_contract_form_gfk_entity_name"
                        :rules="[
                          v => !!productTypesForSelect.find(el => el.value === v) || 'Please select',
                          v => !!productTypesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needContractType"
                    >
                      <v-select
                        :disabled="!canEditRequestForm"
                        label="Contract Type"
                        :items="contractTypesForSelect"
                        item-text="text"
                        v-model="request.contractType"
                        ref="gfk_vendor_contract_form_gfk_entity_name"
                        :rules="[
                          v => !!contractTypesForSelect.find(el => el.value === v) || 'Please select',
                          v => !!contractTypesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                      >
                      </v-select>
                    </v-flex>
                    <!-- <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needSubProductType"
                    >
                      <v-select
                        :disabled="!canEditRequestForm"
                        label="Sub Product Type"
                        :items="subProductTypesForSelect"
                        item-text="text"
                        v-model="request.subProductType"
                        ref="gfk_vendor_contract_form_gfk_entity_name"
                        :rules="[
                          v => !!subProductTypesForSelect.find(el => el.value === v) || 'Please select',
                          v => !!subProductTypesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                      >
                      </v-select>
                    </v-flex> -->
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needLanguage"
                    >
                      <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(isRequestInfoChecked && !request.language ? ['red--text'] : []), ...((canEditRequestForm && !disabledLanguage) ? [] : ['my-disabled--text'])]">Language</div>
                      <div>
                        <v-radio-group row class="gfk-vendor-contract-radio-group-horizonal"
                          :disabled="!canEditRequestForm || disabledLanguage"
                          v-model="request.language"
                          :rules="[v => (!!v && v != '') || 'Please select']"
                        >
                          <v-radio label="English" value="English" color="primary"></v-radio>
                          <v-radio label="中文" value="Chinese" color="primary"></v-radio>
                        </v-radio-group>
                      </div>
                      <div
                        tabindex="0"
                        ref="gfk_vendor_contract_form_language"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needCategory"
                    >
                      <v-select
                        :disabled="!canEditRequestForm"
                        label="Category"
                        :items="categoryForSelect"
                        item-text="text"
                        v-model="request.category"
                        ref="gfk_vendor_contract_form_gfk_entity_name"
                        :rules="[
                          v => !!categoryForSelect.find(el => el.value === v) || 'Please select',
                          v => !!categoryForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needContractEffectiveDate"
                    >
                      <div>
                        <v-dialog
                          ref="gfk_vendor_contract_form_contract_effective_date"
                          v-model="isPickerContractEffectiveDateDisplayed"
                          :return-value.sync="request.contractEffectiveDate"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="request.contractEffectiveDate"
                              label="Contract Effective Date"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              :disabled="!canEditRequestForm"
                              ref="gfk_vendor_contract_form_contract_effective_date_text_field"
                              :rules="[v => (!!v && v != '') || 'Please select']"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="request.contractEffectiveDate" scrollable color="primary" header-color="primary"
                            :allowed-dates="allowedContractEffectiveDates"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" class="mb-2" @click="isPickerContractEffectiveDateDisplayed = false">CANCEL</v-btn>
                            <v-btn dark color="primary" class="mb-2" @click="clickOkContractEffectiveDate">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needGfkEntityName"
                    >
                      <v-select
                        :disabled="!canEditRequestForm"
                        label="GfK Entity Name"
                        :items="salesOrgsForSelect"
                        :item-text="needLanguage ? request.language === 'Chinese' ? 'textCn' : 'textEn' : 'text'"
                        :placeholder="salesOrgPlaceholder"
                        v-model="request.gfkEntityName"
                        ref="gfk_vendor_contract_form_gfk_entity_name"
                        :rules="[
                          v => !!salesOrgsForSelect.find(el => el.value === v) || 'Please select',
                          v => !!salesOrgsForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                        @change="changeGfkEntityName"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div
                        v-if="needVendorEntityName"
                      >
                        <v-combobox
                          :disabled="!canEditRequestForm"
                          label="Vendor Entity Name"
                          ref="gfk_vendor_contract_form_vendor_entity_name"
                          no-filter
                          :search-input.sync="vendorEntityNameKey"
                          v-model="request.vendorEntityName"
                          :items="vendorEntityNameItems"
                          :rules="[v => !!v || 'Please input and select']"
                          @change="changeVendorEntityName"
                          @blur="() => request.vendorEntityName = vendorEntityNameKey"
                        ></v-combobox>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-2 class="v-card-x-cell"
                      v-if="needTaxRate"
                    >
                      <v-select
                        v-model="request.taxRate"
                        :items="taxRatesForSelect"
                        :disabled="!canEditRequestForm"
                        label="Tax Rate"
                        item-text="value"
                        ref="gfk_vendor_contract_form_tax_rate"
                        :rules="[
                          v => !!taxRatesForSelect.find(el => el.value === v) || 'Please select',
                          v => !!taxRatesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                        @change="changeTaxRate"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-2 class="v-card-x-cell"
                      v-if="needInvoiceType"
                    >
                      <v-select
                        v-model="request.invoiceType"
                        :items="invoiceTypesForSelect"
                        :disabled="!canEditRequestForm"
                        label="Invoice Type"
                        :item-text="isLanguageChinese ? 'cn' : 'en'"
                        ref="gfk_vendor_contract_form_invoice_type"
                        :rules="[
                          v => !!invoiceTypesForSelect.find(el => el.value === v) || 'Please select',
                          v => !!invoiceTypesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                        @change="changeInvoiceType"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-2 class="v-card-x-cell"
                      v-if="needBillingCondition"
                    >
                      <v-combobox
                        v-model="request.billingCondition"
                        :search-input.sync="billingConditionKey"
                        :items="billingConditionsForSelect"
                        :disabled="!canEditRequestForm"
                        label="Billing Condition"
                        :item-value="isLanguageChinese ? 'cn' : 'en'"
                        :item-text="isLanguageChinese ? 'cn' : 'en'"
                        ref="gfk_vendor_contract_form_billing_condition"
                        :return-object="false"
                        :rules="[
                          v => !!v || 'Please select or input'
                        ]"
                        @change="changeBillingCondition"
                        @blur="() => request.billingCondition = billingConditionKey"
                      >
                        <template v-slot:append-item>
                          <div class="grey--text" style="margin: 15px 15px 12px; text-align:left;">
                            Or enter other billing condition
                          </div>
                        </template>
                      </v-combobox>
                    </v-flex>
                    <v-flex xs12 sm6 pt-3 pl-3 pr-4 pb-2 class="v-card-x-cell"
                      v-if="needPaymentTerm"
                    >
                      <v-combobox
                        v-model="request.paymentTerm"
                        :search-input.sync="paymentTermKey"
                        :items="paymentTermsForSelect"
                        :disabled="!canEditRequestForm"
                        label="Payment Term"
                        type="number"
                        item-value="value"
                        item-text="value"
                        ref="gfk_vendor_contract_form_payment_term_select"
                        :return-object="false"
                        :rules="[
                          v => !!v || 'Please select or input',
                          v => (!!v && v.indexOf('.') === -1) || 'Please input integer'
                        ]"
                        @change="changePaymentTerm"
                        @blur="() => request.paymentTerm = paymentTermKey"
                      >
                        <template v-slot:append-outer>
                          <div style="margin-top: 6px;">
                            DAYS
                          </div>
                        </template>
                        <template v-slot:append-item>
                          <div class="grey--text" style="margin: 15px 15px 12px; text-align:left;">
                            Or enter other number of days
                          </div>
                        </template>
                      </v-combobox>
                    </v-flex>
                    <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needNameOfServiceProduct"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.nameOfServiceProduct ? ['red--text'] : [])]">Name of Service/ Product</div>
                      <div class="title-solo-textarea">
                        <v-textarea
                          solo
                          no-resize
                          rows="2"
                          placeholder="Please input"
                          style="margin-top: 0px"
                          :counter="maxLenNameOfServiceProduct"
                          v-model="request.nameOfServiceProduct"
                          :readonly="!canEditRequestForm"
                          ref="gfk_vendor_contract_form_name_of_service_product"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                          @input="changeNameOfServiceProduct"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needSpecificationOfServiceProduct"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.specificationOfServiceProduct ? ['red--text'] : [])]">Specification of Service/ Product</div>
                      <div class="title-solo-textarea">
                        <v-textarea
                          solo
                          no-resize
                          rows="2"
                          placeholder="Please input"
                          style="margin-top: 0px"
                          :counter="maxLenSpecificationOfServiceProduct"
                          v-model="request.specificationOfServiceProduct"
                          :readonly="!canEditRequestForm"
                          ref="gfk_vendor_contract_form_specification_of_service_product"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                          @input="changeSpecificationOfServiceProduct"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needServiceProductDeliveryPlace"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.serviceProductDeliveryPlace ? ['red--text'] : [])]">Service/Product Delivery Place</div>
                      <div class="title-solo-textarea">
                        <v-combobox
                          :disabled="!canEditRequestForm"
                          solo
                          ref="gfk_vendor_contract_form_service_product_delivery_place"
                          v-model="request.serviceProductDeliveryPlace"
                          :search-input.sync="serviceProductDeliveryPlaceKey"
                          append-icon=""
                          :items="arrayGfkAddress"
                          :return-object="false"
                          :item-value="isLanguageChinese ? 'cn' : 'en'"
                          :item-text="isLanguageChinese ? 'cn' : 'en'"
                          :rules="[v => !!v || 'Please input or select']"
                          @blur="() => request.serviceProductDeliveryPlace = serviceProductDeliveryPlaceKey"
                        >
                          <template v-slot:prepend-item>
                            <div class="grey--text" style="margin: 15px 15px 12px; text-align:left;">
                              Please input or select
                            </div>
                          </template>
                        </v-combobox>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needServiceProductDeliveryTime"
                    >
                      <div>
                        <v-dialog
                          ref="gfk_vendor_contract_form_service_product_delivery_time"
                          v-model="isPickerServiceProductDeliveryTimeDisplayed"
                          :return-value.sync="request.serviceProductDeliveryTime"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="request.serviceProductDeliveryTime"
                              label="Service/Product Delivery Time"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              :disabled="!canEditRequestForm"
                              ref="gfk_vendor_contract_form_service_product_delivery_time_text_field"
                              :rules="[v => (!!v && v != '') || 'Please select']"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="request.serviceProductDeliveryTime" scrollable color="primary" header-color="primary"
                            :allowed-dates="allowedServiceProductDeliveryTime"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" class="mb-2" @click="isPickerServiceProductDeliveryTimeDisplayed = false">CANCEL</v-btn>
                            <v-btn dark color="primary" class="mb-2" @click="clickOkServiceProductDeliveryTime">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needProjectName"
                    >
                      <v-text-field
                        v-model="request.projectName"
                        label="Project Name"
                        ref="gfk_vendor_contract_form_project_name"
                        :disabled="!canEditRequestForm"
                        :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                        @input="changeProjectName"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needServiceProductStartDate"
                    >
                      <div>
                        <v-dialog
                          ref="gfk_vendor_contract_form_service_product_start_date"
                          v-model="isPickerServiceProductStartDateDisplayed"
                          :return-value.sync="request.serviceProductStartDate"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="request.serviceProductStartDate"
                              label="Service/Product Start Date"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              :disabled="!canEditRequestForm"
                              ref="gfk_vendor_contract_form_service_product_start_date_text_field"
                              :rules="[v => (!!v && v != '') || 'Please select']"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="request.serviceProductStartDate" scrollable color="primary" header-color="primary"
                            :allowed-dates="allowedServiceProductStartDate"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" class="mb-2" @click="isPickerServiceProductStartDateDisplayed = false">CANCEL</v-btn>
                            <v-btn dark color="primary" class="mb-2" @click="clickOkServiceProductStartDate">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needServiceProductCompleteDate"
                    >
                      <div>
                        <v-dialog
                          ref="gfk_vendor_contract_form_service_product_complete_date"
                          v-model="isPickerServiceProductCompleteDateDisplayed"
                          :return-value.sync="request.serviceProductCompleteDate"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="request.serviceProductCompleteDate"
                              label="Service/Product Complete Date"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              :disabled="!canEditRequestForm"
                              ref="gfk_vendor_contract_form_service_product_complete_date_text_field"
                              :rules="[v => (!!v && v != '') || 'Please select']"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="request.serviceProductCompleteDate" scrollable color="primary" header-color="primary"
                            :allowed-dates="allowedServiceProductCompleteDate"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" class="mb-2" @click="isPickerServiceProductCompleteDateDisplayed = false">CANCEL</v-btn>
                            <v-btn dark color="primary" class="mb-2" @click="clickOkServiceProductCompleteDate">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needEstimatedContractValue"
                    >
                      <div class="v-card-contract-amount">
                        <v-text-field
                          append-icon="mdi-pencil"
                          key="gfk_vendor_contract_estimated_contract_value"
                          :label="'Estimated Contract Value (Include Tax)' + (!!estimatedContractValueToThousands ? ': ' + estimatedContractValueToThousands : '')"
                          type="number"
                          :disabled="!canEditRequestForm"
                          v-model="request.estimatedContractValue"
                          @blur="blurEstimatedContractValue"
                          ref="gfk_vendor_contract_form_estimated_contract_value"
                          :rules="[
                            v => !isNaN(parseFloat(v)) || 'Please input number',
                            v => parseFloat(v) > 0 || 'Amount should be greater than 0'
                          ]"
                          @input="changeEstimatedContractValue"
                        >
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needContractValueIncludeTax"
                    >
                      <div class="v-card-contract-amount">
                        <v-text-field
                          append-icon="mdi-pencil"
                          key="gfk_vendor_contract_contract_value"
                          :label="'Contract Value (Include Tax)' + (!!contractValueIncludeTaxToThousands ? ': ' + contractValueIncludeTaxToThousands : '')"
                          type="number"
                          :disabled="!canEditRequestForm"
                          v-model="request.contractValueIncludeTax"
                          @blur="blurContractValueIncludeTax"
                          ref="gfk_vendor_contract_form_contract_value_include_tax"
                          :rules="[
                            v => !isNaN(parseFloat(v)) || 'Please input number',
                            v => parseFloat(v) > 0 || 'Amount should be greater than 0'
                          ]"
                          @input="changeContractValueIncludeTax"
                        >
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needCurrency"
                    >
                      <v-select
                        :disabled="!canEditRequestForm"
                        label="Currency"
                        :items="currenciesForSelect"
                        item-text="text"
                        v-model="request.currency"
                        ref="gfk_vendor_contract_form_currency"
                        :rules="[
                          v => !!currenciesForSelect.find(el => el.value === v) || 'Please select',
                          v => !!currenciesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                        @change="changeCurrency"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needOtherRequirements"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.otherRequirements ? ['red--text'] : [])]">Other Requirements</div>
                      <div class="title-solo-textarea">
                        <v-textarea
                          solo
                          no-resize
                          rows="3"
                          placeholder="Please input"
                          style="margin-top: 0px"
                          :counter="maxLenOtherRequirements"
                          v-model="request.otherRequirements"
                          :readonly="!canEditRequestForm"
                          ref="gfk_vendor_contract_form_other_requirements"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                          @input="changeOtherRequirements"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needConfirmPersonalInfo"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && (typeof request.isObtainPersonalInfo !== 'number') ? ['red--text'] : []), ...(canEditRequestForm ? [] : ['my-disabled--text'])]">
                        Does this vendor obtain personal information from GfK?<br/>
                        该供应商从GfK 处获得任何个人信息吗？
                      </div>
                      <div class="mt-n5">
                        <v-radio-group row class="gfk-vendor-contract-radio-group-horizonal"
                          :disabled="!canEditRequestForm"
                          v-model="request.isObtainPersonalInfo"
                          :rules="[v => (typeof v === 'number') || 'Please select']"
                        >
                          <v-radio label="Yes" :value="1" color="primary"></v-radio>
                          <v-radio label="No" :value="0" color="primary"></v-radio>
                        </v-radio-group>
                      </div>
                      <div
                        tabindex="0"
                        ref="gfk_vendor_contract_generator_form_language"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needConfirmPersonalInfo"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && (typeof request.isAuthorizePersonalInfo !== 'number') ? ['red--text'] : []), ...(canEditRequestForm ? [] : ['my-disabled--text'])]">
                        Does this vendor authorized by GfK to process personal information？<br/>
                        该供应商受GfK 委托处理个人信息吗？
                      </div>
                      <div class="mt-n5">
                        <v-radio-group row class="gfk-vendor-contract-radio-group-horizonal"
                          :disabled="!canEditRequestForm"
                          v-model="request.isAuthorizePersonalInfo"
                          :rules="[v => (typeof v === 'number') || 'Please select']"
                        >
                          <v-radio label="Yes" :value="1" color="primary"></v-radio>
                          <v-radio label="No" :value="0" color="primary"></v-radio>
                        </v-radio-group>
                      </div>
                      <div
                        tabindex="0"
                        ref="gfk_vendor_contract_generator_form_language"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 ma-0 pa-0 />
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needGfkContactAddress"
                    >
                      <v-combobox
                        id="form-text-field-gfk-contact-address"
                        v-model="request.gfkContactAddress"
                        label="GfK Contact Address"
                        :disabled="!canEditRequestForm"
                        :search-input.sync="gfkContactAddressKey"
                        append-icon=""
                        :items="arrayGfkAddress"
                        :return-object="false"
                        :item-value="isLanguageChinese ? 'cn' : 'en'"
                        :item-text="isLanguageChinese ? 'cn' : 'en'"
                        :rules="[v => (!!v && v.toString().trim() != '') || 'Please input or select']"
                        @blur="() => request.gfkContactAddress = gfkContactAddressKey"
                      >
                        <template v-slot:prepend-item>
                          <div class="grey--text" style="margin: 15px 15px 12px; text-align:left;">
                            Please input or select
                          </div>
                        </template>
                      </v-combobox>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needVendorContactAddress"
                    >
                      <v-text-field
                        id="form-text-field-vendor-contact-address"
                        v-model="request.vendorContactAddress"
                        label="Vendor Contact Address"
                        :disabled="!canEditRequestForm"
                        :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needGfkZipCodes"
                    >
                      <v-text-field
                        id="form-text-field-gfk-zip-code"
                        v-model="request.gfkZipCodes"
                        label="GfK Zip Code"
                        :disabled="!canEditRequestForm"
                        :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needVendorZipCodes"
                    >
                      <v-text-field
                        id="form-text-field-vendor-zip-code"
                        v-model="request.vendorZipCodes"
                        label="Vendor Zip Code"
                        :disabled="!canEditRequestForm"
                        :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needGfkContactPerson"
                    >
                      <v-text-field
                        id="form-text-field-gfk-contact-person"
                        v-model="request.gfkContactPerson"
                        label="GfK Contact Person"
                        :disabled="!canEditRequestForm"
                        :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needVendorContactPerson"
                    >
                      <v-text-field
                        id="form-text-field-vendor-contact-person"
                        v-model="request.vendorContactPerson"
                        label="Vendor Contact Person"
                        :disabled="!canEditRequestForm"
                        :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needGfkContactEmail"
                    >
                      <v-text-field
                        id="form-text-field-gfk-contact-email"
                        v-model="request.gfkContactEmail"
                        label="GfK Contact Email"
                        :disabled="!canEditRequestForm"
                        :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needVendorContactEmail"
                    >
                      <v-text-field
                        id="form-text-field-vendor-contact-email"
                        v-model="request.vendorContactEmail"
                        label="Vendor Contact Email"
                        :disabled="!canEditRequestForm"
                        :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="
                        needPersonalDataPurposeAndUse
                        || needPersonalDataSubjectCategory
                        || needPersonalDataProcessType
                        || needPersonalDataProcessTerm
                      "
                    >
                      <div class="v-card-x-cell-title font-weight-bold">
                        Details of Personal Data processing<br/>
                        个人信息处理详情<br/>
                        <span class="grey--text">* 由服务提供方填写</span>
                      </div>
                    </v-flex>
                    <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needPersonalDataPurposeAndUse"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.personalDataPurposeAndUse ? ['red--text'] : [])]">
                        Purpose of processing Personal Data and how to use it<br/>
                        <span class="grey--text">* 例如服务描述中所述之目的</span>
                      </div>
                      <div class="title-solo-textarea mt-n1">
                        <v-textarea
                          solo
                          no-resize
                          rows="3"
                          placeholder="Please input Purpose of processing Personal Data and how to use it"
                          style="margin-top: 0px"
                          :counter="maxLenPersonalDataPurposeAndUse"
                          v-model="request.personalDataPurposeAndUse"
                          :readonly="!canEditRequestForm"
                          ref="gfk_vendor_contract_generator_form_personal_data_purpose_and_use"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 pt-1 pl-3 pr-3 pb-0 class="v-card-x-cell"
                      v-if="needPersonalDataSubjectCategory"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.personalDataSubjectCategory ? ['red--text'] : [])]">
                        Categories of Personal Data Subjects<br/>
                        <span class="grey--text">* 例如GfK调查小组成员、GfK用户、GfK客户、GfK职员等</span>
                      </div>
                      <div class="title-solo-textarea mt-n1">
                        <v-textarea
                          solo
                          no-resize
                          rows="3"
                          placeholder="Please input Categories of Personal Data Subjects"
                          style="margin-top: 0px"
                          :counter="maxLenPersonalDataSubjectCategory"
                          v-model="request.personalDataSubjectCategory"
                          :readonly="!canEditRequestForm"
                          ref="gfk_vendor_contract_generator_form_personal_data_subject_category"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 pt-1 pl-3 pr-3 pb-0 class="v-card-x-cell"
                      v-if="needPersonalDataProcessType"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.personalDataProcessType ? ['red--text'] : [])]">
                        Categories of Personal Data Processed<br/>
                        <span class="grey--text">* 例如姓名、地址、电子邮件地址、登录信息、IP 地址、cookie（包括 cookie ID）、移动广告标识符、统计数据以及 GfK 家庭 ID 和身份证号码等</span>
                      </div>
                      <div class="title-solo-textarea mt-n1">
                        <v-textarea
                          solo
                          no-resize
                          rows="3"
                          placeholder="Please input Categories of Personal Data Subjects"
                          style="margin-top: 0px"
                          :counter="maxLenPersonalDataProcessType"
                          v-model="request.personalDataProcessType"
                          :readonly="!canEditRequestForm"
                          ref="gfk_vendor_contract_generator_form_personal_data_process_type"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needPersonalDataProcessTerm"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.personalDataProcessTerm ? ['red--text'] : [])]">
                        Personal Data processing term<br/>
                        <span class="grey--text">* 例如在采购合同总体条款有效期限内处理个人信息</span>
                      </div>
                      <div class="title-solo-textarea mt-n1">
                        <v-textarea
                          solo
                          no-resize
                          rows="3"
                          placeholder="Please input Categories of Personal Data Subjects"
                          style="margin-top: 0px"
                          :counter="maxLenPersonalDataProcessTerm"
                          v-model="request.personalDataProcessTerm"
                          :readonly="!canEditRequestForm"
                          ref="gfk_vendor_contract_generator_form_personal_data_process_term"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <template v-if="needPersonalDataSubcontractor">
                      <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                        <div class="v-card-x-cell-title">
                          Subprocessor
                        </div>
                      </v-flex>
                      <template v-for="(pdsdo, pdsdi) in request.personalDataSubcontractorDetails">
                        <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                          :key="'gfk_vendor_contract_generator_form_personal_data_subcontractor_name' + pdsdi"
                        >
                          <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !pdsdo['name'] ? ['red--text'] : [])]">
                            Subprocessor Name {{ pdsdi + 1 }}
                          </div>
                          <div class="title-solo-textarea mt-n1">
                            <v-textarea
                              solo
                              no-resize
                              rows="3"
                              placeholder="Please input Subprocessor Name"
                              style="margin-top: 0px"
                              :counter="maxLenPersonalDataSubcontractorName"
                              v-model="pdsdo['name']"
                              :readonly="!canEditRequestForm"
                              ref="gfk_vendor_contract_generator_form_personal_data__subcontractor_name"
                              :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                            >
                            </v-textarea>
                          </div>
                        </v-flex>
                        <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                          :key="'gfk_vendor_contract_generator_form_personal_data_subcontractor_process_details' + pdsdi"
                        >
                          <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !pdsdo['processDetails'] ? ['red--text'] : [])]">
                            Details of subprocessors' processing of Personal Data {{ pdsdi + 1 }}
                          </div>
                          <div class="title-solo-textarea mt-n1">
                            <v-textarea
                              solo
                              no-resize
                              rows="3"
                              placeholder="Please input Details of subprocessors' processing of Personal Data"
                              style="margin-top: 0px"
                              :counter="maxLenPersonalDataSubcontractorProcessDetails"
                              v-model="pdsdo['processDetails']"
                              :readonly="!canEditRequestForm"
                              ref="gfk_vendor_contract_generator_form_personal_data_subcontractor_process_details"
                              :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                            >
                            </v-textarea>
                          </div>
                        </v-flex>
                        <v-flex xs12 :key="'gfk_vendor_contract_generator_form_personal_data_subcontractor_del_btn' + pdsdi">
                          <v-btn small text
                            color="error"
                            class="mt-n4 px-3"
                            v-show="canEditRequestForm && request.personalDataSubcontractorDetails.length > 1"
                            @click="clickRemoveSubcontractor(pdsdi)"
                          >
                            <v-icon>mdi-minus</v-icon>
                            <span class="ml-1">Remove Subprocessor {{ pdsdi + 1 }}</span>
                          </v-btn>
                        </v-flex>
                      </template>
                      <v-flex xs12 v-show="canEditRequestForm">
                        <v-btn small text
                          color="primary"
                          class="mt-n1 px-3"
                          v-if="request.personalDataSubcontractorDetails.length < 7"
                          @click="clickAddSubcontractor"
                        >
                          <v-icon>mdi-plus</v-icon>
                          <span class="ml-1">Add Subprocessor</span>
                        </v-btn>
                        <div v-else class="grey--text mt-n1 mx-4" style="font-size: 12px">
                          Sorry. We only support inserting maximum 7 subprocessors for now.<br/>
                        </div>
                      </v-flex>
                    </template>

                    <v-flex xs12 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell"
                      v-if="request.generatorUsage && needAdditionalAttachment"
                    >
                      <div v-if="canEditRequestForm" style="width: 100%;display: flex;align-items: center;">
                        <x-upload
                          request-class="file"
                          request-function="upload"
                          accept=".docx"
                          :params="{
                            email: userEmail,
                            loginStatus: loginStatus
                          }"
                          :before-upload="beforeUploadAdditionalAttachment"
                          :on-error="errorUploadAdditionalAttachment"
                          :on-success="successUploadAdditionalAttachment"
                        >
                          <v-btn text
                            v-if="request.additionalAttachments.length === 0"
                            style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                          >
                            <v-icon color="primary" size="24" style="margin-right:12px;">
                              mdi-upload
                            </v-icon>
                            <div>Upload <b>Additional Attachment</b> (Word .DOCX Only) (If Any)</div>
                          </v-btn>
                        </x-upload>

                        <v-btn text
                          v-if="request.additionalAttachments.length > 0"
                          style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                          @click="isAdditionalAttachmentUploadConfirming = true"
                        >
                          <v-icon color="primary" size="24" style="margin-right:12px;">
                            mdi-upload
                          </v-icon>
                          <div>Change <b>Additional Attachment</b> (Word .DOCX Only)</div>
                        </v-btn>
                      </div>
                      <div
                        v-else-if="request.additionalAttachments.length"
                        class="v-card-x-cell-title pb-1"
                      >
                        Additional Attachment
                      </div>

                      <v-expand-transition>
                        <div 
                          v-if="/* no neccessary */false && isRequestInfoChecked && request.additionalAttachments.length === 0" 
                          class="red--text"
                          style="font-size:12px;"
                        >
                          Please upload Additional Attachment
                        </div>
                      </v-expand-transition>
                      
                      <div style="padding-bottom: 16px;">
                        <file-list
                          :files="request.additionalAttachments"
                          :deletable="canEditRequestForm"
                          :multiple-rows="windowWidth < 650"
                          @click-download="downloadFile"
                          @click-delete="deleteAdditionalAttachment"
                        ></file-list>
                        <div
                          tabindex="0"
                          ref="gfk_vendor_contract_additional_attachment"
                          style="height: 1px;"
                        ></div>
                      </div>
                    </v-flex>

                    <v-flex xs12 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell"
                      v-if="request.generatorUsage && needAttachmentThirteen"
                    >
                      <div
                        v-if="!canEditRequestForm"
                        class="v-card-x-cell-title pb-1"
                      >
                        Attachment 13
                      </div>
                      <div v-else style="width: 100%;display: flex;align-items: center;">
                        <x-upload
                          request-class="file"
                          request-function="upload"
                          accept=".docx"
                          :params="{
                            email: userEmail,
                            loginStatus: loginStatus
                          }"
                          :before-upload="beforeUploadAttachmentThirteen"
                          :on-error="errorUploadAttachmentThirteen"
                          :on-success="successUploadAttachmentThirteen"
                        >
                          <v-btn text
                            v-if="request.attachmentThirteens.length === 0"
                            style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                          >
                            <v-icon color="primary" size="24" style="margin-right:12px;">
                              mdi-upload
                            </v-icon>
                            <div>Upload <b>Attachment 13</b> (Word .DOCX Only)</div>
                          </v-btn>
                        </x-upload>

                        <v-btn text
                          v-if="request.attachmentThirteens.length > 0"
                          style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                          @click="isAttachmentThirteenUploadConfirming = true"
                        >
                          <v-icon color="primary" size="24" style="margin-right:12px;">
                            mdi-upload
                          </v-icon>
                          <div>Change <b>Attachment 13</b> (Word .DOCX Only)</div>
                        </v-btn>
                      </div>

                      <v-expand-transition>
                        <div 
                          v-if="isRequestInfoChecked && request.attachmentThirteens.length === 0" 
                          class="red--text"
                          style="font-size:12px;"
                        >
                          Please upload Attachment 13
                        </div>
                      </v-expand-transition>
                      
                      <div style="padding-bottom: 16px;">
                        <file-list
                          :files="request.attachmentThirteens"
                          :deletable="canEditRequestForm"
                          :multiple-rows="windowWidth < 650"
                          @click-download="downloadFile"
                          @click-delete="deleteAttachmentThirteen"
                        ></file-list>
                        <div
                          tabindex="0"
                          ref="gfk_vendor_contract_attachment_thirteen"
                          style="height: 1px;"
                        ></div>
                      </div>
                    </v-flex>

                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell"
                      v-if="needOriginalContract"
                    >                    
                      <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(isRequestInfoChecked && !request.originalContractType ? ['red--text'] : []), ...(canEditRequestForm ? [] : ['my-disabled--text'])]">Orignal Signed Contract</div>
                      <div class="pt-1">
                        <v-radio-group row class="gfk-vendor-contract-radio-group-horizonal"
                          :disabled="!canEditRequestForm"
                          v-model="request.originalContractType"
                          :rules="[v => (!!v && v != '') || 'Please select']"
                        >
                          <v-radio label="Upload Contract" value="Upload" color="primary"></v-radio>
                          <v-radio label="Enter Request ID" value="Request" color="primary"></v-radio>
                        </v-radio-group>
                      </div>
                      <div
                        tabindex="0"
                        ref="gfk_vendor_contract_form_original_contract_type"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 pt-0 pl-3 pr-3 pb-0 class="v-card-x-cell"
                      v-if="needOriginalContract && request.originalContractType === 'Upload'"
                    >
                      <div
                        v-if="!canEditRequestForm"
                        class="v-card-x-cell-title pb-1"
                      >
                        Original Signed Contract
                      </div>
                      <div v-else style="width: 100%;display: flex;align-items: center;">
                        <x-upload
                          request-class="file"
                          request-function="upload"
                          accept=".doc,.docx,.pdf"
                          :params="{
                            email: userEmail,
                            loginStatus: loginStatus
                          }"
                          :before-upload="beforeUploadOriginalContract"
                          :on-error="errorUploadOriginalContract"
                          :on-success="successUploadOriginalContract"
                        >
                          <v-btn text
                            v-if="request.uploadedOriginalContracts.length === 0"
                            style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                          >
                            <v-icon color="primary" size="24" style="margin-right:12px;">
                              mdi-upload
                            </v-icon>
                            <div>Upload <b>Original Signed Contract</b></div>
                          </v-btn>
                        </x-upload>

                        <v-btn text
                          v-if="request.uploadedOriginalContracts.length > 0"
                          style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                          @click="isOtherRequiredDataElementsUploadConfirming = true"
                        >
                          <v-icon color="primary" size="24" style="margin-right:12px;">
                            mdi-upload
                          </v-icon>
                          <div>Change <b>Original Signed Contract</b></div>
                        </v-btn>
                      </div>

                      <v-expand-transition>
                        <div 
                          v-if="isRequestInfoChecked && request.uploadedOriginalContracts.length === 0" 
                          class="red--text"
                          style="font-size:12px;"
                        >
                          Please upload Original Signed Contract
                        </div>
                      </v-expand-transition>
                      
                      <div style="padding-bottom: 16px;">
                        <file-list
                          :files="request.uploadedOriginalContracts"
                          :deletable="canEditRequestForm"
                          :multiple-rows="windowWidth < 650"
                          @click-download="downloadFile"
                          @click-delete="deleteOriginalContracts"
                        ></file-list>
                        <div
                          tabindex="0"
                          ref="gfk_vendor_contract_other_required_data_elements"
                          style="height: 1px;"
                        ></div>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <template 
                      v-if="needOriginalContract && request.originalContractType === 'Request'"
                    >      
                      <v-flex xs12 sm6 pt-0 pl-3 pr-3 pb-0 class="v-card-x-cell">      
                        <v-autocomplete
                          :disabled="!canEditRequestForm"
                          label="Request ID"
                          placeholder="Please input"
                          ref="gfk_vendor_contract_orginal_contract_request_id"
                          no-filter
                          :search-input.sync="originalContractRequestIdKey"
                          v-model="request.originalContractRequestId"
                          :items="originalContractRequestIdItems"
                          :rules="[
                            v => (!!v || 'Please input and select'),
                            v => (!!v && !!(originalContractRequestIdItems.find(el => el === v))) || 'Please input and select. Maybe Request ID is invalid'
                          ]"
                        ></v-autocomplete>
                      </v-flex>
                      <template v-if="originalContractRequestChecked">
                        <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                        <v-flex xs12>
                          <v-layout row wrap>
                            <v-flex xs12 sm6 order-sm2 pt-0 pl-3 pr-3 pb-4 class="v-card-x-cell">
                              <div
                                class="pr-1"
                                style="text-align: right;"
                              >{{ originalContractRequestTime }} {{ originalContractRequestStage }}</div>
                            </v-flex>
                            <v-flex xs12 sm6 order-sm1 pt-0 pl-3 pr-3 pb-4 class="v-card-x-cell">
                              <div v-for="(ocrco, ocrci) in originalContractRequestContracts"
                                :key="'original-contract-request-contracts-' + ocrci"
                              >
                                {{ ocrco }}
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </template>
                    </template>
                  </template>
                  <template v-if="!isPageLoading && canEditRequestForm">
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs6 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell">
                      <div class="bottom-btn-container">
                        <v-btn color="primary"
                          @click="clickPrevDraftStep"
                        >
                          Previous
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs6 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell">
                      <div class="bottom-btn-container-right">
                        <v-btn color="primary"
                          @click="clickSaveRequest"
                        >
                          Save
                        </v-btn>
                        <v-btn color="primary"
                          @click="clickNextDraftStep"
                        >
                          Next
                        </v-btn>
                      </div>
                    </v-flex>
                  </template>
                </v-layout>
              </v-form>
            </template>
          </material-app-card>

          <material-app-card
            id="gfk-vendor-contract-generated-contract"
            color="primary"
            icon="mdi-text-box-check-outline"
            v-if="(!isPendingRequest || isDraftStepInGeneratedContract || isDraftStepInCyberSecurity || isDraftStepInOtherSupportInfo || isDraftStepInApprovalInfo) && request.generatorUsage"
            :title="(isPendingRequest ? '2.1 ' : '') + 'Contract'"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell v-card-x-cell">
                  <div style="padding-bottom: 16px;">
                    <file-list
                      v-if="request.generatorUsage"
                      :files="dummyContractUnderReview"
                      :multiple-rows="windowWidth < 650"
                    >
                      <template v-slot:buttons>
                        <td style="width: 86px;">
                          <v-btn small text icon
                            class="mt-0 mb-0"
                            @click="downloadContractDoc"
                          >
                            <v-icon color="primary">
                              mdi-download
                            </v-icon>
                          </v-btn>
                          <v-btn small text icon
                            class="mt-0 mb-0"
                            @click="isGeneratorDisplayed = true"
                          >
                            <v-icon color="primary">
                              mdi-eye-settings
                            </v-icon>
                          </v-btn>
                        </td>
                      </template>
                    </file-list>
                  </div>
                  <div v-if="isDraftStepInGeneratedContract" class="error lighten-1 white--text" style="text-align:left;padding:21px 21px 18px 18px;display:flex;align-items:start;border-radius:6px;margin-bottom:16px;">
                    <v-icon color="white" style="margin-top: -3px;margin-right: 18px">
                      mdi-alert-circle-outline
                    </v-icon>
                    <div>
                      <div>Based on your input, we have generated a GfK standard contract. Please double check the contract carefully, <span style="font-weight:bold;text-decoration:underline;">especially the APPENDIX.</span></div>
                      <div>Now you can download and <span style="font-weight: bold;text-decoration: underline;">confirm the contract with your client</span>, or re-edit your contract.</div>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs12 class="v-flex-row-breaker">
                </v-flex>
                <template v-if="!isPageLoading && canEditGeneratedContract">
                  <v-flex xs6 pt-1 pl-3 pb-3>
                    <div class="bottom-btn-container">
                      <v-btn color="primary"
                        @click="clickPrevDraftStep"
                      >
                        Re-edit
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs6 pt-1 pr-3 pb-3>
                    <div class="bottom-btn-container-right">
                      <v-btn color="primary"
                        @click="clickNextDraftStep"
                      >
                        Next
                      </v-btn>
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            id="gfk-vendor-contract-other-support-info-card"
            color="primary"
            icon="mdi-file-document-edit-outline"
            v-if="(!isPendingRequest || isDraftStepInOtherSupportInfo || isDraftStepInApprovalInfo) && needOtherSupportInfo"
            :title="(isPendingRequest ? '3.0 ' : '') + 'Other Required Supporting/Information'"
            :style="{
              'margin-top': '32px'
            }"
          >
            <template v-slot>
              <v-form ref="gfk-vendor-contract-other-support-info-form" class="py-3" :lazy-validation="true">
                <v-layout pb-0 row wrap class="v-card-x-layout">
                  <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                    <div 
                      :class="[
                        'v-card-x-cell-title',
                        'my-disabled--text',
                      ]"
                    >Does the Payment Term you are requesting meet  the standard payment term (≥ 90 days)<br/>账期符合标准付款账期（≥ 90日）</div>
                  </v-flex>
                  <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                    <div>
                      <v-radio-group row class="gfk-vendor-contract-radio-group-horizonal mt-n3"
                        :disabled="!canEditOtherSupportInfoForm"
                        readonly
                        v-model="isStandardPayment"
                        :rules="[v => (!!v && v != '') || 'Please select']"
                      >
                        <v-radio label="Yes 是的符合" value="Y" color="primary"></v-radio>
                        <v-radio label="No 不符合" value="N" color="primary"></v-radio>
                      </v-radio-group>
                    </div>
                    <div
                      tabindex="0"
                      ref="gfk_vendor_contract_other_support_info_form_standard_payment"
                      style="height: 1px;"
                    ></div>
                  </v-flex>
                </v-layout>
                <v-layout pb-0 row wrap class="v-card-x-layout">
                  <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                    <div 
                      :class="[
                        'v-card-x-cell-title', 
                        ...(
                          isRequestInfoChecked && !request.isForAdvancePayment
                          ? ['red--text']
                          : []
                        ),
                        ...(
                          canEditOtherSupportInfoForm
                          ? []
                          : ['my-disabled--text']
                        )
                      ]"
                    >Does the contract you are applying for involve an advance payment?<br/>请问本合同是否涉及预付款</div>
                  </v-flex>
                  <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                    <div>
                      <v-radio-group row class="gfk-vendor-contract-radio-group-horizonal mt-n3"
                        :disabled="!canEditOtherSupportInfoForm"
                        v-model="request.isForAdvancePayment"
                        :rules="[v => (!!v && v != '') || 'Please select']"
                      >
                        <v-radio label="Yes 是的，涉及预付款" value="Y" color="primary"></v-radio>
                        <v-radio label="No 不涉及" value="N" color="primary"></v-radio>
                      </v-radio-group>
                    </div>
                    <div
                      tabindex="0"
                      ref="gfk_vendor_contract_other_support_info_form_advance_payment"
                      style="height: 1px;"
                    ></div>
                  </v-flex>
                </v-layout>
                <v-layout pb-0 row wrap class="v-card-x-layout" v-if="needSvpGlobalProcurementApprovalPdf">
                  <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                    <div 
                      :class="[
                        'v-card-x-cell-title', 
                        ...(
                          isRequestInfoChecked && !request.listSvpGlobalProcurementApprovalPdf.length
                          ? ['red--text']
                          : []
                        ),
                        ...(
                          canEditOtherSupportInfoForm
                          ? []
                          : ['my-disabled--text']
                        )
                      ]"
                    >Please upload the email approval from SVP Global Procurement Suki Becker &lt;Suki.Becker@nielseniq.com&gt; on exceptional payment terms or advance payment term mentioned on the contract. （PDF Format)<br/>请上传全球采购高级副总裁 Suki Becker &lt;Suki.Becker@nielseniq.com&gt; 关于合同中提及的特殊付款条件或预付款条件的特批邮件。（PDF 格式）</div>
                  </v-flex>
                  <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell" v-if="canEditOtherSupportInfoForm">
                    <div style="width: 100%;display: flex;align-items: center;">
                      <x-upload
                        request-class="file"
                        request-function="upload"
                        accept=".pdf"
                        :params="{
                          email: userEmail,
                          loginStatus: loginStatus
                        }"
                        :before-upload="beforeUploadSvpGlobalProcurementApprovalPdf"
                        :on-error="errorUploadSvpGlobalProcurementApprovalPdf"
                        :on-success="successUploadSvpGlobalProcurementApprovalPdf"
                      >
                        <v-btn text
                          style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                        >
                          <v-icon color="primary" size="24" style="margin-right:12px;">
                            mdi-upload
                          </v-icon>
                          <div>Upload (PDF)</div>
                        </v-btn>
                      </x-upload>
                    </div>
                  </v-flex>
                  <v-flex xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <file-list
                      :files="request.listSvpGlobalProcurementApprovalPdf"
                      :deletable="canEditOtherSupportInfoForm"
                      :multiple-rows="windowWidth < 650"
                      @click-download="downloadFile"
                      @click-delete="deleteSvpGlobalProcurementApprovalPdf"
                    ></file-list>
                    <div
                      tabindex="0"
                      ref="gfk_vendor_contract_other_support_info_form_svp_global_procurement_approval_pdf"
                      style="height: 1px;"
                    ></div>
                  </v-flex>
                </v-layout>
                <v-layout pt-4 pb-0 row wrap class="v-card-x-layout">
                  <template v-if="!isPageLoading && canEditOtherSupportInfoForm">
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs6 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell">
                      <div class="bottom-btn-container">
                        <v-btn color="primary"
                          @click="clickPrevDraftStep"
                        >
                          Previous
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs6 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell">
                      <div class="bottom-btn-container-right">
                        <v-btn color="primary"
                          @click="clickSaveRequest"
                        >
                          Save
                        </v-btn>
                        <v-btn color="primary"
                          @click="clickNextDraftStep"
                        >
                          Next
                        </v-btn>
                      </div>
                    </v-flex>
                  </template>
                </v-layout>
              </v-form>
            </template>
          </material-app-card>

          <material-app-card
            id="gfk-vendor-contract-approval-info"
            color="primary"
            icon="mdi-stamper"
            class="pb-3"
            :title="isPendingRequest ? (3 + !!needOtherSupportInfo) + '.0 Send For Approval' : 'Approval Information'"
            v-if="!isPendingRequest || isDraftStepInApprovalInfo"
          >
            <template v-slot>
              <v-form ref="approval-info-form" :lazy-validation="true">
                <v-layout row wrap class="v-card-x-layout">
                  <v-flex xs12 sm6 pa-0
                    v-if="needApproval"
                  >
                    <v-flex xs12 pl-3 pr-3 pb-2 class="v-card-x-cell"
                      v-if="needCommercialLeaderApproval"
                    >
                      <div 
                        class="v-card-x-cell-title my-disabled--text"
                        :style="{
                          ...(!isApprovalInfoChecked || checkCommercialLeaderApprovers)?{}:{color: 'red !important'}
                        }"
                      >Commercial Leader Approver</div>
                      <div v-if="!checkCommercialLeaderApprovers"
                        style="padding-top: 2px;color: red"
                      >
                        No available approver found.
                      </div>
                      <div v-else style="padding-top: 2px;max-width: 270px;">
                        <div
                          v-if="needCommercialLeaderL1Approval"
                          class="pb-2"
                        >
                          <account-profile
                            :email="commercialLeaderL1Approver.email"
                            :name="commercialLeaderL1Approver.name"
                            :icon="commercialLeaderL1Approver.icon"
                            :hide-tooltip="isMobile"
                            :gap-of-tag-icon-and-name="12"
                            tag-color="rgba(0,0,0,.68)"
                          >
                          </account-profile>
                        </div>
                        <div
                          v-if="needCommercialLeaderL2Approval && !(needCommercialLeaderL1Approval && (commercialLeaderL1Approver.email === commercialLeaderL2Approver.email))"
                          class="pb-2"
                        >
                          <account-profile
                            :email="commercialLeaderL2Approver.email"
                            :name="commercialLeaderL2Approver.name"
                            :icon="commercialLeaderL2Approver.icon"
                            :hide-tooltip="isMobile"
                            :gap-of-tag-icon-and-name="12"
                            tag-color="rgba(0,0,0,.68)"
                          >
                          </account-profile>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs12 pl-3 pr-3 pb-2 class="v-card-x-cell"
                      v-if="needFinanceApproval"
                    >
                      <div 
                        class="v-card-x-cell-title my-disabled--text"
                        :style="{
                          ...(!isApprovalInfoChecked || checkFinanceApprovers)?{}:{color: 'red !important'}
                        }"
                      >Finance Approver</div>
                      <div v-if="!checkFinanceApprovers"
                        style="padding-top: 2px;color: red"
                      >
                        No available approver found.
                      </div>
                      <div v-else style="padding-top: 2px;max-width: 270px;">
                        <div
                          v-if="needFinanceL1Approval"
                          class="pb-2"
                        >
                          <account-profile
                            :email="financeL1Approver.email"
                            :name="financeL1Approver.name"
                            :icon="financeL1Approver.icon"
                            :hide-tooltip="isMobile"
                            :gap-of-tag-icon-and-name="12"
                            tag-color="rgba(0,0,0,.68)"
                          >
                          </account-profile>
                        </div>
                        <div
                          v-if="needFinanceL2Approval && !(needFinanceL1Approval && (financeL1Approver.email === financeL2Approver.email))"
                          class="pb-2"
                        >
                          <account-profile
                            :email="financeL2Approver.email"
                            :name="financeL2Approver.name"
                            :icon="financeL2Approver.icon"
                            :hide-tooltip="isMobile"
                            :gap-of-tag-icon-and-name="12"
                            tag-color="rgba(0,0,0,.68)"
                          >
                          </account-profile>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs12 pl-3 pr-3 pb-2 class="v-card-x-cell"
                      v-if="needLegalApproval"
                    >
                      <div 
                        class="v-card-x-cell-title my-disabled--text"
                        :style="{
                          ...(!isApprovalInfoChecked || checkLegalApprovers)?{}:{color: 'red !important'}
                        }"
                      >Legal Approver</div>
                      <div v-if="!checkLegalApprovers"
                        style="padding-top: 2px;color: red"
                      >
                        No available approver found.
                      </div>
                      <div v-else style="padding-top: 2px;max-width: 270px;">
                        <div class="pb-2">
                          <account-profile
                            :email="legalApprover.email"
                            :name="legalApprover.name"
                            :icon="legalApprover.icon"
                            :hide-tooltip="isMobile"
                            :gap-of-tag-icon-and-name="12"
                            tag-color="rgba(0,0,0,.68)"
                          >
                          </account-profile>
                        </div>
                      </div>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 sm6 pa-0>
                    <v-flex xs12 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div class="v-card-x-cell-title my-disabled--text">Requester</div>
                      <div style="padding-top: 2px;max-width: 270px;">
                        <account-profile
                          :email="request.requestor.email"
                          :name="request.requestor.name"
                          :icon="request.requestor.icon"
                          :hide-tooltip="isMobile"
                          :gap-of-tag-icon-and-name="12"
                          tag-color="rgba(0,0,0,.68)"
                          tag-font-size="16"
                        >
                        </account-profile>
                      </div>
                    </v-flex>
                    <v-flex xs12 mt-4 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div class="v-card-x-cell-title my-disabled--text">Department</div>
                      <div
                        class="my-disabled--text"
                        style="margin-top: 6px;"
                      >
                        {{ request.team }}
                      </div>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                  <v-flex xs12 mt-1 pt-3 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div :class="['v-card-x-cell-title', ...(isApprovalInfoChecked && !request.backgroundBriefing ? ['red--text'] : []), ...(canEditApprovalForm ? [] : ['my-disabled--text'])]">Background Briefing</div>
                    <div class="title-solo-textarea">
                      <v-textarea
                        solo
                        no-resize
                        rows="3"
                        placeholder="Please specify"
                        style="margin-top: 0px"
                        :counter="maxLenBackgroundBriefing"
                        v-model="request.backgroundBriefing"
                        :readonly="!canEditApprovalForm"
                        :rules="[v => !!v && v !== '' || 'Please input']"
                        ref="gfk_vendor_contract_form_background_briefing"
                      >
                      </v-textarea>
                    </div>
                  </v-flex>
                  <template v-if="!isPageLoading && canEditApprovalForm">
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div class="bottom-btn-container">
                        <v-btn color="primary"
                          @click="clickPrevDraftStep"
                        >
                          Previous
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div
                        class="bottom-btn-container-right"
                      >
                        <v-btn color="primary"
                          @click="clickSaveRequest"
                        >
                          Save
                        </v-btn>
                        <v-btn color="primary"
                          @click="clickSubmitRequest"
                        >
                          Submit
                        </v-btn>
                      </div>
                    </v-flex>
                  </template>
                </v-layout>
              </v-form>
            </template>
          </material-app-card>
        </v-flex>

        <v-flex
          xs12
          lg4
          order-lg2
          pt-0
          pb-0
        >
          <material-app-card
            v-if="!isPageLoading"
            color="primary"
            icon="mdi-radar"
            :title="isNewRequest ? 'GfK Vendor Contract' : request.requestId"
          >
            <template v-slot:extra>
              <v-tooltip left :disabled="isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text icon color="primary"
                    v-if="canRecall"
                    @click="clickRecall"
                    v-on="on"
                  >
                    <v-icon>mdi-restart</v-icon>
                  </v-btn>
                </template>
                <span>Recall</span>
              </v-tooltip>
              <v-tooltip left :disabled="isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text icon color="primary"
                    v-show="canAbandon"
                    @click="isRequestAbandoning = true"
                    v-on="on"
                  >
                    <v-icon size="22">mdi-cancel</v-icon>
                  </v-btn>
                </template>
                <span>Abandon</span>
              </v-tooltip>
              <v-tooltip left :disabled="isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text icon color="primary"
                    v-show="!isNewRequest"
                    @click="showShareTo"
                    v-on="on"
                  >
                    <v-icon v-if="canShareAccess">mdi-account-plus</v-icon>
                    <v-icon v-else>mdi-account</v-icon>
                  </v-btn>
                </template>
                <span v-if="canShareAccess">Share Access</span>
                <span v-else>Access</span>
              </v-tooltip>
            </template>
            <template v-slot>
              <div class="gfk-vendor-contract-stepper">
                <v-stepper
                  :value="stageIndexOfDisplayed"
                  vertical
                >
                  <template v-for="(stage, si) in stagesDisplayed">
                    <v-stepper-step
                      :key="'step_' + si"
                      color="primary"
                      :step="si"
                      :complete="stageIndexOfDisplayed >= si"
                      :complete-icon="stageIndexOfDisplayed > si ? '$vuetify.icons.complete' : 'mdi-star'"
                    >
                      {{ stage.content + (stage.total > 1 ? (' (' + stage.done + '/' + stage.total + ')') : '') }}
                    </v-stepper-step>
                    <v-stepper-content :key="'step_content_' + si" :step="si">
                    </v-stepper-content>
                  </template>
                </v-stepper>
              </div>
            </template>
          </material-app-card>

          <material-app-card
            color="primary"
            icon="mdi-message-draw"
            title="Review Panel"
            v-if="!isPendingRequest"
          >
            <template v-slot:extra>
              <v-tooltip left :disabled="isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text icon color="primary"
                    v-if="canComment"
                    @click="clickDraftComments()"
                    v-on="on"
                  >
                    <v-icon size="20">mdi-forum-outline</v-icon>
                  </v-btn>
                </template>
                <span>Comment</span>
              </v-tooltip>
            </template>
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex v-if="canDownloadSignFile || canViewSignInfo" xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <div ref="contract_sign" style="width:100%"></div>
                  <file-list
                    :files="isSigningRequest ? dummyContractSigning : dummyContractSigned"
                    :multiple-rows="$vuetify.breakpoint.lgAndUp || !$vuetify.breakpoint.smAndUp"
                  >
                    <template v-slot:buttons="props">
                      <td style="width: 86px; text-align: right;">
                        <v-btn small text icon
                          class="mt-0 mb-0"
                          v-if="canDownloadSignFile"
                          @click="downloadSignFile(props.index)"
                        >
                          <v-icon color="primary">
                            mdi-download
                          </v-icon>
                        </v-btn>
                        <v-btn small text icon
                          v-if="canViewSignInfo"
                          @click="clickViewSign"
                        >
                          <v-icon color="primary">
                            mdi-eye-settings
                          </v-icon>
                        </v-btn>
                      </td>
                    </template>
                  </file-list>
                </v-flex>
                <v-flex v-else-if="isApprovalRequest || isApprovedRequest" xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <div ref="contract_under_review" style="width:100%"></div>
                  <file-list
                    v-if="request.generatorUsage"
                    :files="dummyContractUnderReview"
                    :multiple-rows="$vuetify.breakpoint.lgAndUp || !$vuetify.breakpoint.smAndUp"
                  >
                    <template v-slot:buttons>
                      <td style="width: 86px;">
                        <v-btn small text icon
                          class="mt-0 mb-0"
                          @click="downloadContractDoc"
                        >
                          <v-icon color="primary">
                            mdi-download
                          </v-icon>
                        </v-btn>
                        <v-btn small text icon
                          @click="isGeneratorDisplayed = true"
                        >
                          <v-icon color="primary">
                            mdi-eye-settings
                          </v-icon>
                        </v-btn>
                      </td>
                    </template>
                  </file-list>
                  <file-list
                    v-else
                    :files="request.draftedContract"
                    :deletable="false"
                    :multiple-rows="$vuetify.breakpoint.lgAndUp || !$vuetify.breakpoint.smAndUp"
                    @click-download="downloadFile"
                  ></file-list>
                </v-flex>
                <v-flex xs12 class="v-flex-row-breaker">
                </v-flex>
                <v-flex pt-0 pl-3 pr-3 pb-3>
                  <div v-if="!!reviewPanel.alerts.length" class="error lighten-1 white--text" style="text-align:left;padding:21px 21px 18px 18px;display:flex;align-items:start;border-radius:6px;margin-bottom:0;">
                    <v-icon color="white" style="margin-top: -3px;margin-right: 18px">
                      mdi-alert-circle-outline
                    </v-icon>
                    <div>
                      <div 
                        v-for="(rpa, rpai) in reviewPanel.alerts"
                        :key="'review-panel-alerts-' + rpai"
                      >{{ rpa }}</div>
                    </div>
                  </div>
                </v-flex>
                <template v-if="!isPageLoading">
                  <v-flex pt-1 pl-3 pr-3 pb-3>
                    <div class="bottom-btn-container-right">
                      <v-btn v-if="!isMobile && canReviseContract && !request.generatorUsage" color="primary"
                        @click="clickUploadNewDraftedContract"
                      >
                        Revise Contract
                      </v-btn>
                      <template v-if="canApprove">
                        <v-btn v-if="request.generatorUsage" color="primary"
                          @click="isGeneratorDisplayed = true"
                        >
                          Action
                        </v-btn>
                        <template v-else>
                          <v-btn color="primary"
                            v-if="isCyberSecurityBaseApprovalRequest"
                            @click="clickCyberSecurityNeedImprovement"
                          >
                            Need Improvement
                          </v-btn>
                          <v-btn color="primary"
                            @click="clickApprove"
                          >
                            Approve
                          </v-btn>
                        </template>
                      </template>
                      <v-btn v-if="canSign" color="primary"
                        @click="clickStartSign"
                      >
                        Sign
                      </v-btn>
                      <v-btn v-if="canViewSignInfo" color="primary"
                        @click="clickViewSign"
                      >
                        Next
                      </v-btn>
                      <v-btn v-if="canArchive" color="primary"
                        @click="clickArchive"
                      >
                        Archive
                      </v-btn>                      
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            color="primary"
            icon="mdi-history"
            title="Activity History"
            v-if="historyDisplayed.length > 0 && $vuetify.breakpoint.lgAndUp"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <activity-history
                    :history="historyDisplayed"
                  >
                  </activity-history>
                </v-flex>
              </v-layout>
            </template>
          </material-app-card>
        </v-flex>

        <v-flex
          v-if="!$vuetify.breakpoint.lgAndUp"
          order-xs3
          xs12
          pt-0
          pb-0
        >
          <material-app-card
            color="primary"
            icon="mdi-history"
            title="Activity History"
            v-if="historyDisplayed.length > 0"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <activity-history
                    :history="historyDisplayed"
                  >
                  </activity-history>
                </v-flex>
              </v-layout>
            </template>
          </material-app-card>
        </v-flex>

        <v-dialog
          v-model="isGeneratorDisplayed"
          persistent
          fullscreen
          :hide-overlay="isMobile"
          :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
        >
          <v-card class="gfk-vendor-contract-generate-contract-card">
            <v-list class="sidebar-top">
              <v-list-item>
                <template v-if="isMobile">
                  <v-list-item-action>
                    <v-btn v-if="isMobile && !isFreshContractPreviewing && !canEditRequestType" text icon @click="isGeneratorDisplayed = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-spacer></v-spacer>
                </template>

                <v-list-item-content>
                  <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">
                    <span 
                      v-if="isDraftStepInRequestType || isFreshContractPreviewing"
                    >Generate Contract</span>
                    <span v-else>Contract Information</span>
                  </v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <div style="display: flex">
                    <v-tooltip bottom :disabled="isMobile">
                      <template v-slot:activator="{ on }">
                        <v-btn text icon color="primary" class="ml-3"
                          v-if="canComment"
                          @click="clickDraftComments()"
                          v-on="on"
                        >
                          <v-icon>mdi-forum-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Comment</span>
                    </v-tooltip>
                    <v-btn v-if="!isMobile && !isFreshContractPreviewing && !isDraftStepInRequestType" text icon @click="isGeneratorDisplayed = false" class="ml-2">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list>            

            <div
              :style="{
                'position': 'absolute',
                'top': '64px',
                'left': '0',
                'right': '0',
                'bottom': isMobile ? '0' : '65px',
                'overflow': 'hidden',
                'border-radius': isMobile ? '0' : '3px'
              }"
            >
              <div style="position: relative;width: 100%;height: 100%; background-color: rgba(209, 209, 209);">
                <v-layout wrap px-2
                  :style="{
                    'height': '100%',
                    'overflow': $vuetify.breakpoint.lgAndUp ? 'hidden' : 'auto',
                    'border-top': '1px solid lightgrey',
                    'border-bottom': '1px solid lightgrey'
                  }"
                >
                  <v-flex
                    xs12
                    lg6
                    px-2
                    pt-0
                    pb-4
                    :style="{
                      'height': $vuetify.breakpoint.lgAndUp ? '100%' : 'auto',
                      'overflow': $vuetify.breakpoint.lgAndUp ? 'auto' : 'hidden'
                    }"
                  >
                    <material-app-card
                      id="gfk-vendor-contract-generator-form-card"
                      color="primary"
                      icon="mdi-file-document-edit-outline"
                      :title="(isPendingRequest ? '2.0 ' : '') + (isDraftStepInRequestType || isFreshContractPreviewing ? 'Generate Contract' : 'Contract Information')"
                      :style="{
                        'margin-top': '32px'
                      }"
                    >
                      <template v-slot>
                        <v-form ref="gfk-vendor-contract-generator-form" :lazy-validation="true">
                          <v-layout pb-3 row wrap class="v-card-x-layout">
                            <v-flex xs12
                              v-if="isPageLoading"
                              :style="{
                                margin: '12px',
                                display: 'flex',
                                'align-items': 'center',
                                'justify-content': 'center'
                              }"
                            >
                              <v-progress-circular
                                :width="4"
                                color="grey"
                                indeterminate
                                :size="24"
                              ></v-progress-circular>
                              <div
                                class="grey--text text--lighten-1"
                                :style="{
                                  'margin-left': '9px'
                                }"
                              >
                                Loading
                              </div>
                            </v-flex>
                            <template v-else>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needProductType"
                              >
                                <v-select
                                  :disabled="!canEditRequestForm || disabledProductType"
                                  label="Product Type"
                                  :items="productTypesForSelect"
                                  item-text="text"
                                  v-model="request.productType"
                                  ref="gfk_vendor_contract_generator_form_gfk_entity_name"
                                  :rules="[
                                    v => !!productTypesForSelect.find(el => el.value === v) || 'Please select',
                                    v => !!productTypesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                                  ]"
                                >
                                </v-select>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needContractType"
                              >
                                <v-select
                                  :disabled="!canEditRequestForm"
                                  label="Contract Type"
                                  :items="contractTypesForSelect"
                                  item-text="text"
                                  v-model="request.contractType"
                                  ref="gfk_vendor_contract_generator_form_gfk_entity_name"
                                  :rules="[
                                    v => !!contractTypesForSelect.find(el => el.value === v) || 'Please select',
                                    v => !!contractTypesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                                  ]"
                                >
                                </v-select>
                              </v-flex>
                              <!-- <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needSubProductType"
                              >
                                <v-select
                                  :disabled="!canEditRequestForm"
                                  label="Sub Product Type"
                                  :items="subProductTypesForSelect"
                                  item-text="text"
                                  v-model="request.subProductType"
                                  ref="gfk_vendor_contract_generator_form_gfk_entity_name"
                                  :rules="[
                                    v => !!subProductTypesForSelect.find(el => el.value === v) || 'Please select',
                                    v => !!subProductTypesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                                  ]"
                                >
                                </v-select>
                              </v-flex> -->
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needLanguage"
                              >
                                <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(isRequestInfoChecked && !request.language ? ['red--text'] : []), ...((canEditRequestForm && !disabledLanguage) ? [] : ['my-disabled--text'])]">Language</div>
                                <div>
                                  <v-radio-group row class="gfk-vendor-contract-radio-group-horizonal"
                                    :disabled="!canEditRequestForm || disabledLanguage"
                                    v-model="request.language"
                                    :rules="[v => (!!v && v != '') || 'Please select']"
                                  >
                                    <v-radio label="English" value="English" color="primary"></v-radio>
                                    <v-radio label="中文" value="Chinese" color="primary"></v-radio>
                                  </v-radio-group>
                                </div>
                                <div
                                  tabindex="0"
                                  ref="gfk_vendor_contract_generator_form_language"
                                  style="height: 1px;"
                                ></div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needCategory"
                              >
                                <v-select
                                  :disabled="!canEditRequestForm"
                                  label="Category"
                                  :items="categoryForSelect"
                                  item-text="text"
                                  v-model="request.category"
                                  ref="gfk_vendor_contract_generator_form_gfk_entity_name"
                                  :rules="[
                                    v => !!categoryForSelect.find(el => el.value === v) || 'Please select',
                                    v => !!categoryForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                                  ]"
                                >
                                </v-select>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needContractEffectiveDate"
                              >
                                <div>
                                  <v-dialog
                                    ref="gfk_vendor_contract_generator_form_contract_effective_date"
                                    v-model="isPickerContractEffectiveDateDisplayedGen"
                                    :return-value.sync="request.contractEffectiveDate"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="request.contractEffectiveDate"
                                        label="Contract Effective Date"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                        :disabled="!canEditRequestForm"
                                        ref="gfk_vendor_contract_generator_form_contract_effective_date_text_field"
                                        :rules="[v => (!!v && v != '') || 'Please select']"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="request.contractEffectiveDate" scrollable color="primary" header-color="primary"
                                      :allowed-dates="allowedContractEffectiveDates"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" class="mb-2" @click="isPickerContractEffectiveDateDisplayedGen = false">CANCEL</v-btn>
                                      <v-btn dark color="primary" class="mb-2" @click="clickOkContractEffectiveDateGen">OK</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needGfkEntityName"
                              >
                                <v-select
                                  :disabled="!canEditRequestForm"
                                  label="GfK Entity Name"
                                  :items="salesOrgsForSelect"
                                  :item-text="needLanguage ? request.language === 'Chinese' ? 'textCn' : 'textEn' : 'text'"
                                  :placeholder="salesOrgPlaceholder"
                                  v-model="request.gfkEntityName"
                                  ref="gfk_vendor_contract_generator_form_gfk_entity_name"
                                  :rules="[
                                    v => !!salesOrgsForSelect.find(el => el.value === v) || 'Please select',
                                    v => !!salesOrgsForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                                  ]"
                                  @change="changeGfkEntityName"
                                >
                                </v-select>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                                <div
                                  v-if="needVendorEntityName"
                                >
                                  <v-combobox
                                    :disabled="!canEditRequestForm"
                                    label="Vendor Entity Name"
                                    ref="gfk_vendor_contract_generator_form_vendor_entity_name"
                                    no-filter
                                    :search-input.sync="vendorEntityNameKey"
                                    v-model="request.vendorEntityName"
                                    :items="vendorEntityNameItems"
                                    :rules="[v => !!v || 'Please input and select']"
                                    @change="changeVendorEntityName"
                                    @blur="() => request.vendorEntityName = vendorEntityNameKey"
                                  ></v-combobox>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-2 class="v-card-x-cell"
                                v-if="needTaxRate"
                              >
                                <v-select
                                  v-model="request.taxRate"
                                  :items="taxRatesForSelect"
                                  :disabled="!canEditRequestForm"
                                  label="Tax Rate"
                                  item-text="value"
                                  ref="gfk_vendor_contract_generator_form_tax_rate"
                                  :rules="[
                                    v => !!taxRatesForSelect.find(el => el.value === v) || 'Please select',
                                    v => !!taxRatesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                                  ]"
                                  @change="changeTaxRate"
                                >
                                </v-select>
                              </v-flex>
                              <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-2 class="v-card-x-cell"
                                v-if="needInvoiceType"
                              >
                                <v-select
                                  v-model="request.invoiceType"
                                  :items="invoiceTypesForSelect"
                                  :disabled="!canEditRequestForm"
                                  label="Invoice Type"
                                  :item-text="isLanguageChinese ? 'cn' : 'en'"
                                  ref="gfk_vendor_contract_generator_form_invoice_type"
                                  :rules="[
                                    v => !!invoiceTypesForSelect.find(el => el.value === v) || 'Please select',
                                    v => !!invoiceTypesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                                  ]"
                                  @change="changeInvoiceType"
                                >
                                </v-select>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-2 class="v-card-x-cell"
                                v-if="needBillingCondition"
                              >
                                <v-combobox
                                  v-model="request.billingCondition"
                                  :search-input.sync="billingConditionKey"
                                  :items="billingConditionsForSelect"
                                  :disabled="!canEditRequestForm"
                                  label="Billing Condition"
                                  :item-value="isLanguageChinese ? 'cn' : 'en'"
                                  :item-text="isLanguageChinese ? 'cn' : 'en'"
                                  ref="gfk_vendor_contract_generator_form_billing_condition"
                                  :return-object="false"
                                  :rules="[
                                    v => !!v || 'Please select or input'
                                  ]"
                                  @change="changeBillingCondition"
                                  @blur="() => request.billingCondition = billingConditionKey"
                                >
                                  <template v-slot:append-item>
                                    <div class="grey--text" style="margin: 15px 15px 12px; text-align:left;">
                                      Or enter other billing conditions
                                    </div>
                                  </template>
                                </v-combobox>
                              </v-flex>
                              <v-flex xs12 sm6 pt-3 pl-3 pr-4 pb-2 class="v-card-x-cell"
                                v-if="needPaymentTerm"
                              >
                                <v-combobox
                                  v-model="request.paymentTerm"
                                  :search-input.sync="paymentTermKey"
                                  :items="paymentTermsForSelect"
                                  :disabled="!canEditRequestForm"
                                  label="Payment Term"
                                  type="number"
                                  item-value="value"
                                  item-text="value"
                                  ref="gfk_vendor_contract_generator_form_payment_term_select"
                                  :return-object="false"
                                  :rules="[
                                    v => !!v || 'Please select or input',
                                    v => (!!v && v.indexOf('.') === -1) || 'Please input integer'
                                  ]"
                                  @change="changePaymentTerm"
                                  @blur="() => request.paymentTerm = paymentTermKey"
                                >
                                  <template v-slot:append-outer>
                                    <div style="margin-top: 6px;">
                                      DAYS
                                    </div>
                                  </template>
                                  <template v-slot:append-item>
                                    <div class="grey--text" style="margin: 15px 15px 12px; text-align:left;">
                                      Or enter other number of days
                                    </div>
                                  </template>
                                </v-combobox>
                              </v-flex>
                              <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needNameOfServiceProduct"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.nameOfServiceProduct ? ['red--text'] : [])]">Name of Service/ Product</div>
                                <div class="title-solo-textarea">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="2"
                                    placeholder="Please input"
                                    style="margin-top: 0px"
                                    :counter="maxLenNameOfServiceProduct"
                                    v-model="request.nameOfServiceProduct"
                                    :readonly="!canEditRequestForm"
                                    ref="gfk_vendor_contract_generator_form_name_of_service_product"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                    @input="changeNameOfServiceProduct"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needSpecificationOfServiceProduct"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.specificationOfServiceProduct ? ['red--text'] : [])]">Specification of Service/ Product</div>
                                <div class="title-solo-textarea">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="2"
                                    placeholder="Please input"
                                    style="margin-top: 0px"
                                    :counter="maxLenSpecificationOfServiceProduct"
                                    v-model="request.specificationOfServiceProduct"
                                    :readonly="!canEditRequestForm"
                                    ref="gfk_vendor_contract_generator_form_specification_of_service_product"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                    @input="changeSpecificationOfServiceProduct"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needServiceProductDeliveryPlace"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.serviceProductDeliveryPlace ? ['red--text'] : [])]">Service/Product Delivery Place</div>
                                <div class="title-solo-textarea">
                                  <v-combobox
                                    :disabled="!canEditRequestForm"
                                    solo
                                    ref="gfk_vendor_contract_generator_form_service_product_delivery_place"
                                    v-model="request.serviceProductDeliveryPlace"
                                    :search-input.sync="serviceProductDeliveryPlaceKey"
                                    append-icon=""
                                    :items="arrayGfkAddress"
                                    :return-object="false"
                                    :item-value="isLanguageChinese ? 'cn' : 'en'"
                                    :item-text="isLanguageChinese ? 'cn' : 'en'"
                                    :rules="[v => !!v || 'Please input or select']"
                                    @input="changeServiceProductDeliveryPlace"
                                    @blur="() => request.serviceProductDeliveryPlace = serviceProductDeliveryPlaceKey"
                                  >
                                    <template v-slot:prepend-item>
                                      <div class="grey--text" style="margin: 15px 15px 12px; text-align:left;">
                                        Please input or select
                                      </div>
                                    </template>
                                  </v-combobox>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needServiceProductDeliveryTime"
                              >
                                <div>
                                  <v-dialog
                                    ref="gfk_vendor_contract_generator_form_service_product_delivery_time"
                                    v-model="isPickerServiceProductDeliveryTimeDisplayedGen"
                                    :return-value.sync="request.serviceProductDeliveryTime"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="request.serviceProductDeliveryTime"
                                        label="Service/Product Delivery Time"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                        :disabled="!canEditRequestForm"
                                        ref="gfk_vendor_contract_generator_form_service_product_delivery_time_text_field"
                                        :rules="[v => (!!v && v != '') || 'Please select']"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="request.serviceProductDeliveryTime" scrollable color="primary" header-color="primary"
                                      :allowed-dates="allowedServiceProductDeliveryTime"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" class="mb-2" @click="isPickerServiceProductDeliveryTimeDisplayedGen = false">CANCEL</v-btn>
                                      <v-btn dark color="primary" class="mb-2" @click="clickOkServiceProductDeliveryTimeGen">OK</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needProjectName"
                              >
                                <v-text-field
                                  v-model="request.projectName"
                                  label="Project Name"
                                  ref="gfk_vendor_contract_generator_form_project_name"
                                  :disabled="!canEditRequestForm"
                                  :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                  @input="changeProjectName"
                                >
                                </v-text-field>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needServiceProductStartDate"
                              >
                                <div>
                                  <v-dialog
                                    ref="gfk_vendor_contract_generator_form_service_product_start_date"
                                    v-model="isPickerServiceProductStartDateDisplayedGen"
                                    :return-value.sync="request.serviceProductStartDate"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="request.serviceProductStartDate"
                                        label="Service/Product Start Date"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                        :disabled="!canEditRequestForm"
                                        ref="gfk_vendor_contract_generator_form_service_product_start_date_text_field"
                                        :rules="[v => (!!v && v != '') || 'Please select']"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="request.serviceProductStartDate" scrollable color="primary" header-color="primary"
                                      :allowed-dates="allowedServiceProductStartDate"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" class="mb-2" @click="isPickerServiceProductStartDateDisplayedGen = false">CANCEL</v-btn>
                                      <v-btn dark color="primary" class="mb-2" @click="clickOkServiceProductStartDateGen">OK</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needServiceProductCompleteDate"
                              >
                                <div>
                                  <v-dialog
                                    ref="gfk_vendor_contract_generator_form_service_product_complete_date"
                                    v-model="isPickerServiceProductCompleteDateDisplayedGen"
                                    :return-value.sync="request.serviceProductCompleteDate"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="request.serviceProductCompleteDate"
                                        label="Service/Product Complete Date"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                        :disabled="!canEditRequestForm"
                                        ref="gfk_vendor_contract_generator_form_service_product_complete_date_text_field"
                                        :rules="[v => (!!v && v != '') || 'Please select']"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="request.serviceProductCompleteDate" scrollable color="primary" header-color="primary"
                                      :allowed-dates="allowedServiceProductCompleteDate"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" class="mb-2" @click="isPickerServiceProductCompleteDateDisplayedGen = false">CANCEL</v-btn>
                                      <v-btn dark color="primary" class="mb-2" @click="clickOkServiceProductCompleteDateGen">OK</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needEstimatedContractValue"
                              >
                                <div class="v-card-contract-amount">
                                  <v-text-field
                                    append-icon="mdi-pencil"
                                    key="gfk_vendor_contract_estimated_contract_value"
                                    :label="'Estimated Contract Value (Include Tax)' + (!!estimatedContractValueToThousands ? ': ' + estimatedContractValueToThousands : '')"
                                    type="number"
                                    :disabled="!canEditRequestForm"
                                    v-model="request.estimatedContractValue"
                                    @blur="blurEstimatedContractValue"
                                    ref="gfk_vendor_contract_generator_form_estimated_contract_value"
                                    :rules="[
                                      v => !isNaN(parseFloat(v)) || 'Please input number',
                                      v => parseFloat(v) > 0 || 'Amount should be greater than 0'
                                    ]"
                                    @input="changeEstimatedContractValue"
                                  >
                                  </v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needContractValueIncludeTax"
                              >
                                <div class="v-card-contract-amount">
                                  <v-text-field
                                    append-icon="mdi-pencil"
                                    key="gfk_vendor_contract_contract_value"
                                    :label="'Contract Value (Include Tax)' + (!!contractValueIncludeTaxToThousands ? ': ' + contractValueIncludeTaxToThousands : '')"
                                    type="number"
                                    :disabled="!canEditRequestForm"
                                    v-model="request.contractValueIncludeTax"
                                    @blur="blurContractValueIncludeTax"
                                    ref="gfk_vendor_contract_generator_form_contract_value_include_tax"
                                    :rules="[
                                      v => !isNaN(parseFloat(v)) || 'Please input number',
                                      v => parseFloat(v) > 0 || 'Amount should be greater than 0'
                                    ]"
                                    @input="changeContractValueIncludeTax"
                                  >
                                  </v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needCurrency"
                              >
                                <v-select
                                  :disabled="!canEditRequestForm"
                                  label="Currency"
                                  :items="currenciesForSelect"
                                  item-text="text"
                                  v-model="request.currency"
                                  ref="gfk_vendor_contract_generator_form_currency"
                                  :rules="[
                                    v => !!currenciesForSelect.find(el => el.value === v) || 'Please select',
                                    v => !!currenciesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                                  ]"
                                  @change="changeCurrency"
                                >
                                </v-select>
                              </v-flex>
                              <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needOtherRequirements"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.otherRequirements ? ['red--text'] : [])]">Other Requirements</div>
                                <div class="title-solo-textarea">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="3"
                                    placeholder="Please input"
                                    style="margin-top: 0px"
                                    :counter="maxLenOtherRequirements"
                                    v-model="request.otherRequirements"
                                    :readonly="!canEditRequestForm"
                                    ref="gfk_vendor_contract_generator_form_other_requirements"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                    @input="changeOtherRequirements"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needConfirmPersonalInfo"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && (typeof request.isObtainPersonalInfo !== 'number') ? ['red--text'] : []), ...(canEditRequestForm ? [] : ['my-disabled--text'])]">
                                  Does this vendor obtain personal information from GfK?<br/>
                                  该供应商从GfK 处获得任何个人信息吗？
                                </div>
                                <div class="mt-n5">
                                  <v-radio-group row class="gfk-vendor-contract-radio-group-horizonal"
                                    :disabled="!canEditRequestForm"
                                    v-model="request.isObtainPersonalInfo"
                                    :rules="[v => (typeof v === 'number') || 'Please select']"
                                  >
                                    <v-radio label="Yes" :value="1" color="primary"></v-radio>
                                    <v-radio label="No" :value="0" color="primary"></v-radio>
                                  </v-radio-group>
                                </div>
                                <div
                                  tabindex="0"
                                  ref="gfk_vendor_contract_generator_form_language"
                                  style="height: 1px;"
                                ></div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needConfirmPersonalInfo"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && (typeof request.isAuthorizePersonalInfo !== 'number') ? ['red--text'] : []), ...(canEditRequestForm ? [] : ['my-disabled--text'])]">
                                  Does this vendor authorized by GfK to process personal information？<br/>
                                  该供应商受GfK 委托处理个人信息吗？
                                </div>
                                <div class="mt-n5">
                                  <v-radio-group row class="gfk-vendor-contract-radio-group-horizonal"
                                    :disabled="!canEditRequestForm"
                                    v-model="request.isAuthorizePersonalInfo"
                                    :rules="[v => (typeof v === 'number') || 'Please select']"
                                  >
                                    <v-radio label="Yes" :value="1" color="primary"></v-radio>
                                    <v-radio label="No" :value="0" color="primary"></v-radio>
                                  </v-radio-group>
                                </div>
                                <div
                                  tabindex="0"
                                  ref="gfk_vendor_contract_generator_form_language"
                                  style="height: 1px;"
                                ></div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needGfkContactAddress"
                              >
                                <v-combobox
                                  id="generator-form-text-field-gfk-contact-address"
                                  v-model="request.gfkContactAddress"
                                  label="GfK Contact Address"
                                  :disabled="!canEditRequestForm"
                                  :search-input.sync="gfkContactAddressKey"
                                  append-icon=""
                                  :items="arrayGfkAddress"
                                  :return-object="false"
                                  :item-value="isLanguageChinese ? 'cn' : 'en'"
                                  :item-text="isLanguageChinese ? 'cn' : 'en'"
                                  :rules="[v => (!!v && v.toString().trim() != '') || 'Please input or select']"
                                  @blur="() => request.gfkContactAddress = gfkContactAddressKey"
                                >
                                  <template v-slot:prepend-item>
                                    <div class="grey--text" style="margin: 15px 15px 12px; text-align:left;">
                                      Please input or select
                                    </div>
                                  </template>
                                </v-combobox>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needVendorContactAddress"
                              >
                                <v-text-field
                                  id="generator-form-text-field-vendor-contact-address"
                                  v-model="request.vendorContactAddress"
                                  label="Vendor Contact Address"
                                  :disabled="!canEditRequestForm"
                                  :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                >
                                </v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needGfkZipCodes"
                              >
                                <v-text-field
                                  id="generator-form-text-field-gfk-zip-codes"
                                  v-model="request.gfkZipCodes"
                                  label="GfK Zip Code"
                                  :disabled="!canEditRequestForm"
                                  :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                >
                                </v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needVendorZipCodes"
                              >
                                <v-text-field
                                  id="generator-form-text-field-vendor-zip-codes"
                                  v-model="request.vendorZipCodes"
                                  label="Vendor Zip Code"
                                  :disabled="!canEditRequestForm"
                                  :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                >
                                </v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needGfkContactPerson"
                              >
                                <v-text-field
                                  id="generator-form-text-field-gfk-contact-person"
                                  v-model="request.gfkContactPerson"
                                  label="GfK Contact Person"
                                  :disabled="!canEditRequestForm"
                                  :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                >
                                </v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needVendorContactPerson"
                              >
                                <v-text-field
                                  id="generator-form-text-field-vendor-contact-person"
                                  v-model="request.vendorContactPerson"
                                  label="Vendor Contact Person"
                                  :disabled="!canEditRequestForm"
                                  :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                >
                                </v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needGfkContactEmail"
                              >
                                <v-text-field
                                  id="generator-form-text-field-gfk-contact-email"
                                  v-model="request.gfkContactEmail"
                                  label="GfK Contact Email"
                                  :disabled="!canEditRequestForm"
                                  :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                >
                                </v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needVendorContactEmail"
                              >
                                <v-text-field
                                  id="generator-form-text-field-vendor-contact-email"
                                  v-model="request.vendorContactEmail"
                                  label="Vendor Contact Email"
                                  :disabled="!canEditRequestForm"
                                  :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                >
                                </v-text-field>
                              </v-flex>
                              <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="
                                  needPersonalDataPurposeAndUse
                                  || needPersonalDataSubjectCategory
                                  || needPersonalDataProcessType
                                  || needPersonalDataProcessTerm
                                "
                              >
                                <div class="v-card-x-cell-title font-weight-bold">
                                  Details of Personal Data processing<br/>
                                  个人信息处理详情<br/>
                                  <span class="grey--text">* 由服务提供方填写</span>
                                </div>
                              </v-flex>
                              <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needPersonalDataPurposeAndUse"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.personalDataPurposeAndUse ? ['red--text'] : [])]">
                                  Purpose of processing Personal Data and how to use it<br/>
                                  <span class="grey--text">* 例如服务描述中所述之目的</span>
                                </div>
                                <div class="title-solo-textarea mt-n1">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="3"
                                    placeholder="Please input Purpose of processing Personal Data and how to use it"
                                    style="margin-top: 0px"
                                    :counter="maxLenPersonalDataPurposeAndUse"
                                    v-model="request.personalDataPurposeAndUse"
                                    :readonly="!canEditRequestForm"
                                    ref="gfk_vendor_contract_generator_form_personal_data_purpose_and_use"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <v-flex xs12 pt-1 pl-3 pr-3 pb-0 class="v-card-x-cell"
                                v-if="needPersonalDataSubjectCategory"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.personalDataSubjectCategory ? ['red--text'] : [])]">
                                  Categories of Personal Data Subjects<br/>
                                  <span class="grey--text">* 例如GfK调查小组成员、GfK用户、GfK客户、GfK职员等</span>
                                </div>
                                <div class="title-solo-textarea mt-n1">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="3"
                                    placeholder="Please input Categories of Personal Data Subjects"
                                    style="margin-top: 0px"
                                    :counter="maxLenPersonalDataSubjectCategory"
                                    v-model="request.personalDataSubjectCategory"
                                    :readonly="!canEditRequestForm"
                                    ref="gfk_vendor_contract_generator_form_personal_data_subject_category"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <v-flex xs12 pt-1 pl-3 pr-3 pb-0 class="v-card-x-cell"
                                v-if="needPersonalDataProcessType"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.personalDataProcessType ? ['red--text'] : [])]">
                                  Categories of Personal Data Processed<br/>
                                  <span class="grey--text">* 例如姓名、地址、电子邮件地址、登录信息、IP 地址、cookie（包括 cookie ID）、移动广告标识符、统计数据以及 GfK 家庭 ID 和身份证号码等</span>
                                </div>
                                <div class="title-solo-textarea mt-n1">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="3"
                                    placeholder="Please input Categories of Personal Data Subjects"
                                    style="margin-top: 0px"
                                    :counter="maxLenPersonalDataProcessType"
                                    v-model="request.personalDataProcessType"
                                    :readonly="!canEditRequestForm"
                                    ref="gfk_vendor_contract_generator_form_personal_data_process_type"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needPersonalDataProcessTerm"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.personalDataProcessTerm ? ['red--text'] : [])]">
                                  Personal Data processing term<br/>
                                  <span class="grey--text">* 例如在采购合同总体条款有效期限内处理个人信息</span>
                                </div>
                                <div class="title-solo-textarea mt-n1">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="3"
                                    placeholder="Please input Categories of Personal Data Subjects"
                                    style="margin-top: 0px"
                                    :counter="maxLenPersonalDataProcessTerm"
                                    v-model="request.personalDataProcessTerm"
                                    :readonly="!canEditRequestForm"
                                    ref="gfk_vendor_contract_generator_form_personal_data_process_term"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <template v-if="needPersonalDataSubcontractor">
                                <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                                  <div class="v-card-x-cell-title">
                                    Subprocessor
                                  </div>
                                </v-flex>
                                <template v-for="(pdsdo, pdsdi) in request.personalDataSubcontractorDetails">
                                  <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                    :key="'gfk_vendor_contract_generator_form_personal_data_subcontractor_name' + pdsdi"
                                  >
                                    <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !pdsdo['name'] ? ['red--text'] : [])]">
                                      Subprocessor Name {{ pdsdi + 1 }}
                                    </div>
                                    <div class="title-solo-textarea mt-n1">
                                      <v-textarea
                                        solo
                                        no-resize
                                        rows="3"
                                        placeholder="Please input Subprocessor Name"
                                        style="margin-top: 0px"
                                        :counter="maxLenPersonalDataSubcontractorName"
                                        v-model="pdsdo['name']"
                                        :readonly="!canEditRequestForm"
                                        ref="gfk_vendor_contract_generator_form_personal_data__subcontractor_name"
                                        :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                      >
                                      </v-textarea>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                    :key="'gfk_vendor_contract_generator_form_personal_data_subcontractor_process_details' + pdsdi"
                                  >
                                    <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !pdsdo['processDetails'] ? ['red--text'] : [])]">
                                      Details of subprocessors' processing of Personal Data {{ pdsdi + 1 }}<br/>
                                      <span class="grey--text">* 例如场景描述及处理的主要个人信息的类型</span>
                                    </div>
                                    <div class="title-solo-textarea mt-n1">
                                      <v-textarea
                                        solo
                                        no-resize
                                        rows="3"
                                        placeholder="Please input Details of subprocessors' processing of Personal Data"
                                        style="margin-top: 0px"
                                        :counter="maxLenPersonalDataSubcontractorProcessDetails"
                                        v-model="pdsdo['processDetails']"
                                        :readonly="!canEditRequestForm"
                                        ref="gfk_vendor_contract_generator_form_personal_data_subcontractor_process_details"
                                        :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                      >
                                      </v-textarea>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 :key="'gfk_vendor_contract_generator_form_personal_data_subcontractor_del_btn' + pdsdi">
                                    <v-btn small text
                                      color="error"
                                      class="mt-n4 px-3"
                                      v-show="canEditRequestForm && request.personalDataSubcontractorDetails.length > 1"
                                      @click="clickRemoveSubcontractor(pdsdi)"
                                    >
                                      <v-icon>mdi-minus</v-icon>
                                      <span class="ml-1">Remove Subprocessor {{ pdsdi + 1 }}</span>
                                    </v-btn>
                                  </v-flex>
                                </template>
                                <v-flex xs12 v-show="canEditRequestForm">
                                  <v-btn small text
                                    color="primary"
                                    class="mt-n1 px-3"
                                    v-if="request.personalDataSubcontractorDetails.length < 7"
                                    @click="clickAddSubcontractor"
                                  >
                                    <v-icon>mdi-plus</v-icon>
                                    <span class="ml-1">Add Subprocessor</span>
                                  </v-btn>
                                  <div v-else class="grey--text mt-n1 mx-4" style="font-size: 12px">
                                    Sorry. We only support inserting maximum 7 subprocessors for now.<br/>
                                  </div>
                                </v-flex>
                              </template>

                              <v-flex xs12 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell"
                                v-if="request.generatorUsage && needAdditionalAttachment"
                              >
                                <div v-if="canEditRequestForm" style="width: 100%;display: flex;align-items: center;">
                                  <x-upload
                                    request-class="file"
                                    request-function="upload"
                                    accept=".docx"
                                    :params="{
                                      email: userEmail,
                                      loginStatus: loginStatus
                                    }"
                                    :before-upload="beforeUploadAdditionalAttachment"
                                    :on-error="errorUploadAdditionalAttachment"
                                    :on-success="successUploadAdditionalAttachment"
                                  >
                                    <v-btn text
                                      v-if="request.additionalAttachments.length === 0"
                                      style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                                    >
                                      <v-icon color="primary" size="24" style="margin-right:12px;">
                                        mdi-upload
                                      </v-icon>
                                      <div>Upload <b>Additional Attachment</b> (Word .DOCX Only) (If Any)</div>
                                    </v-btn>
                                  </x-upload>

                                  <v-btn text
                                    v-if="request.additionalAttachments.length > 0"
                                    style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                                    @click="isAdditionalAttachmentUploadConfirming = true"
                                  >
                                    <v-icon color="primary" size="24" style="margin-right:12px;">
                                      mdi-upload
                                    </v-icon>
                                    <div>Change <b>Additional Attachment</b> (Word .DOCX Only)</div>
                                  </v-btn>
                                </div>
                                <div
                                  v-else-if="request.additionalAttachments.length"
                                  class="v-card-x-cell-title pb-1"
                                >
                                  Additional Attachment
                                </div>

                                <v-expand-transition>
                                  <div 
                                    v-if="/* no neccessary */false && isRequestInfoChecked && request.additionalAttachments.length === 0" 
                                    class="red--text"
                                    style="font-size:12px;"
                                  >
                                    Please upload Additional Attachment
                                  </div>
                                </v-expand-transition>
                                
                                <div style="padding-bottom: 16px;">
                                  <file-list
                                    :files="request.additionalAttachments"
                                    :deletable="canEditRequestForm"
                                    :multiple-rows="windowWidth < 650"
                                    @click-download="downloadFile"
                                    @click-delete="deleteAdditionalAttachment"
                                  ></file-list>
                                  <div
                                    tabindex="0"
                                    ref="gfk_vendor_contract_form_additional_attachment"
                                    style="height: 1px;"
                                  ></div>
                                </div>
                              </v-flex>

                              <v-flex xs12 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell"
                                v-if="request.generatorUsage && needAttachmentThirteen"
                              >
                                <div
                                  v-if="!canEditRequestForm"
                                  class="v-card-x-cell-title pb-1"
                                >
                                  Attachment 13
                                </div>
                                <div v-else style="width: 100%;display: flex;align-items: center;">
                                  <x-upload
                                    request-class="file"
                                    request-function="upload"
                                    accept=".docx"
                                    :params="{
                                      email: userEmail,
                                      loginStatus: loginStatus
                                    }"
                                    :before-upload="beforeUploadAttachmentThirteen"
                                    :on-error="errorUploadAttachmentThirteen"
                                    :on-success="successUploadAttachmentThirteen"
                                  >
                                    <v-btn text
                                      v-if="request.attachmentThirteens.length === 0"
                                      style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                                    >
                                      <v-icon color="primary" size="24" style="margin-right:12px;">
                                        mdi-upload
                                      </v-icon>
                                      <div>Upload <b>Attachment 13</b> (Word .DOCX Only)</div>
                                    </v-btn>
                                  </x-upload>

                                  <v-btn text
                                    v-if="request.attachmentThirteens.length > 0"
                                    style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                                    @click="isAttachmentThirteenUploadConfirming = true"
                                  >
                                    <v-icon color="primary" size="24" style="margin-right:12px;">
                                      mdi-upload
                                    </v-icon>
                                    <div>Change <b>Attachment 13</b> (Word .DOCX Only)</div>
                                  </v-btn>
                                </div>

                                <v-expand-transition>
                                  <div 
                                    v-if="isRequestInfoChecked && request.attachmentThirteens.length === 0" 
                                    class="red--text"
                                    style="font-size:12px;"
                                  >
                                    Please upload Attachment 13
                                  </div>
                                </v-expand-transition>
                                
                                <div style="padding-bottom: 16px;">
                                  <file-list
                                    :files="request.attachmentThirteens"
                                    :deletable="canEditRequestForm"
                                    :multiple-rows="windowWidth < 650"
                                    @click-download="downloadFile"
                                    @click-delete="deleteAttachmentThirteen"
                                  ></file-list>
                                  <div
                                    tabindex="0"
                                    ref="gfk_vendor_contract_form_attachment_thirteen"
                                    style="height: 1px;"
                                  ></div>
                                </div>
                              </v-flex>
                            </template>
                          </v-layout>
                        </v-form>
                      </template>
                    </material-app-card>
                  </v-flex>

                  <v-flex
                    v-if="isDraftStepInRequestType && !isPreviewContractLoading"
                    xs12
                    lg6
                    px-2
                    pt-0
                    pb-4
                    :style="{
                      'height': $vuetify.breakpoint.lgAndUp ? '100%' : 'auto',
                      'overflow-x': 'hidden',
                      'overflow-y': $vuetify.breakpoint.lgAndUp ? 'auto' : 'hidden'
                    }"
                  >
                    <div
                      id="legal-other-contract-generater-button"
                      class="pl-10 d-flex align-center"
                      :style="{
                        'margin-top': $vuetify.breakpoint.lgAndUp ? '32px' : '0px',
                        'min-height': $vuetify.breakpoint.lgAndUp ? 'calc(100% - 32px)' : '120px',
                      }"
                    >
                      <span>Please finish request form, then click</span>
                      <v-btn color="primary"
                        class="mx-2"
                        @click="postGenerateContractPreview"
                      >
                        Generate
                      </v-btn>
                      <span>to preview contract.</span>
                    </div>
                  </v-flex>
                  <v-flex
                    v-else
                    xs12
                    lg6
                    px-2
                    pt-0
                    pb-4
                    style="position:relative;height:100%;overflow:hidden;"
                  >
                    <div
                      :style="{
                        'position': 'absolute',
                        'top': '0',
                        'left': '0',
                        'right': '0',
                        'bottom': '0',
                        'overflow': 'hidden',
                        'background-color': 'rgb(209,209,209)'
                      }"
                    >
                      <div style="position: relative;width: 100%;height: 100%;">
                        <div
                          v-if="isPreviewContractLoading || !request.contractPdfMsPath || !contractPreviewSrc"
                          style="
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: rgb(209,209,209);
                          "
                        >
                          <div style="text-align:center">
                            <v-progress-circular
                              :width="5"
                              color="grey"
                              indeterminate
                              :size="30"
                            ></v-progress-circular>
                            <div
                              class="grey--text text--darken-1"
                              :style="{
                                'margin-top': '9px',
                                'font-size': '14px'
                              }"
                            >
                              {{
                                isPreviewContractLoading
                                  ? 'Generating'
                                  : 'Loading'
                              }}
                            </div>
                          </div>
                        </div>
                        <iframe ref="contractPreviewiFrame" v-else :src="contractPreviewSrc" width="100%" height="100%" frameborder="0"></iframe>
                        <!-- <div v-else style="color: white;padding: 6px;">* Contract template will show in this grey box</div> -->
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </div>

            <div v-if="!isMobile" style="position: absolute;bottom: 0;left: 0;right: 0;padding: 2px 12px;display: flex;">
              <v-btn color="primary"
                v-if="canEditRequestForm"
                @click="clickCloseGenerator"
              >
                Previous
              </v-btn>
              <v-btn color="primary"
                v-if="canEditGeneratedContract && isFreshContractPreviewing"
                @click="clickPrevDraftStepInContractPreviewing"
              >
                Re-edit
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary"
                v-if="!canEditRequestForm && !isPreviewContractLoading && request.contractDocMsPath"
                @click="downloadContractDoc"
              >
                Download as {{ isApprovedRequest ? 'PDF' : 'Word' }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary"
                v-if="canEditRequestForm && !isContractTemplateGetting && !isAdditionalAttachmentGetting && !isAttachmentThirteenGetting"
                @click="postGenerateContractPreview"
              >
                Generate
              </v-btn>
              <v-btn color="primary"
                v-if="canEditGeneratedContract && isFreshContractPreviewing"
                @click="isGeneratorDisplayed = false"
              >
                Next
              </v-btn>
              <template v-if="canApprove && request.generatorUsage">
                <v-btn color="primary" class="mr-1"
                  v-if="isCyberSecurityBaseApprovalRequest"
                  @click="clickCyberSecurityNeedImprovement"
                >
                  Need Improvement
                </v-btn>
                <v-btn color="primary"
                  
                  @click="clickApprove"
                >
                  Approve
                </v-btn>
              </template>
            </div>
          </v-card>
        </v-dialog>
        
        <v-dialog
          v-model="isAdditionalAttachmentUploadConfirming"
          persistent
          width="400px"
        >
          <v-card class="pt-6">
            <v-card-text v-if="request.additionalAttachments.length > 0">
              Replace Additional Attachment with the upload file?
            </v-card-text>
            <v-card-text v-else>
              Upload Additional Attachment
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                style="min-width: 90px;"
                flat
                @click="isAdditionalAttachmentUploadConfirming = false"
              >
                No
              </v-btn>
              <x-upload
                request-class="file"
                request-function="upload"
                accept=".docx"
                :params="{
                  email: userEmail,
                  loginStatus: loginStatus
                }"
                :before-upload="beforeUploadAdditionalAttachment"
                :on-error="errorUploadAdditionalAttachment"
                :on-success="successUploadAdditionalAttachment"
              >
                <v-btn
                  dark
                  color="primary"
                  style="min-width: 90px;margin-left: 9px !important;"
                >
                  Yes
                </v-btn>
              </x-upload>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
        <v-dialog
          v-model="isAttachmentThirteenUploadConfirming"
          persistent
          width="400px"
        >
          <v-card class="pt-6">
            <v-card-text v-if="request.attachmentThirteens.length > 0">
              Replace Attachment 13 with the upload file?
            </v-card-text>
            <v-card-text v-else>
              Upload Attachment 13
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                style="min-width: 90px;"
                flat
                @click="isAttachmentThirteenUploadConfirming = false"
              >
                No
              </v-btn>
              <x-upload
                request-class="file"
                request-function="upload"
                accept=".docx"
                :params="{
                  email: userEmail,
                  loginStatus: loginStatus
                }"
                :before-upload="beforeUploadAttachmentThirteen"
                :on-error="errorUploadAttachmentThirteen"
                :on-success="successUploadAttachmentThirteen"
              >
                <v-btn
                  dark
                  color="primary"
                  style="min-width: 90px;margin-left: 9px !important;"
                >
                  Yes
                </v-btn>
              </x-upload>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isCommentsSending"
          persistent
          width="fit-content"
          :fullscreen="isMobile"
          :hide-overlay="isMobile"
          :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
          style="overflow: visible"
        >
          <v-card class="gfk-vendor-contract-comments-card">
            <v-list :class="isMobile ? ['white', 'sidebar-top'] : ['white']">
              <v-list-item>
                <template v-if="isMobile">
                  <v-list-item-action>
                    <v-btn v-if="isMobile" text icon @click="closeSendComments">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-spacer></v-spacer>
                </template>

                <v-list-item-content>
                  <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">
                    {{ isContractSubmitting ? 'Send For Approval' : 'Comments' }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn v-if="isMobile && !isContractSubmitting" text icon @click="clickSendComments">
                    <v-icon>check</v-icon>
                  </v-btn>
                  <v-btn v-else text icon @click="closeSendComments">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-form ref="contract-comment-form" :lazy-validation="true">
              <div :style="{position: 'absolute',top: '72px',bottom: 0,left: 0,right: 0,overflow: 'visible'}">
                <div class="gfk-vendor-contract-comments-container gfk-vendor-contract-comments-container-icon"
                  v-if="isContractSubmitting"
                  :style="{
                    'text-align': 'left',
                    ... isMobile ? { 'padding-bottom': '6px' } : { 'padding': '0 16px 12px' }
                  }"
                >
                  <v-icon color="warning darken-1">
                    mdi-help-circle
                  </v-icon>
                  <div>
                    Your Request will be sent
                    <span v-if="firstApprover.email">to </span>
                    <span v-if="firstApprover.email" class="gfk-vendor-contract-comments-container-icon-bold">{{ firstApprover.name ? (firstApprover.name + ' (' + firstApprover.email + ')') : firstApprover.email }}</span>
                    for approval. Do you confirm to submit this request?
                  </div>
                </div>

                <div
                  class="gfk-vendor-contract-comments-container"
                  :style="isMobile ? { 'padding-bottom': '6px' } : { 'padding': '8px 16px 0' }"
                >
                  <textarea-at
                    v-model="comments.content"
                    :rows="(isMobile ? commentBoxRows : 6) - (isContractSubmitting ? 2 : 0)"
                    :placeholder="`Please input comments${isContractSubmitting ? ' (If any)' : ''}`"
                    :counter="maxLenComments"
                    :solo="!isMobile"
                    no-resize
                    :rules="[...(isContractSubmitting ? [] : [v => (!!v && v.toString().trim() != '') || 'Please input'])]"

                    @input="commentInput"
                    @at="commentAt"
                    @at-insert="commentAtInsert"
                  >
                  </textarea-at>
                </div>
                <div style="display: flex; align-items: center;justify-content: space-between;padding: 0 12px;">
                  <x-upload
                    request-class="file"
                    request-function="upload"
                    :params="{
                      email: userEmail,
                      loginStatus: loginStatus
                    }"
                    :before-upload="beforeUploadAttachment"
                    :on-error="errorUploadAttachment"
                    :on-success="successUploadAttachment"
                  >
                    <v-btn text
                      style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                    >
                      <v-icon color="primary" size="24" style="margin-right:12px;">
                        mdi-upload
                      </v-icon>
                      <div>Upload Supporting Documents (If Any)</div>
                    </v-btn>
                  </x-upload>
                </div>
                <file-list
                  :files="comments.files"
                  :multiple-rows="windowWidth < 650"
                  :style="{
                    'height': 'calc(100% - ' + ((isMobile ? commentBoxRows * 18 + 24 : 244) + 74) + 'px)',
                    'padding': '0 12px',
                    'padding-bottom': (isMobile ? 60 : 0) + 'px',
                    'overflow-y': 'auto'
                  }"
                  @click-download="downloadFile"
                  @click-delete="deleteAttachment"
                ></file-list>

                <div v-if="!isMobile" style="padding: 6px 16px;text-align: right;">
                  <v-btn v-if="isContractSubmitting" color="primary"
                    :disabled="comments.content ? comments.content.length > maxLenComments : false"
                    @click="clickSubmitRequestFinal"
                  >
                    Submit
                  </v-btn>
                  <v-btn v-else color="primary"
                    @click="clickSendComments"
                  >
                    Send
                  </v-btn>
                </div>
              </div>
            </v-form>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isNewDraftedContractUploadCardDisplayed"
          persistent
          width="fit-content"
          :fullscreen="isMobile"
          :hide-overlay="isMobile"
          :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
        >
          <v-card class="gfk-vendor-contract-new-drafted-contract-card">
            <v-list :class="isMobile ? ['white', 'sidebar-top'] : ['white']">
              <v-list-item>
                <template v-if="isMobile">
                  <v-list-item-action>
                    <v-btn v-if="isMobile" text icon @click="isNewDraftedContractUploadCardDisplayed = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-spacer></v-spacer>
                </template>

                <v-list-item-content>
                  <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">
                    Contract Update
                  </v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn v-if="isMobile" text icon @click="clickConfirmNewDraftedContract">
                    <v-icon>check</v-icon>
                  </v-btn>
                  <v-btn v-else text icon @click="isNewDraftedContractUploadCardDisplayed = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div :style="{position: 'absolute',top: '64px',bottom: 0,left: 0,right: 0,overflow: 'visible'}">
              <div style="display: flex; align-items: center;justify-content: space-between;padding: 0 12px;">
                <div style="margin-right: 4px;">
                  <x-upload
                    request-class="file"
                    request-function="upload"
                    accept=".doc,.docx,.pdf"
                    :params="{
                      email: userEmail,
                      loginStatus: loginStatus
                    }"
                    :before-upload="beforeUploadNewDraftedContract"
                    :on-error="errorUploadNewDraftedContract"
                    :on-success="successUploadNewDraftedContract"
                  >
                    <v-btn text
                      style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                    >
                      <v-icon color="primary" size="24" style="margin-right:12px;">
                        mdi-upload
                      </v-icon>
                      <div>Upload New Contract</div>
                    </v-btn>
                  </x-upload>
                </div>
              </div>
              <file-list
                :files="newDraftedContract.files"
                :multiple-rows="windowWidth < 650"
                :style="{
                  'height': 'calc(100% - 64px - ' + (isMobile ? newDraftedContractCommentBoxRows * 18 + 98 : 236) + 'px)',
                  'min-height': '60px',
                  'padding': '0 12px',
                  'padding-bottom': '40px',
                  'overflow-y': 'auto'
                }"
                @click-download="downloadFile"
                @click-delete="deleteNewDraftedContract"
              ></file-list>
              <div
                class="contract-review-comments-container"
                :style="isMobile ? { 'padding-bottom': '3px' } : { 'padding': '12px 18px' }"
              >
                <div :style="{'text-align': 'left','font-size': '14px',padding: '2px ' + ( isMobile ? '18px' : '0')}">Comments</div>
                <textarea-at
                  v-model="newDraftedContract.comments"
                  :rows="isMobile ? newDraftedContractCommentBoxRows : 4"
                  placeholder="Please input comments (if any)"
                  :counter="maxLenDraftComments"
                  solo
                  no-resize
                
                  @input="draftCommentInput"
                  @at="draftCommentAt"
                  @at-insert="draftCommentAtInsert"
                >
                </textarea-at>
              </div>

              <div v-if="!isMobile" style="padding: 0 18px 2px;text-align: right;">
                <v-btn color="primary"
                  :disabled="newDraftedContract.comments ? newDraftedContract.comments.length > maxLenDraftComments : false"
                  @click="clickConfirmNewDraftedContract"
                >
                  Update
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isRequestApproving"
          persistent
          width="fit-content"
        >
          <v-card class="gfk-vendor-contract-approve-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">
                    {{ approval.config.title || 'Approve' }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isRequestApproving = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div style="position: absolute;top: 64px;bottom: 0;left: 0;right: 0;overflow: visible;">
              <div style="text-align: left;padding: 3px 16px;">
                {{ approval.config.text || 'Please confirm the approval of this request.' }}
              </div>
              <div
                class="gfk-vendor-contract-comments-container"
                :style="{ 'padding': '12px 16px' }"
              >
                <textarea-at
                  v-model="approval.comments.content"
                  :rows="3"
                  placeholder="Please input comments (if any)"
                  hide-details
                  solo
                  no-resize
                
                  @input="approvalCommentInput"
                  @at="approvalCommentAt"
                  @at-insert="approvalCommentAtInsert"
                >
                </textarea-at>
              </div>

              <div style="padding: 0 16px 2px;text-align: right;">
                <v-btn color="primary" @click="postApproveRequest">
                  {{ approval.config.button || 'Approve' }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isRequestRecalling"
          persistent
          width="fit-content"
        >
          <v-card class="gfk-vendor-contract-sign-type-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Recall</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isRequestRecalling = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            
            <div style="text-align: left;padding: 12px 24px;display: flex;align-items: start;">
              <v-icon color="warning darken-1" style="margin-top: -3px;margin-right: 9px">mdi-help-circle</v-icon>
              <slot>
                <span>Do you confirm to recall this request?
                  {{
                    cntCanRecall === 1 ?
                      canRecallSubmit ? 'Approval process will restart from the very beginning.' :
                      canRecallSign ? 'Signature process will restart.' :
                      '' 
                    : ''
                  }}
                </span>
              </slot>
            </div>

            <v-form ref="gfk-vendor-contract-recall-form" :lazy-validation="true">
              <v-radio-group v-if="cntCanRecall > 1" class="gfk-vendor-contract-radio-group-vertical gfk-vendor-contract-recall-radio-group mx-4 my-3"
                v-model="recall.type"
                :rules="[v => !!v || 'Please select']"
              >
                <v-radio class="py-2" :value="1" color="primary"
                  v-if="canRecallSubmit"
                >
                  <template v-slot:label>
                    <div>
                      <div>Resubmit</div>
                      <div class="gfk-vendor-contract-recall-radio-description">Approval process will restart from the very beginning</div>
                    </div>
                  </template>
                </v-radio>
                <v-radio class="py-2" :value="2" color="primary"
                  v-if="canRecallSign"
                >
                  <template v-slot:label>
                    <div>
                      <div>Resign</div>
                      <div class="gfk-vendor-contract-recall-radio-description">Signature process will restart</div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-form>

            <div :class="{'mt-3': (cntCanRecall === 1)}" style="padding: 0 16px 8px;text-align: right;">
              <v-btn color="primary"
                :disabled="!recall.type"
                @click="postRecallRequest"
              >
                Yes
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isSignTypeSelecting"
          persistent
          width="fit-content"
        >
          <v-card class="gfk-vendor-contract-sign-type-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Sign</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isSignTypeSelecting = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-radio-group class="gfk-vendor-contract-radio-group-vertical gfk-vendor-contract-sign-con-type-radio-group mx-3 mt-0 mb-2"
              v-model="signFlow.type"
              :rules="[v => !!v || 'Please select']"
            >
              <v-radio class="py-2" :value="1" color="primary" key="gfk-vendor-contract-sign-type-radio-physical-chop">
                <template v-slot:label>
                  <div>
                    <div>Physical Chop</div>
                    <div class="gfk-vendor-contract-sign-con-type-radio-description">Cannot be recalled once selected</div>
                  </div>
                </template>
              </v-radio>
              <v-radio class="py-2" :value="2" color="primary" key="gfk-vendor-contract-sign-type-radio-e-chop">
                <template v-slot:label>
                  <div>
                    <div>E Chop</div>
                    <div class="gfk-vendor-contract-sign-con-type-radio-description">Send a link to client/vendor for chop</div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>

            <div style="padding: 0 16px 8px;text-align: right;">
              <v-btn color="primary"
                :disabled="!signFlow.type"
                :loading="isSignTypeNextLoading"
                @click="finishSelectType"
              >
                Next
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isSignerSelecting"
          persistent
          width="fit-content"
        >
          <v-card class="gfk-vendor-contract-signer-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Signers</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon
                    :disabled="isSignFlowCreating"
                    @click="isSignerSelecting = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-form ref="gfk-vendor-contract-signer-form" :lazy-validation="true">
              <v-layout wrap>
                <v-flex xs12 mx-4 mt-3 mb-2>
                  <div class="sign-flow-signer-header">Internal Signer</div>
                </v-flex>
                <v-flex xs12 mx-4>
                  <v-autocomplete
                    :disabled="false"
                    label="Legal Entity Name"
                    placeholder="Please select"
                    v-model="signFlow.intSigner.organize.name"
                    :items="intSignerOrganizeNames"
                    :rules="[v => !!v || '']"
                  >
                  </v-autocomplete>
                </v-flex>
                <template
                  v-if="signFlow.extSigner.need"
                >
                  <v-flex xs12 mx-4 mt-4 mb-2>
                    <div class="sign-flow-signer-header">External Signer</div>
                  </v-flex>
                  <v-flex xs12 mx-4>
                    <v-combobox
                      :disabled="false"
                      label="Legal Entity Name"
                      placeholder="Please input"
                      no-filter
                      :return-object="false"
                      item-value="name"
                      :search-input.sync="extSignerOrganizeNameKey"
                      v-model="signFlow.extSigner.organize.name"
                      :items="extSignerOrganizeNames"
                      :rules="[v => !!v || '']"
                      @input="inputExtSignerOrganizeName"
                      @blur="() => signFlow.extSigner.organize.name = extSignerOrganizeNameKey"
                    >
                      <template v-slot:item="{ item }">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <div>{{ item.name }}</div>
                          <div v-if="item.hasAgent">
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" size="18" v-on="on">
                                  playlist_add_check
                                </v-icon>
                              </template>
                              <span>Liaison in Legal Entity</span>
                            </v-tooltip>
                          </div>
                          <div v-else>
                            <v-icon color="grey lighten-1" size="18">
                              list
                            </v-icon>
                          </div>
                        </div>
                      </template>
                    </v-combobox>
                  </v-flex>
                  <v-flex xs12 mx-4>
                    <v-combobox
                      :disabled="false"
                      label="Liaison Name"
                      placeholder="Please input"
                      :return-object="false"
                      item-text="name"
                      item-value="name"
                      :search-input.sync="extSignerAgentNameKey"
                      v-model="signFlow.extSigner.agent.name"
                      :items="extSignerAgentNames"
                      :rules="[v => !!v || '']"
                      @input="inputExtSignerAgentName"
                      @blur="() => signFlow.extSigner.agent.name = extSignerAgentNameKey"
                    >
                      <template v-slot:item="{ item }">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <div>{{ item.name }}</div>
                          <div>
                            <span v-if="item.hasAgent">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    mdi-account-check
                                  </v-icon>
                                </template>
                                <span>Liaison matched</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                mdi-account
                              </v-icon>
                            </span>
                            <span v-if="item.hasOrg">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    playlist_add_check
                                  </v-icon>
                                </template>
                                <span>Liaison in Legal Entity</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                list
                              </v-icon>
                            </span>
                          </div>
                        </div>
                      </template>
                    </v-combobox>
                  </v-flex>
                  <v-flex xs12 mx-4>
                    <v-combobox
                      :disabled="false"
                      label="Liaison Mobile No."
                      placeholder="Please input"
                      :return-object="false"
                      item-value="mobile"
                      item-text="mobile"
                      :search-input.sync="extSignerAgentMobileKey"
                      v-model="signFlow.extSigner.agent.mobile"
                      :items="extSignerAgentMobiles"
                      :rules="[v => !!v || '']"
                      @input="inputExtSignerAgentMobile"
                      @blur="() => signFlow.extSigner.agent.mobile = extSignerAgentMobileKey"
                    >
                      <template v-slot:item="{ item }">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <div>{{ item.mobile }}</div>
                          <div>
                            <span v-if="item.hasAgent">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    mdi-account-check
                                  </v-icon>
                                </template>
                                <span>Liaison matched</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                mdi-account
                              </v-icon>
                            </span>
                            <span v-if="item.hasOrg">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    playlist_add_check
                                  </v-icon>
                                </template>
                                <span>Liaison in Legal Entity</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                list
                              </v-icon>
                            </span>
                          </div>
                        </div>
                      </template>
                    </v-combobox>
                  </v-flex>
                  <v-flex xs12 mx-4>
                    <v-combobox
                      :disabled="false"
                      label="Liaison Email Address"
                      placeholder="Please input"
                      :return-object="false"
                      item-value="email"
                      item-text="email"
                      :search-input.sync="extSignerAgentEmailKey"
                      v-model="signFlow.extSigner.agent.email"
                      :items="extSignerAgentEmails"
                      :rules="[v => !!v || '']"
                      @input="inputExtSignerAgentEmail"
                      @blur="() => signFlow.extSigner.agent.email = extSignerAgentEmailKey"
                    >
                      <template v-slot:item="{ item }">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <div>{{ item.email }}</div>
                          <div>
                            <span v-if="item.hasAgent">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    mdi-account-check
                                  </v-icon>
                                </template>
                                <span>Liaison matched</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                mdi-account
                              </v-icon>
                            </span>
                            <span v-if="item.hasOrg">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    playlist_add_check
                                  </v-icon>
                                </template>
                                <span>Liaison in Legal Entity</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                list
                              </v-icon>
                            </span>
                          </div>
                        </div>
                      </template>
                    </v-combobox>
                  </v-flex>
                </template>
              </v-layout>
            </v-form>

            <div style="padding: 0 16px 8px;text-align: right;" class="mt-3">
              <v-btn color="primary"
                :loading="isSignFlowCreating"
                @click="createSignFlow"
              >
                Next
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isSignUrlDisplayed"
          persistent
          width="fit-content"
        >
          <v-card class="gfk-vendor-contract-signer-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Sign</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isSignUrlDisplayed = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-layout wrap pb-10>
              <v-flex xs12 mx-4 mt-2 mb-2>
                <div style="display: flex; align-items: center;">
                  <div class="sign-flow-info-sign-header">Internal Sign</div>
                  <div class="ml-4">
                    <!-- xxxxx -->
                    <v-btn
                      small text icon
                      class="my-0"
                      color="primary"
                      @click="clickIntLink"
                    >
                      <v-icon>mdi-arrow-right-circle</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-flex>
              <v-flex v-if="false" xs12 mx-4>
                <div class="sign-flow-info-sign-content grey--text">URL: {{ signFlow.intSigner.url }}</div>
              </v-flex>
              <v-flex xs12 mx-4 mt-3 mb-3>
                <div class="sign-flow-info-sign-content red--text">NOTE:</div>
                <div class="sign-flow-info-sign-content red--text mb-3">* Signature Consant Password: {{ signFlow.intSigner.pwd }}</div>
              </v-flex>
              <template v-if="isExtSigningRequest && signFlow.extSigner.need">
                <v-flex xs12 mx-4 mt-3 mb-2>
                  <div class="sign-flow-info-sign-header">External Sign</div>
                </v-flex>
                <v-flex v-if="false" xs12 mx-4 mt-2>
                  <div class="sign-flow-info-sign-content grey--text">URL: {{ signFlow.extSigner.url }}</div>
                </v-flex>
              <v-flex xs12 mx-4 mt-2>
                <div class="sign-flow-info-sign-content primary--text">* Notification email has been sent.</div>
              </v-flex>
              </template>
            </v-layout>

          </v-card>
        </v-dialog>

        <confirm-dialog
          :card-class="['gfk-vendor-contract-confirm-card']"
          v-model="isPrevDraftStepConfirming"
          title="Previous"
          comments="Do you confirm to go back to previous step? (Some information may be lost if you go to previous step)"
          @click-yes="clickConfirmPrevDraftStep"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['gfk-vendor-contract-confirm-card']"
          v-model="isNewDraftedContractUploadConfirming"
          comments="Do you conrfirm to upload new contract version to replace the previous version?"
          @click-yes="postReviseDraftedContract"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['gfk-vendor-contract-confirm-card']"
          v-model="isRequestAbandoning"
          title="Abandon"
          comments="Do you confirm to abandon this request?"
          @click-yes="postAbandonRequest"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['gfk-vendor-contract-confirm-card']"
          v-model="isExtSignerConfirming"
          title="External Signer Confirm"
          @click-yes="clickConfirmExtSigner"
          @click-no="cancelConfirmExtSigner"
          @click-close="closeConfirmExtSigner"
        >
          <div>
            <div class="mb-2" v-if="msgExtOrg">{{ msgExtOrg }}</div>
            <div class="mb-2" v-if="msgExtAgent">{{ msgExtAgent }}</div>
            <div class="mb-2" v-if="msgExtOrgXAgent">{{ msgExtOrgXAgent }}</div>
          </div>
        </confirm-dialog>

        <confirm-dialog
          :card-class="['gfk-vendor-contract-confirm-card']"
          v-model="isContractArchiving"
          icon="mdi-information"
          icon-color="primary"
          title="Archive"
          comments="Do you confirm to archive the contract?"
          @click-yes="postArchive"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['gfk-vendor-contract-gfk-entity-alert-card']"
          v-model="isGfkEntityExpiredAlertDisplaying"
          title="Issue - GfK Entity Name"
          icon="mdi-alert"
          icon-color="red"
          :show-no="false"
          yes-text="OK"
          @click-yes="isGfkEntityExpiredAlertDisplaying = false"
        >
          <span>GfK Entity Name <b>"{{ gfkEntityNameInLanguage }}"</b> is unavailable for signing now. <br/>For any query, please contact Scarlett (scarlett.liao.consultant@nielseniq.com) for solution.</span>
        </confirm-dialog>

        <confirm-dialog
          :card-class="['gfk-entity-alert-card']"
          v-model="isGfkEntityPhysicalSignOnlyDisplaying"
          title="Issue - Sales Organization"
          icon="mdi-alert"
          icon-color="red"
          yes-text="OK"
          @click-yes="isGfkEntityPhysicalSignOnlyDisplaying = false"
        >
          <span>Sales Organization <b>"{{ gfkEntityNameInLanguage }}"</b> is only for <b>offline signing</b> now. <br/>For any query, please contact Scarlett (scarlett.liao.consultant@nielseniq.com) for solution.</span>
        </confirm-dialog>
        
        <share-access
          v-model="isAccessSharing"
          :owner="request.requestor"
          :members="collaborators"
          :editable="canShareAccess"
          @save-members="postUpdatedCollaborator"
        ></share-access>
      </v-layout>
    </div>
  </v-fade-transition>
</template>

<script>
// ====== import ======
// --- config ---
import { isProductEnv, isCdRevEnv } from '@/config'

// --- vuex ---
import { mapState, mapGetters, mapActions } from 'vuex'

// --- functions ---
import { xPost, xDownload } from '@/functions/http/axios'
import { toThousands, transAmountToBig, dateFormat } from '@/functions/maths'

// --- components ---
import activityHistory from '@/components/app/history'
import confirmDialog from '@/components/vuetify-extension/confirm-dialog'
import fileList from '@/components/vuetify-extension/file-list'
// import AccountAutocomplete from '@/components/vuetify-extension/account-autocomplete'
import AccountProfile from '@/components/vuetify-extension/account-profile'
import TextareaAt from '@/components/vuetify-extension/textarea-at-v2'
import shareAccess from '@/components/app/dialog/share-access'
import xUpload from '@/components/x-upload'
import MsalFunctions from '@/components/msal/functions/index.vue'



export default {
  components: {
    activityHistory,
    confirmDialog,
    fileList,
    // AccountAutocomplete,
    AccountProfile,
    TextareaAt,
    shareAccess,
    xUpload,
    MsalFunctions,
  },
  data () { return {
    // ====== props ======
    isPageLoading: false,
    isGeneratorDisplayed: false,
    isFreshContractPreviewing: false,
    isPreviewContractLoading: false,
    isRequestInfoChecked: false,
    isOtherSupportInfoChecked: false,
    isApprovalInfoChecked: false,
    isPrevDraftStepConfirming: false,
    isContractSubmitting: false,
    isCommentsSending: false,
    isRequestRecalling: false,
    isRequestAbandoning: false,
    isRequestApproving: false,
    isNewDraftedContractUploadCardDisplayed: false,
    isNewDraftedContractUploadConfirming: false,
    isAccessSharing: false,
    isSignTypeSelecting: false,
    isSignTypeNextLoading: false,
    isSignerSelecting: false,
    isIntSignerLoading: false,
    isExtSignerLoading: false,
    isSignFlowCreating: false,
    isExtSignerConfirming: false,
    isSignUrlDisplayed: false,
    isContractArchiving: false,
    isGfkEntityExpiredAlertDisplaying: false,
    isGfkEntityPhysicalSignOnlyDisplaying: false,
    isCostCenterEditing: false,
    
    isPickerContractEffectiveDateDisplayed: false,
    isPickerContractEffectiveDateDisplayedGen: false,
    isPickerServiceProductDeliveryTimeDisplayed: false,
    isPickerServiceProductDeliveryTimeDisplayedGen: false,
    isPickerServiceProductStartDateDisplayed: false,
    isPickerServiceProductStartDateDisplayedGen: false,
    isPickerServiceProductCompleteDateDisplayed: false,
    isPickerServiceProductCompleteDateDisplayedGen: false,

    isContractTemplateGetting: false,
    
    isAdditionalAttachmentGetting: false,
    isAdditionalAttachmentUploadConfirming: false,
    isAdditionalAttachmentUploading: false,
    
    isAttachmentThirteenGetting: false,
    isAttachmentThirteenUploadConfirming: false,
    isAttachmentThirteenUploading: false,
    
    isOriginalContractUploadConfirming: false,
    isOriginalContractUploading: false,
    
    isDraftedContractUploading: false,
    isNewDraftedContractUploading: false,
    
    isAttachmentUploading: false,
    
    isSvpGlobalProcurementApprovalPdfUploading: false,

    maxLenNameOfServiceProduct: 1000,
    maxLenSpecificationOfServiceProduct: 1000,
    maxLenProjectName: 1000,
    maxLenOtherRequirements: 2000,
    maxLenBackgroundBriefing: 1000,
    maxLenComments: 1000,
    maxLenDraftComments: 1000,
    maxLenPersonalDataPurposeAndUse: 2000,
    maxLenPersonalDataSubjectCategory: 2000,
    maxLenPersonalDataProcessType: 2000,
    maxLenPersonalDataProcessTerm: 2000,
    maxLenPersonalDataSubcontractorName: 400,
    maxLenPersonalDataSubcontractorProcessDetails: 2000,

    msgExtOrg: '',
    msgExtAgent: '',
    msgExtOrgXAgent: '',

    // ====== params ======
    salesOrgs: [],
    currencies: [],
    fxRate: [],
    requestTypes: [],
    productTypes: [],
    contractTypes: [],
    subProductTypes: [],
    categories: [],
    listIsSharedData: [],
    cyberSecurityTypes: [],
    templates: [],
    leaderTitles: [],
    commercialLeaderL1Approvers: [],
    commercialLeaderL2Approvers: [],
    financeL1Approvers: [],
    financeL2Approvers: [],
    legalApprovers: [],
    archiveApprovers: [],
    stages: [],
    urls: [],

    vendorEntityNameKey: '',
    vendorEntityNameItems: [],

    vendorBlackList: [],

    billingConditionKey: '',
    paymentTermKey: '',
    gfkContactAddressKey: '',
    serviceProductDeliveryPlaceKey: '',

    originalContractRequestIdKey: '',
    originalContractRequestIdItems: [],

    extSignerOrganizeNameKey: '',
    // extSignerOrganizeNameItems: [],

    extSignerAgentNameKey: '',
    // extSignerAgentNameItems: [],

    extSignerAgentMobileKey: '',
    // extSignerAgentMobileItems: [],

    extSignerAgentEmailKey: '',
    // extSignerAgentEmailItems: [],
    
    taxRates: [
      { 
        value: '3%', 
        active: true, 
      },
      { 
        value: '6%', 
        active: true, 
      },
      { 
        value: '13%', 
        active: true, 
      },
      { 
        value: '0%', 
        active: true, 
      },
      { 
        value: '0.5%', 
        active: true, 
      },
      { 
        value: '1%', 
        active: true, 
      },
      { 
        value: '1.5%', 
        active: true, 
      },
      { 
        value: '2%', 
        active: true, 
      },
      { 
        value: '5%', 
        active: true, 
      },
      { 
        value: '9%', 
        active: true, 
      },
      { 
        value: '10%', 
        active: true, 
      },
    ],
    invoiceTypes: [
      { 
        value: '1', 
        en: 'Special VAT Invoice', 
        cn: '增值税专用发票', 
        active: true, 
      },
      { 
        value: '2', 
        en: 'Normal VAT Invoice', 
        cn: '增值税普通发票',  
        active: true,
      },
      { 
        value: '3', 
        en: 'Proforma Invoice', 
        cn: '形式发票',  
        active: true,
      },
    ],
    billingConditions: [
      {
        en: 'The service/product is accepted by GfK',
        cn: 'GfK验收服务/产品', 
        active: true,
      },
      {
        en: 'The contract is signed',
        cn: '合同签署', 
        active: true,
      },
    ],
    paymentTerms: [
      {
        value: '90',
        active: true,
      },
    ],
    arrayGfkAddress: [
      {
        en: "Rm2401-2402 (nominal room No. 2801-2802) Greentech Tower, No.436 Hengfeng Road, Jing’an District, Shanghai",
        cn: '上海市静安区恒丰路 436 号 2401-2402 室（名义室号为 2801-2802 室）',
      },
      {
        en: 'Unit 08-09, 11/F ,501 Nei, 5F-45F, Building 3#, No. 20 Jin He East Avenue, Chaoyang District, Beijing, China',
        cn: '北京市朝阳区金和东路20号院3号楼5至45层501内11层08-09单 元',
      },
    ],
    
    // time flag - 保证读取最新数据
    tfPreviewingContract: null,
    flagPostExtOrgs: null,
    flagPostExtAgents: [null, null],
    flagPostExtOrgsAndAgents: [null, null],

    extraRequestors: [],

    // ====== form ======
    request: {
      requestor: {
        email: null,
        name: null,
        icon: null,
      },
      team: null,
      draftStep: null,
      type: null,
      selectGeneratorUsage: null,
      generatorUsage: null,
      needForm: null,
      needProductType: null,
      needContractType: null,
      needSubProductType: null,
      needLanguage: null,
      needCategory: null,
      needGfkEntityName: null,
      needVendorEntityName: null,
      needContractEffectiveDate: null,
      needTaxRate: null,
      needInvoiceType: null,
      needBillingCondition: null,
      needPaymentTerm: null,
      needNameOfServiceProduct: null,
      needSpecificationOfServiceProduct: null,
      needServiceProductDeliveryTime: null,
      needServiceProductDeliveryPlace: null,
      needServiceProductStartDate: null,
      needServiceProductCompleteDate: null,
      needOtherRequirements: null,
      needCurrency: null,
      needContractValueIncludeTax: null,
      needProjectName: null,
      needEstimatedContractValue: null,
      needConfirmPersonalInfo: null,
      needGfkContactAddress: null,
      needGfkZipCodes: null,
      needGfkContactPerson: null,
      needGfkContactEmail: null,
      needVendorContactAddress: null,
      needVendorZipCodes: null,
      needVendorContactPerson: null,
      needVendorContactEmail: null,
      needPersonalDataPurposeAndUse: null,
      needPersonalDataSubjectCategory: null,
      needPersonalDataProcessType: null,
      needPersonalDataProcessTerm: null,
      needPersonalDataSubcontractor: null,
      needAdditionalAttachment: null,
      needAttachmentThirteen: null,
      needOriginalContract: null,
      needOtherSupportInfo: null,
      needSvpGlobalProcurementApprovalPdf: null,
      needCommercialLeaderL1Approval: null,
      needCommercialLeaderL2Approval: null,
      needFinanceL1Approval: null,
      needFinanceL2Approval: null,
      needLegalApproval: null,
      productType: null,
      contractType: null,
      subProductType: null,
      language: null,
      category: null,
      contractEffectiveDate: null,
      gfkEntityName: null,
      vendorEntityName: null,
      taxRate: null,
      invoiceType: null,
      billingCondition: null,
      paymentTerm: null,
      nameOfServiceProduct: null,
      specificationOfServiceProduct: null,
      projectName: null,
      serviceProductDeliveryTime: null,
      serviceProductDeliveryPlace: null,
      serviceProductStartDate: null,
      serviceProductCompleteDate: null,
      estimatedContractValue: null,
      contractValueIncludeTax: null,
      currency: null,
      otherRequirements: null,
      originalContractType: null,

      isObtainPersonalInfo: null,
      isAuthorizePersonalInfo: null,
      gfkContactAddress: null,
      gfkZipCodes: null,
      gfkContactPerson: null,
      gfkContactEmail: null,
      vendorContactAddress: null,
      vendorZipCodes: null,
      vendorContactPerson: null,
      vendorContactEmail: null,
      
      personalDataPurposeAndUse: null,
      personalDataSubjectCategory: null,
      personalDataProcessType: null,
      personalDataProcessTerm: null,
      personalDataSubcontractorDetails: [{
        name: null,
        processDetails: null,
      }],

      uploadedOriginalContracts: [],
      originalContractRequestId: null,

      additionalAttachments: [],
      attachmentThirteens: [],

      contractPdfMsPath: '',
      contractDocMsPath: '',
      contractPdfLcPath: '',
      contractDocLcPath: '',

      isForAdvancePayment: null,

      backgroundBriefing: null,

      draftedContract: [],

      listSvpGlobalProcurementApprovalPdf: [],
      
      isSharedData: null,
      cyberSecurityType: null,
      servicenowTicketNumber: '',
      
      commercialLeaderL1Approver: {
        email: null,
        name: null,
        icon: null,
      },
      commercialLeaderL2Approver: {
        email: null,
        name: null,
        icon: null,
      },
      financeL1Approver: {
        email: null,
        name: null,
        icon: null,
      },
      financeL2Approver: {
        email: null,
        name: null,
        icon: null,
      },
      legalApprover: {
        email: null,
        name: null,
        icon: null,
      },
      
      archiveApprover: {
        email: null,
        name: null,
        icon: null,
      },

      signType: null,
      needExtSign: null,
      needArchive: null,
      canRecallArchive: null,
    },

    dummyContractUnderReview: [{
      name: 'Contract Aligned'
    }],

    collaborators: [],
    ats: [],
    
    roles: [],

    history: [],

    originalContractRequestContracts: [],
    originalContractRequestTime: null,
    originalContractRequestStage: null,

    // comments
    comments: {
      content: null,
      files: [],
    },
    
    newDraftedContract: {
      files: [],
      comments: '',
    },

    approval: {
      comments: {
        content: '',
      },
      config: {}
    },

    recall: {
      type: null
    },

    signConf: {
      defSignType: null,
      needExtSign: null,
    },
    signFlow: {
      esignFlowId: null,
      type: null,
      intSigner: {
        organize: {
          id: null,
          name: null,
        },
        signed: null,
        url: null,
        pwd: null,
      },
      extSigner: {
        need: null,
        organize: {
          id: null,
          name: null,
        },
        agent: {
          id: null,
          name: null,
          mobile: null,
          email: null,
        },
        // organize and agent binded ?
        binded: false,
        signed: null,
        url: null,
      }
    },
    intOrganizes: [],
    extOrganizesXAgents: [],
    extAgentsXOrganizes: [],

    cyberSecurityClassificationGuide: {
      isClicked: false,
      isRead: false,
      timeCounterToggle: 0,
    },
    servicenowTicket: {
      isClicked: false,
      isDone: false,
      timeCounterToggle: 0,
    },

    reviewPanel: {
      alerts: [],
    },

    // preview
    contractPreviewSrc: null,

    // msal
    refNo: Date.parse(new Date()),
  }},
  computed: {
    // ====== vuex ======
    ...mapState('app', {
      windowWidth: state => state.windowSize.width,
      windowHeight: state => state.windowSize.height,
      themeColor: state => state.color,
    }),
    ...mapGetters('app', [
      'isMobile',
    ]),
    ...mapGetters('user', [
      'loginStatus',
      'userEmail',
      'userName',
      'userPhotoUrl',
      'userTeam',
      'userLocation',
    ]),

    // ====== props ======
    // --- msal ---
    refMsalFunction () {
      return `msal-function-${this.refNo}`
    },
    // --- request ---
    currStageId () {
      if (!this.history.length) return '1'
      return this.history[0].stageId || '1'
    },
    isNewRequest () {
      return this.token === 'new'
    },
    isDraftedRequest () {
      return this.currStageId === '1'
    },
    isRecalledRequest () {
      return this.currStageId === '3'
    },
    isPendingRequest () {
      return this.isNewRequest || this.isDraftedRequest || this.isRecalledRequest
    },
    isAbandonedRequest () {
      return this.currStageId === '4'
    },
    isCommercialLeaderL1ApprovalRequest () {
      return this.currStageId === '6'
    },
    isCommercialLeaderL2ApprovalRequest () {
      return this.currStageId === '7'
    },
    isFinanceL1ApprovalRequest () {
      return this.currStageId === '9'
    },
    isFinanceL2ApprovalRequest () {
      return this.currStageId === '10'
    },
    isLegalApprovalRequest () {
      return this.currStageId === '8'
    },
    isApprovalRequest () {
      return (
        this.isCommercialLeaderL1ApprovalRequest
        || this.isCommercialLeaderL2ApprovalRequest
        || this.isFinanceL1ApprovalRequest
        || this.isFinanceL2ApprovalRequest
        || this.isLegalApprovalRequest
      )
    },
    isJustApprovedRequest () {
      return this.currStageId === '5'
    },
    isIntSigningRequest () {
      return this.currStageId === '11'
    },
    isIntSignedRequest () {
      return this.currStageId === '12'
    },
    isExtSigningRequest () {
      return this.currStageId === '13'
    },
    isExtSignedRequest () {
      return this.currStageId === '14'
    },
    isSigningRequest () {
      return (
        this.isIntSigningRequest
        || this.isIntSignedRequest
        || this.isExtSigningRequest
        || this.isExtSignedRequest
      )
    },
    isArchivingRequest () {
      return this.currStageId === '15'
    },
    isArchivedRequest () {
      return this.currStageId === '16'
    },
    isApprovedRequest () {
      return (
        this.isJustApprovedRequest
        || this.isSigningRequest
        || this.isArchivingRequest
        || this.isArchivedRequest
      )
    },
    isDraftStepInRequestType () {
      return this.isPendingRequest && this.request.draftStep === '1'
    },
    isDraftStepInDraftedContract () {
      return this.isPendingRequest && this.request.draftStep === '2'
    },
    isDraftStepInContractInfo () {
      return this.isPendingRequest && this.request.draftStep === '3'
    },
    isDraftStepInGeneratedContract () {
      return this.isPendingRequest && this.request.draftStep === '4'
    },
    isDraftStepInCyberSecurity () {
      return this.isPendingRequest && this.request.draftStep === '6'
    },
    isDraftStepInOtherSupportInfo () {
      return this.isPendingRequest && this.request.draftStep === '7'
    },
    isDraftStepInApprovalInfo () {
      return this.isPendingRequest && this.request.draftStep === '5'
    },

    // ------ user ------   
    isRequestor () {
      return this.userEmail === this.request.requestor.email
    },
    isCollaborator () {
      return !!this.collaborators.find(el => this.userEmail === el.email)
    },
    isAt () {
      return !!this.ats.find(el => this.userEmail === el.email)
    },
    isArchiveApprover () {
      return (
        this.userEmail === this.request.archiveApprover.email
        || !!this.archiveApprovers.find(el => this.userEmail === el.email && el.role === 'Approver')
      )
    },
    isArchiveSupporter () {
      return !!this.archiveApprovers.find(el => this.userEmail === el.email && el.role === 'Supporter')
    },
    isArchiveViewer () {
      return !!this.archiveApprovers.find(el => this.userEmail === el.email && el.role === 'Viewer')
    },

    canEditRequest () {
      return (
        this.checkUserAccess()
        && (this.isRequestor || this.isCollaborator)
        && this.isPendingRequest
      )
    }, 
    canEditRequestType () {
      return (
        this.canEditRequest
        && this.isDraftStepInRequestType
      )
    },
    canEditDraftedContract () {
      return (
        this.canEditRequest
        && this.isDraftStepInDraftedContract
      )
    },
    canEditRequestForm () {
      return (
        this.canEditRequest
        && (
          (this.isDraftStepInRequestType && !this.isPreviewContractLoading)
          || this.isDraftStepInContractInfo
        )
      )
    },
    canEditGeneratedContract () {
      return (
        this.canEditRequest
        && this.isDraftStepInGeneratedContract
      )      
    },
    canEditOtherSupportInfoForm () {
      return (
        this.canEditRequest
        && this.isDraftStepInOtherSupportInfo
      )
    },
    canReviseContract () {
      return (
        (this.isRequestor || this.isCollaborator)
        && this.isApprovalRequest
      )
    },
    canRecallSubmit () {
      return (
        (this.isRequestor || this.isCollaborator)
        && (this.isApprovalRequest || (this.isApprovedRequest && !this.isArchivedRequest) || (this.isArchivedRequest && this.request.canRecallArchive))
      )
    },
    canRecallSign () {
      return (
        (this.isRequestor || this.isCollaborator)
        && (this.isSigningRequest || this.isArchivingRequest || (this.isArchivedRequest && this.request.canRecallArchive))
      )
    },
    cntCanRecall () {
      return (
        this.canRecallSubmit * 1
        + this.canRecallSign * 1
      )
    },
    canRecall () {
      return !!this.cntCanRecall
    },
    canAbandon () {
      return (
        (this.isRequestor || this.isCollaborator)
        && !this.isNewRequest
        && (this.isDraftedRequest || this.isRecalledRequest)
      )
    },
    canEditCyberSecurityForm () {
      return (
        this.canEditRequest
        && this.isDraftStepInCyberSecurity
      )      
    },
    canEditApprovalForm () {
      return (
        this.canEditRequest
        && this.isDraftStepInApprovalInfo
      )      
    },
    canApprove () {
      return this.isApprovalRequest 
        && (
          this.roles.indexOf('CurrentApprover') !== -1
          || this.roles.indexOf('CurrentSupporter') !== -1
        )
    },
    canComment () {
      return (
        (
          !this.isPendingRequest
          && (
            this.isRequestor
            || this.isCollaborator
          )
        )
        || (
          (
            this.isApprovalRequest
            || this.isArchivingRequest
          )
          && (
            this.roles.indexOf('CurrentApprover') !== -1
            || this.roles.indexOf('CurrentSupporter') !== -1
            || this.isAt
          )
        )
      )
    },
    canView () {
      return (
        !this.isAbandonedRequest
        && (
          this.isRequestor
          || this.isCollaborator
          || (
            (
              this.isApprovalRequest
              || this.isApprovedRequest
            )
            && (
              this.isAt
              || this.canApprove
              || this.canArchive
              || this.roles.indexOf('CurrentViewer') !== -1
              || this.roles.indexOf('PrevApprover') !== -1
              || this.roles.indexOf('PrevSupporter') !== -1
              || this.roles.indexOf('PrevViewer') !== -1
            )
          )
        )
      )
    },
    canShareAccess () {
      return this.isRequestor || this.isCollaborator
    },
    canSign () {
      return (
        (this.isRequestor || this.isCollaborator)
        && this.isJustApprovedRequest
      )
    },
    canViewSignInfo () {
      return (
        (this.isRequestor || this.isCollaborator)
        && this.isSigningRequest
      )
    },
    canDownloadSignFile () {
      return (
        (
          // (this.isRequestor || this.isCollaborator || this.isArchiveApprover || this.isArchiveSupporter)
          // && 
          (this.isArchivingRequest || this.isArchivedRequest)
        )
      )
    },
    canArchive () {
      return (
        (this.isArchiveApprover || this.isArchiveSupporter)
        && this.isArchivingRequest
      )
    },

    // --- form ---
    canSelectGeneratorUsage () {
      return this.getRequestProps('selectGeneratorUsage')
    },
    needForm () {
      return this.getRequestProps('needForm')
    },
    needProductType () {
      return this.getRequestProps('needProductType')
    },
    disabledProductType () {
      return true
      // return (this.isPendingRequest && this.requestTypeObj.needProductType === 2)
    },
    needContractType () {
      // let change = (this.needProductType && this.request.productType === '1')
      return this.getRequestProps('needContractType')
    },
    needSubProductType () {
      return this.getRequestProps('needSubProductType')
    },
    needLanguage () {
      return this.getRequestProps('needLanguage')
    },
    disabledLanguage () {
      return (
        this.request.generatorUsage
        && (
          (
            this.requestTypeObj.needLanguage === 2
            && this.needProductType
            && this.request.productType === '1'
            && this.needContractType
            && this.request.contractType === '1'
          )
          || this.requestTypeObj.needLanguage === 4
        )
      )
    },
    needCategory () {
      return this.getRequestProps('needCategory')
    },
    needContractEffectiveDate () {
      return this.getRequestProps('needContractEffectiveDate')
    },
    needGfkEntityName () {
      return this.getRequestProps('needGfkEntityName') || this.getTemplateProps('needGfkEntityName')
    },
    needVendorEntityName () {
      return this.getRequestProps('needVendorEntityName') || this.getTemplateProps('needVendorEntityName')
    },
    needTaxRate () {
      return this.getTemplateProps('needTaxRate')
    },
    needInvoiceType () {
      return this.getTemplateProps('needInvoiceType')
    },
    needBillingCondition () {
      return this.getTemplateProps('needBillingCondition')
    },
    needPaymentTerm () {
      return this.getTemplateProps('needPaymentTerm')
    },
    needNameOfServiceProduct () {
      return this.getTemplateProps('needNameOfServiceProduct')
    },
    needSpecificationOfServiceProduct () {
      return this.getTemplateProps('needSpecificationOfServiceProduct')
    },
    needProjectName () {
      return this.getTemplateProps('needProjectName')
    },
    needServiceProductDeliveryTime () {
      return this.getTemplateProps('needServiceProductDeliveryTime')
    },
    needServiceProductDeliveryPlace () {
      return this.getTemplateProps('needServiceProductDeliveryPlace')
    },
    needServiceProductStartDate () {
      return this.getTemplateProps('needServiceProductStartDate')
    },
    needServiceProductCompleteDate () {
      return this.getTemplateProps('needServiceProductCompleteDate')
    },
    needEstimatedContractValue () {
      return this.getTemplateProps('needEstimatedContractValue')
    },
    needContractValueIncludeTax () {
      return this.getTemplateProps('needContractValueIncludeTax')
    },
    needCurrency () {
      return this.getTemplateProps('needCurrency')
    },
    needOtherRequirements () {
      return this.getTemplateProps('needOtherRequirements')
    },
    needConfirmPersonalInfo () {
      return this.getTemplateProps('needConfirmPersonalInfo')
    },
    needGfkContactAddress () {
      return this.getTemplateProps('needGfkContactAddress')
    },
    needGfkZipCodes () {
      return this.getTemplateProps('needGfkZipCodes')
    },
    needGfkContactPerson () {
      return this.getTemplateProps('needGfkContactPerson')
    },
    needGfkContactEmail () {
      return this.getTemplateProps('needGfkContactEmail')
    },
    needVendorContactAddress () {
      return this.getTemplateProps('needVendorContactAddress')
    },
    needVendorZipCodes () {
      return this.getTemplateProps('needVendorZipCodes')
    },
    needVendorContactPerson () {
      return this.getTemplateProps('needVendorContactPerson')
    },
    needVendorContactEmail () {
      return this.getTemplateProps('needVendorContactEmail')
    },
    needPersonalDataPurposeAndUse () {
      return this.getTemplateProps('needPersonalDataPurposeAndUse')
    },
    needPersonalDataSubjectCategory () {
      return this.getTemplateProps('needPersonalDataSubjectCategory')
    },
    needPersonalDataProcessType () {
      return this.getTemplateProps('needPersonalDataProcessType')
    },
    needPersonalDataProcessTerm () {
      return this.getTemplateProps('needPersonalDataProcessTerm')
    },
    needPersonalDataSubcontractor () {
      return this.getTemplateProps('needPersonalDataSubcontractor')
    },

    needAdditionalAttachment () {
      return this.getTemplateProps('needAdditionalAttachment')
    },
    needAttachmentThirteen () {
      return this.getTemplateProps('needAttachmentThirteen')
    },
    needOriginalContract () {
      return this.getTemplateProps('needOriginalContract')
    },
    needOtherSupportInfo () {
      return this.getTemplateProps('needOtherSupportInfo')
    },
    needSvpGlobalProcurementApprovalPdf () {
      return this.getTemplateProps('needSvpGlobalProcurementApprovalPdf')
    },

    needCommercialLeaderL1Approval () {
      return this.getTemplateProps('needCommercialLeaderL1Approval')
    },
    needCommercialLeaderL2Approval () {
      return this.getTemplateProps('needCommercialLeaderL2Approval')
    },
    needCommercialLeaderApproval () {
      return this.needCommercialLeaderL1Approval || this.needCommercialLeaderL2Approval
    },
    needFinanceL1Approval () {
      return this.getTemplateProps('needFinanceL1Approval')
    },
    needFinanceL2Approval () {
      return this.getTemplateProps('needFinanceL2Approval')
    },
    needFinanceApproval () {
      return this.needFinanceL1Approval || this.needFinanceL2Approval
    },
    needLegalApproval () {
      return this.getTemplateProps('needLegalApproval')
    },
    needApproval () {
      return (
        this.needCommercialLeaderL1Approval
        || this.needCommercialLeaderL2Approval
        || this.needFinanceL1Approval
        || this.needFinanceL2Approval
        || this.needLegalApproval
      )
    },

    checkCommercialLeaderL1Approvers () {
      return (!this.needCommercialLeaderL1Approval
        || this.isEmail(this.commercialLeaderL1Approver.email))
    },
    checkCommercialLeaderL2Approvers () {
      return (!this.needCommercialLeaderL2Approval
        || this.isEmail(this.commercialLeaderL2Approver.email))
    },
    checkCommercialLeaderApprovers () {
      return (this.checkCommercialLeaderL1Approvers && this.checkCommercialLeaderL2Approvers)
    },
    checkFinanceL1Approvers () {
      return (!this.needFinanceL1Approval
        || this.isEmail(this.financeL1Approver.email))
    },
    checkFinanceL2Approvers () {
      return (!this.needFinanceL2Approval
        || this.isEmail(this.financeL2Approver.email))
    },
    checkFinanceApprovers () {
      return (this.checkFinanceL1Approvers && this.checkFinanceL2Approvers)
    },
    checkLegalApprovers () {
      return (!this.needLegalApproval
        || this.isEmail(this.legalApprover.email))
    },

    // --- others ---
    isLanguageChinese () {
      return (['Chinese', 'chinese', 'CN', 'Cn', 'cn'].indexOf(this.request.language) !== -1)
    },
    originalContractRequestChecked () {
      return this.originalContractRequestContracts.length
        && !!this.originalContractRequestTime
        && !!this.originalContractRequestStage
    },

    urlGfkVendorCyberSecurityClassificationGuideEN () {
      return (this.urls.find(el => el.value === 'gfkVendorCyberSecurityClassificationGuideEN') || {}).url
    },
    urlGfkVendorCyberSecurityClassificationGuideCN () {
      return (this.urls.find(el => el.value === 'gfkVendorCyberSecurityClassificationGuideCN') || {}).url
    },
    urlGfkVendorServiceNowCyberSecurityAccessment () {
      return (this.urls.find(el => el.value === 'gfkVendorServiceNowCyberSecurityAccessment') || {}).url
    },
    isReadVendorCyberSecurityClassificationGuideOrMore () {
      return (this.cyberSecurityClassificationGuide.isRead ||
        this.request.cyberSecurityType)
    },

    // ====== params ======
    token () {
      return this.$route.params.token
    },
    requestTypeObj () {
      let index = this.requestTypes.findIndex(el => el.value === this.request.type)
      if (index === -1) {
        return {
          needProductType: false,
          needLanguage: false,
        }
      }
      return this.requestTypes[index]
    },
    templateObj () {
      let index = this.templates.findIndex(el => {
        let matchRequestType = (el.matchRequestType === '0' || el.matchRequestType === this.request.type)
        let matchProductType = (el.matchProductType === '0' || el.matchProductType === this.request.productType)
        let matchContractType = (el.matchContractType === '0' || el.matchContractType === this.request.contractType)
        let matchSubProductType = (el.matchSubProductType === '0' || el.matchSubProductType === this.request.subProductType)
        let matchLanguage = (el.matchLanguage === '' || el.matchLanguage === this.request.language)
        return (matchRequestType && matchProductType && matchContractType && matchSubProductType && matchLanguage)
      })
      if (index === -1) return null
      return this.templates[index]
    },
    templateMissingBlanks () {
      let ret = []

      if (this.needProductType && !this.request.productType) { ret.push('Product Type') }
      if (this.needContractType && !this.request.contractType) { ret.push('Contract Type') }
      if (this.needSubProductType && !this.request.subProductType) { ret.push('Sub Product Type') }
      if (this.needLanguage && !this.request.language) { ret.push('Language') }

      return ret.join(', ')
    },

    isSignerLoading () {
      return this.isIntSignerLoading || this.isExtSignerLoading
    },

    // form    
    estimatedContractValueToThousands () {
      let floAmount = parseFloat(this.request.estimatedContractValue)
      if (isNaN(floAmount)) return ''
      return toThousands(floAmount.toFixed(2)) + (floAmount%1 ? '' : '.00')
    },
    contractValueIncludeTaxToThousands () {
      let floAmount = parseFloat(this.request.contractValueIncludeTax)
      if (isNaN(floAmount)) return ''
      return toThousands(floAmount.toFixed(2)) + (floAmount%1 ? '' : '.00')
    },

    // approval
    protentialCommercialLeaderL1Approver () {
      return (this.commercialLeaderL1Approvers.find(el => el.active && (el.role === 'Approver')) ?? {})
    },
    protentialCommercialLeaderL2Approver () {
      return (this.commercialLeaderL2Approvers.find(el => el.active && (el.role === 'Approver')) ?? {})
    },
    protentialFinanceL1Approver () {
      return (this.financeL1Approvers.find(el => el.active && (el.role === 'Approver')) ?? {})
    },
    protentialFinanceL2Approver () {
      return (this.financeL2Approvers.find(el => el.active && (el.role === 'Approver')) ?? {})
    },
    protentialLegalApprover () {
      return (this.legalApprovers.find(el => el.active && (el.role === 'Approver')) ?? {})
    },
    commercialLeaderL1Approver () {
      if (this.isPendingRequest) {
        return {
          email: this.protentialCommercialLeaderL1Approver.email || '',
          name: this.protentialCommercialLeaderL1Approver.name || '',
          icon: this.protentialCommercialLeaderL1Approver.icon || '',
        }
      } else {
        return {
          email: this.request.commercialLeaderL1Approver.email || '',
          name: this.request.commercialLeaderL1Approver.name || '',
          icon: this.request.commercialLeaderL1Approver.icon || '',
        }
      }
    },
    commercialLeaderL2Approver () {
      if (this.isPendingRequest) {
        return {
          email: this.protentialCommercialLeaderL2Approver.email || '',
          name: this.protentialCommercialLeaderL2Approver.name || '',
          icon: this.protentialCommercialLeaderL2Approver.icon || '',
        }
      } else {
        return {
          email: this.request.commercialLeaderL2Approver.email || '',
          name: this.request.commercialLeaderL2Approver.name || '',
          icon: this.request.commercialLeaderL2Approver.icon || '',
        }
      }
    },
    financeL1Approver () {
      if (this.isPendingRequest) {
        return {
          email: this.protentialFinanceL1Approver.email || '',
          name: this.protentialFinanceL1Approver.name || '',
          icon: this.protentialFinanceL1Approver.icon || '',
        }
      } else {
        return {
          email: this.request.financeL1Approver.email || '',
          name: this.request.financeL1Approver.name || '',
          icon: this.request.financeL1Approver.icon || '',
        }
      }
    },
    financeL2Approver () {
      if (this.isPendingRequest) {
        return {
          email: this.protentialFinanceL2Approver.email || '',
          name: this.protentialFinanceL2Approver.name || '',
          icon: this.protentialFinanceL2Approver.icon || '',
        }
      } else {
        return {
          email: this.request.financeL2Approver.email || '',
          name: this.request.financeL2Approver.name || '',
          icon: this.request.financeL2Approver.icon || '',
        }
      }
    },
    legalApprover () {
      if (this.isPendingRequest) {
        return {
          email: this.protentialLegalApprover.email || '',
          name: this.protentialLegalApprover.name || '',
          icon: this.protentialLegalApprover.icon || '',
        }
      } else {
        return {
          email: this.request.legalApprover.email || '',
          name: this.request.legalApprover.name || '',
          icon: this.request.legalApprover.icon || '',
        }
      }
    },

    // stage
    stagesDisplayed () {
      let stages = []
      let index = this.stages.findIndex(el => el.id === this.currStageId)
      if (index === -1) return []      
      stages.push(...this.allPrevStages)

      let index_d = stages.length

      stages.push(this.stages[index])
      stages.push(...this.allNextStages)

      let staged_grouped = []
      stages.forEach((stage, index_s) => {
        let index_g = staged_grouped.findIndex(el => el.content === stage.content)
        if (index_g === -1) {
          index_g = staged_grouped.length
          staged_grouped.push({
            content: stage.content,
            ids: [stage.id],
            done: 0,
            total: 1,
          })
        } else {
          staged_grouped[index_g].ids.push(stage.id)
          staged_grouped[index_g].total++
        }

        if (index_s < index_d) { staged_grouped[index_g].done++ }
      })

      return staged_grouped
    },
    allPrevStages () {
      let index = this.stages.findIndex(el => el.id === this.currStageId)
      if (index === -1) return []
      let prevStr = this.stages[index].prev
      if (!prevStr) return []
      let prevs = prevStr.split(';')
      let stages = []
      prevs.forEach(prev => {
        let index2 = this.stages.findIndex(el => el.id === prev && (!el.activeField || this.request[el.activeField]))
        if (index2 === -1) return
        stages.push(this.stages[index2])
      });
      return stages
    },
    allNextStages () {
      let index = this.stages.findIndex(el => el.id === this.currStageId)
      if (index === -1) return []
      let nextStr = this.stages[index].next
      if (!nextStr) return []
      let nexts = nextStr.split(';')
      let stages = []
      nexts.forEach(next => {
        let index2 = this.stages.findIndex(el => el.id === next && (!el.activeField || this.request[el.activeField]))
        if (index2 === -1) return
        stages.push(this.stages[index2])
      });
      return stages
    },
    stageIndexOfDisplayed () {
      return this.stagesDisplayed.findIndex(el => el.ids.indexOf(this.currStageId) !== -1)
    },

    // history
    historyDisplayed () {
      return this.history.filter(el => !!el.display)
    },

    commentBoxRows () {
      return parseInt((this.windowHeight - 64 - 45) / 18 / 2)
    },
    newDraftedContractCommentBoxRows () {
      return parseInt((this.windowHeight - 64 - 45) / 18 / 2)
    },

    // list / selections
    salesOrgsForSelect () {
      return this.salesOrgs.filter(el => el?.active)
    },
    salesOrgPlaceholder () {
      return (
        this.isPendingRequest
          ? ''
          : this.salesOrgs?.find(el => el.value === this.request.gfkEntityName)?.[this.needLanguage ? this.request.language === 'Chinese' ? 'textCn' : 'textEn' : 'text'] ?? this.request.gfkEntityName ?? ''
      )
    },
    requestTypesForSelect () {
      let requestTypes = this.requestTypes
        .filter(el => el.active)
      let index1 = requestTypes.findIndex(el => el.value === this.request.type)
      let index2 = this.requestTypes.findIndex(el => el.value === this.request.type)
      if (index1 === -1 && index2 !== -1) {
        let requestType = { 
          ...this.requestTypes[index2],
          disabled: true,
        }
        requestTypes.push(requestType)
      }
      return requestTypes
    },
    productTypesForSelect () {
      let productTypes = this.productTypes
        .filter(el => el.active)
      let index1 = productTypes.findIndex(el => el.value === this.request.productType)
      let index2 = this.productTypes.findIndex(el => el.value === this.request.productType)
      if (index1 === -1 && index2 !== -1) {
        let productType = { 
          ...this.productTypes[index2],
          disabled: true,
        }
        productTypes.push(productType)
      }
      return productTypes
    },
    contractTypesForSelect () {
      let contractTypes = this.contractTypes
        .filter(el => el.active)
      let index1 = contractTypes.findIndex(el => el.value === this.request.contractType)
      let index2 = this.contractTypes.findIndex(el => el.value === this.request.contractType)
      if (index1 === -1 && index2 !== -1) {
        let contractType = { 
          ...this.contractTypes[index2],
          disabled: true,
        }
        contractTypes.push(contractType)
      }
      if (this.isPendingRequest && this.request.type === '1' && this.request.generatorUsage) {
        contractTypes = contractTypes.filter(el => el.value !== '2')
      }
      return contractTypes
    },
    subProductTypesForSelect () {
      let subProductTypes = this.subProductTypes
        .filter(el => el.active)
      let index1 = subProductTypes.findIndex(el => el.value === this.request.subProductType)
      let index2 = this.subProductTypes.findIndex(el => el.value === this.request.subProductType)
      if (index1 === -1 && index2 !== -1) {
        let subProductType = { 
          ...this.subProductTypes[index2],
          disabled: true,
        }
        subProductTypes.push(subProductType)
      }
      return subProductTypes
    },
    categoryForSelect () {
      let categories = this.categories
        .filter(el => el.active)
      let index1 = categories.findIndex(el => el.value === this.request.category)
      let index2 = this.categories.findIndex(el => el.value === this.request.category)
      if (index1 === -1 && index2 !== -1) {
        let category = { 
          ...this.categories[index2],
          disabled: true,
        }
        categories.push(category)
      }
      return categories
    },
    listIsSharedDataForSelect () {
      let listIsSharedData = this.listIsSharedData
        .filter(el => el.active)
      let index1 = listIsSharedData.findIndex(el => el.value === this.request.isSharedData)
      let index2 = this.listIsSharedData.findIndex(el => el.value === this.request.isSharedData)
      if (index1 === -1 && index2 !== -1) {
        let category = { 
          ...this.listIsSharedData[index2],
          disabled: true,
        }
        listIsSharedData.push(category)
      }
      return listIsSharedData
    },
    cyberSecurityTypesForSelect () {
      let cyberSecurityTypes = this.cyberSecurityTypes
        .filter(el => el.active)
      let index1 = cyberSecurityTypes.findIndex(el => el.value === this.request.cyberSecurityType)
      let index2 = this.cyberSecurityTypes.findIndex(el => el.value === this.request.cyberSecurityType)
      if (index1 === -1 && index2 !== -1) {
        let category = { 
          ...this.cyberSecurityTypes[index2],
          disabled: true,
        }
        cyberSecurityTypes.push(category)
      }
      return cyberSecurityTypes
    },
    taxRatesForSelect () {
      let taxRates = this.taxRates
        .filter(el => el.active)
      let index1 = taxRates.findIndex(el => el.value === this.request.taxRate)
      let index2 = this.taxRates.findIndex(el => el.value === this.request.taxRate)
      if (index1 === -1 && index2 !== -1) {
        let taxRate = { 
          ...this.taxRates[index2],
          disabled: true,
        }
        taxRates.push(taxRate)
      }
      return taxRates
    },
    invoiceTypesForSelect () {
      let invoiceTypes = this.invoiceTypes
        .filter(el => el.active)
      let index1 = invoiceTypes.findIndex(el => el.value === this.request.invoiceType)
      let index2 = this.invoiceTypes.findIndex(el => el.value === this.request.invoiceType)
      if (index1 === -1 && index2 !== -1) {
        let invoiceType = { 
          ...this.invoiceTypes[index2],
          disabled: true,
        }
        invoiceTypes.push(invoiceType)
      }
      return invoiceTypes
    },
    billingConditionsForSelect () {
      let billingConditions = this.billingConditions
        .filter(el => el.active)
      // let index1 = billingConditions.findIndex(el => el.value === this.request.billingCondition)
      // let index2 = this.billingConditions.findIndex(el => el.value === this.request.billingCondition)
      // if (index1 === -1 && index2 !== -1) {
      //   let billingCondition = { 
      //     ...this.billingConditions[index2],
      //     disabled: true,
      //   }
      //   billingConditions.push(billingCondition)
      // }
      return billingConditions
    },
    paymentTermsForSelect () {
      let paymentTerms = this.paymentTerms
        .filter(el => el.active)
      // let index1 = paymentTerms.findIndex(el => el.value === this.request.paymentTerm)
      // let index2 = this.paymentTerms.findIndex(el => el.value === this.request.paymentTerm)
      // if (index1 === -1 && index2 !== -1) {
      //   let paymentTerm = { 
      //     ...this.paymentTerms[index2],
      //     disabled: true,
      //   }
      //   paymentTerms.push(paymentTerm)
      // }
      return paymentTerms
    },
    currenciesForSelect () {
      let currencies = this.currencies
        .filter(el => el.active)
        .map(el => ({ ...el }))
      let index1 = currencies.findIndex(el => el.value === this.request.type)
      let index2 = this.currencies.findIndex(el => el.value === this.request.type)
      if (index1 === -1 && index2 !== -1) {
        let currency = { 
          ...this.currencies[index2],
          disabled: true,
        }
        currencies.push(currency)
      }
      return currencies
    },
    
    gfkEntityNameInLanguage () {
      const index = this.salesOrgs.findIndex(el => el.value === this.request.gfkEntityName)
      if (index < 0) return null
      switch (this.request.language) {
        case 'Chinese': case 'chinese': case 'CN': case 'Cn': case 'cn': 
          return this.salesOrgs[index].textCn
          break
        default:
          return this.salesOrgs[index].textEn
          break
      }
    },
    invoiceTypeInLanguage () {
      const index = this.invoiceTypesForSelect.findIndex(el => el.value === this.request.invoiceType)
      if (index < 0) return null
      switch (this.request.language) {
        case 'Chinese': case 'chinese': case 'CN': case 'Cn': case 'cn': 
          return this.invoiceTypesForSelect[index].cn
          break
        default:
          return this.invoiceTypesForSelect[index].en
          break
      }
    },
    billingConditionInLanguage () {
      return this.request.billingCondition
      // const index = this.billingConditionsForSelect.findIndex(el => el.value === this.request.billingCondition)
      // if (index < 0) return null
      // switch (this.request.language) {
      //   case 'Chinese': case 'chinese': case 'CN': case 'Cn': case 'cn': 
      //     return this.billingConditionsForSelect[index].cn
      //     break
      //   default:
      //     return this.billingConditionsForSelect[index].en
      //     break
      // }
    },
    currencyInLanguage () {
      const index = this.currenciesForSelect.findIndex(el => el.value === this.request.currency)
      if (index < 0) return null
      switch (this.request.language) {
        case 'Chinese': case 'chinese': case 'CN': case 'Cn': case 'cn': 
          return this.currenciesForSelect[index].textCn
          break
        default:
          return this.currenciesForSelect[index].textEn
          break
      }
    },

    defaultSalesOrg () {
      // Don't care location
      return null
    },

    // approve 第一个
    firstApprover () {
      if (this.needCommercialLeaderL1Approval && !!this.commercialLeaderL1Approver.email) {
        return {
          email: this.commercialLeaderL1Approver.email,
          name: this.commercialLeaderL1Approver.name ?? '',
        }
      }
      if (this.needCommercialLeaderL2Approval&& !!this.commercialLeaderL2Approver.email) {
        return {
          email: this.commercialLeaderL2Approver.email,
          name: this.commercialLeaderL2Approver.name ?? '',
        }
      }
      if (this.needFinanceL1Approval && !!this.financeL1Approver.email) {
        return {
          email: this.financeL1Approver.email,
          name: this.financeL1Approver.name ?? '',
        }
      }
      if (this.needFinanceL2Approval && !!this.financeL2Approver.email) {
        return {
          email: this.financeL2Approver.email,
          name: this.financeL2Approver.name ?? '',
        }
      }
      if (this.needLegalApproval && !!this.request.legalApprover.email) {
        return {
          email: this.request.legalApprover.email,
          name: this.request.legalApprover.name ?? '',
        }
      }
      return {
        email: '',
        name: '',
      }
    },
    
    // signers setting
    intSignerOrganizeNames () {
      let ret = []
      this.intOrganizes.forEach(el => {
        if (el.id && el.name && (ret.indexOf(el.name) === -1)) {
          ret.push(el.name)
        }
      })
      return ret
    },
    extSignerOrganizeNames () {
      let ret = []
      this.extAgentsXOrganizes
        .filter(el => 
          (
            (!this.extSignerOrganizeNameKey && !this.signFlow.extSigner.organize.name)
            || (
              (!!this.extSignerOrganizeNameKey && !!el.orgName && el.orgName.indexOf(this.extSignerOrganizeNameKey) !== -1)
              || (!!this.signFlow.extSigner.organize.name && !!el.orgName && el.orgName.indexOf(this.signFlow.extSigner.organize.name) !== -1)
            )
          )
          && (
            !this.signFlow.extSigner.agent.name || (el.agentName === this.signFlow.extSigner.agent.name)
          )
          && (
            !this.signFlow.extSigner.agent.mobile || (el.agentMobile === this.signFlow.extSigner.agent.mobile)
          )
          && (
            !this.signFlow.extSigner.agent.email || (el.agentEmail === this.signFlow.extSigner.agent.email)
          )
        )
        .forEach(el => {
          if (el.orgId && el.orgName && !ret.find(el2 => el2.name === el.orgName)) {
            ret.push({
              name: el.orgName,
              hasAgent: true,
            })
          }
        })
      this.extOrganizesXAgents.forEach(el => {
        if (el.orgId && el.orgName && !ret.find(el2 => el2.name === el.orgName)) {
          ret.push({
            name: el.orgName,
            hasAgent: false,
          })
        }
      })
      return ret
    },
    extSignerAgents () {
      let ret = []
      this.extOrganizesXAgents
        .filter(el =>
          (
            !!this.signFlow.extSigner.organize.name && (el.orgName === this.signFlow.extSigner.organize.name)
          )
          && (
            (!this.extSignerAgentNameKey && !this.signFlow.extSigner.agent.name)
            || (
              (!!this.extSignerAgentNameKey && !!el.agentName && el.agentName.indexOf(this.extSignerAgentNameKey) !== -1)
              || (!!this.signFlow.extSigner.agent.name && !!el.agentName && el.agentName.indexOf(this.signFlow.extSigner.agent.name) !== -1)
            )
          )
          && (
            (!this.extSignerAgentMobileKey && !this.signFlow.extSigner.agent.mobile)
            || (
              (!!this.extSignerAgentMobileKey && !!el.agentMobile && el.agentMobile.indexOf(this.extSignerAgentMobileKey) !== -1)
              || (!!this.signFlow.extSigner.agent.mobile && !!el.agentMobile && el.agentMobile.indexOf(this.signFlow.extSigner.agent.mobile) !== -1)
            )
          )
          && (
            (!this.extSignerAgentEmailKey && !this.signFlow.extSigner.agent.email)
            || (
              (!!this.extSignerAgentEmailKey && !!el.agentEmail && el.agentEmail.indexOf(this.extSignerAgentEmailKey) !== -1)
              || (!!this.signFlow.extSigner.agent.email && !!el.agentEmail && el.agentEmail.indexOf(this.signFlow.extSigner.agent.email) !== -1)
            )
          )
        )
        .forEach(el => {
          if (
            el.agentId && el.agentName && el.agentMobile && el.agentEmail
            && !ret.find(el2 => el2.id === el.agentId && el2.name === el.agentName && el2.mobile === el.agentMobile && el2.email === el.agentEmail)
          ) {
            ret.push({
              id: el.agentId,
              name: el.agentName,
              mobile: el.agentMobile,
              email: el.agentEmail,
              hasOrg: true,
            })
          }
        })
      this.extAgentsXOrganizes.forEach(el => {
        if (
          el.agentId && el.agentName && el.agentMobile && el.agentEmail
          && !ret.find(el2 => 
            el2.id === el.agentId 
            && el2.name === el.agentName 
            && el2.mobile === el.agentMobile 
            && el2.email === el.agentEmail
          )
        ) {
          ret.push({
            id: el.agentId,
            name: el.agentName,
            mobile: el.agentMobile,
            email: el.agentEmail,
            hasOrg: false,
          })
        }
      })
      return ret
    },
    extSignerAgentNames () {
      let ret = []
      this.extSignerAgents
        .filter(el =>
          (
            (!this.extSignerAgentNameKey || (!!el.name && el.name.indexOf(this.extSignerAgentNameKey) !== -1))
            || (!this.signFlow.extSigner.agent.name || (!!el.name && el.name.indexOf(this.signFlow.extSigner.agent.name) !== -1))
          )
        )
        .forEach(el => {
          if (!ret.find(el2 => el2.name === el.name)) {
            ret.push({
              name: el.name,
              hasOrg: el.hasOrg,
              hasAgent: !!this.extSignerAgents.find(
                el2 => el2.name === el.name
                && (!!this.signFlow.extSigner.agent.mobile || !!this.signFlow.extSigner.agent.email)
                && (el2.mobile === this.signFlow.extSigner.agent.mobile || !this.signFlow.extSigner.agent.mobile)
                && (el2.email === this.signFlow.extSigner.agent.email || !this.signFlow.extSigner.agent.email)
              ),
            })
          }
        })
      ret = ret.sort((a, b) => {
        let pa = a.hasOrg * 1 + a.hasAgent * 10
        let pb = b.hasOrg * 1 + b.hasAgent * 10
        return pb - pa
      })
      return ret
    },
    extSignerAgentMobiles () {
      let ret = []
      this.extSignerAgents
        .filter(el =>
          (
            (!this.extSignerAgentMobileKey || (!!el.mobile && el.mobile.indexOf(this.extSignerAgentMobileKey) !== -1))
            || (!this.signFlow.extSigner.agent.mobile || (!!el.mobile && el.mobile.indexOf(this.signFlow.extSigner.agent.mobile) !== -1))
          )
        )
        .forEach(el => {
          if (!ret.find(el2 => el2.mobile === el.mobile)) {
            ret.push({
              mobile: el.mobile,
              hasOrg: el.hasOrg,
              hasAgent: !!this.extSignerAgents.find(el2 => 
                el2.mobile === el.mobile 
                && (!!this.signFlow.extSigner.agent.name || !!this.signFlow.extSigner.agent.email)
                && (el2.name === this.signFlow.extSigner.agent.name || !this.signFlow.extSigner.agent.name)
                && (el2.email === this.signFlow.extSigner.agent.email || !this.signFlow.extSigner.agent.email)
              ),
            })
          }
        })
      ret = ret.sort((a, b) => {
        let pa = a.hasOrg * 1 + a.hasAgent * 10
        let pb = b.hasOrg * 1 + b.hasAgent * 10
        return pb - pa
      })
      return ret
    },
    extSignerAgentEmails () {
      let ret = []
      this.extSignerAgents
        .filter(el =>
          (
            (!this.extSignerAgentEmailKey || (!!el.email && el.email.indexOf(this.extSignerAgentEmailKey) !== -1))
            || (!this.signFlow.extSigner.agent.email || (!!el.mobile && el.email.indexOf(this.signFlow.extSigner.agent.email) !== -1))
          )
        )
        .forEach(el => {
          if (!ret.find(el2 => el2.email === el.email)) {
            ret.push({
              email: el.email,
              hasOrg: el.hasOrg,
              hasAgent: !!this.extSignerAgents.find(el2 => 
                el2.email === el.email 
                && (!!this.signFlow.extSigner.agent.name || !!this.signFlow.extSigner.agent.mobile)
                && (el2.name === this.signFlow.extSigner.agent.name || !this.signFlow.extSigner.agent.name)
                && (el2.mobile === this.signFlow.extSigner.agent.mobile || !this.signFlow.extSigner.agent.mobile)
              ),
            })
          }
        })
      ret = ret.sort((a, b) => {
        let pa = a.hasOrg * 1 + a.hasAgent * 10
        let pb = b.hasOrg * 1 + b.hasAgent * 10
        return pb - pa
      })
      return ret
    },

    dummyContractSigning () {
      return [{
        name: 'Contract Signing'
      }]
    },
    dummyContractSigned () {
      return this.request.generatorUsage 
        ?
        [{
          name: 'Contract Signed'
        }]
        : 
        this.request.draftedContract
          .map(el => ({
            name: el.name + ' (Signed)'
          }))
    },

    isSharedData () {
      return this.request.isSharedData === '1'
    },
    isCyberSecurityTypesNeedApproval () {
      const type = this.cyberSecurityTypes.find(el => el.value === this.request.cyberSecurityType)
      if (!type) { return false }
      return !!type.needApproval || false
    },
    isDefinedCyberSecurityApproval () {
      return !!this.request.isSharedData && (
        !this.isSharedData ||
        !!this.cyberSecurityTypes.find(el => 
          el.value === this.request.cyberSecurityType))
    },

    isStandardPayment () {
      return +(this.request.paymentTerm) >= 90 ? 'Y' : 'N'
    },
  },
  methods: {
    // ====== vuex ======
    ...mapActions('app', ['setMainTitle']),
    
    // ====== check ======
    // datepicker
    allowedContractEffectiveDates (date) {
      return date >= dateFormat(new Date(), 'YYYY-mm-dd')
      // let ret = true
      // if (this.request.contractSigningDate) {
      //   ret = ret && (date >= this.request.contractSigningDate)
      // }
      // if (this.request.contractEndDate) {
      //   ret = ret && (date <= this.request.contractEndDate)
      // }
      // return ret
    },
    allowedServiceProductDeliveryTime (date) {
      return date >= dateFormat(new Date(), 'YYYY-mm-dd')
      // let ret = true
      // if (this.request.contractSigningDate) {
      //   ret = ret && (date >= this.request.contractSigningDate)
      // }
      // if (this.request.contractEndDate) {
      //   ret = ret && (date <= this.request.contractEndDate)
      // }
      // return ret
    },
    allowedServiceProductStartDate (date) {
      let ret = true
      ret = (date >= dateFormat(new Date(), 'YYYY-mm-dd'))
      if (this.request.serviceProductCompleteDate) {
        ret = ret && (date <= this.request.serviceProductCompleteDate)
      }
      return ret
    },
    allowedServiceProductCompleteDate (date) {
      let ret = true
      ret = (date >= dateFormat(new Date(), 'YYYY-mm-dd'))
      if (this.request.serviceProductStartDate) {
        ret = ret && (date >= this.request.serviceProductStartDate)
      }
      return ret
    },

    // ====== actions ======
    clickCancelNewRequest () {
      this.$router.push('/legal/request/list')
    },
    clickPrevDraftStep () {
      this.isPrevDraftStepConfirming = true
    },
    async clickPrevDraftStepInContractPreviewing () {
      await this.saveDraftRequest('prev', true)
    },
    async clickConfirmPrevDraftStep () {
      await this.saveDraftRequest('prev', false)
      this.isPrevDraftStepConfirming = false
    },
    async clickSaveRequest () {
      await this.saveDraftRequest(null, false)
    },
    async clickSubmitRequest () {
      let validate = true
      if (!!this.$refs['gfk-vendor-contract-type-form']) {
        validate = validate && this.$refs['gfk-vendor-contract-type-form'].validate()
      }
      if (!!this.$refs['gfk-vendor-contract-form']) {
        validate = validate && this.$refs['gfk-vendor-contract-form'].validate()
      }
      if (!!this.$refs['gfk-vendor-contract-generator-form']) {
        validate = validate && this.$refs['gfk-vendor-contract-generator-form'].validate()
      }
      if (!!this.$refs['approval-info-form']) {
        validate = validate && this.$refs['approval-info-form'].validate()
      }
      if (!!this.$refs['gfk-vendor-contract-other-support-info-form']) {
        validate = validate && this.$refs['gfk-vendor-contract-other-support-info-form'].validate()
      }
      if (this.needCommercialLeaderApproval) {
        validate = validate && this.checkCommercialLeaderApprovers
      }
      if (this.needFinanceApproval) {
        validate = validate && this.checkFinanceApprovers
      }
      if (this.needLegalApproval) {
        validate = validate && this.checkLegalApprovers
      }

      this.isApprovalInfoChecked = true

      if (!validate) return

      this.showSendComments(true)
    },
    async clickNextDraftStep () {
      this.isRequestInfoChecked = true
      if (this.isDraftStepInRequestType) {
        if (!this.$refs['gfk-vendor-contract-type-form'].validate()) return
        await this.saveDraftRequest(!this.request.generatorUsage ? 'next' : null, this.request.generatorUsage)
        this.isGeneratorDisplayed = this.request.generatorUsage
      } else if (this.isDraftStepInContractInfo) {
        if (!this.$refs['gfk-vendor-contract-form'].validate()) return
        if (this.needOriginalContract && (this.request.originalContractType === 'Upload') && !this.request.uploadedOriginalContracts.length) return
        await this.saveDraftRequest('next', false)
      } else if (this.isDraftStepInOtherSupportInfo) {
        if (!this.$refs['gfk-vendor-contract-other-support-info-form'].validate()) return
        if (this.needSvpGlobalProcurementApprovalPdf && !this.request.listSvpGlobalProcurementApprovalPdf.length) return
        await this.saveDraftRequest('next', false)
      } else {
        await this.saveDraftRequest('next', false)
      }
    },
    clickCloseGenerator () {
      this.isGeneratorDisplayed = false
      this.saveDraftRequest(null, true)
    },
    clickDraftComments () {
      this.showSendComments(false)
    },

    clickOkContractEffectiveDate () {
      this.$refs['gfk_vendor_contract_form_contract_effective_date'].save(this.request.contractEffectiveDate)
    },
    clickOkContractEffectiveDateGen () {
      this.$refs['gfk_vendor_contract_generator_form_contract_effective_date'].save(this.request.contractEffectiveDate)
    },
    clickOkServiceProductDeliveryTime () {
      this.$refs['gfk_vendor_contract_form_service_product_delivery_time'].save(this.request.serviceProductDeliveryTime)
    },
    clickOkServiceProductDeliveryTimeGen () {
      this.$refs['gfk_vendor_contract_generator_form_service_product_delivery_time'].save(this.request.serviceProductDeliveryTime)
    },
    clickOkServiceProductStartDate () {
      this.$refs['gfk_vendor_contract_form_service_product_start_date'].save(this.request.serviceProductStartDate)
    },
    clickOkServiceProductStartDateGen () {
      this.$refs['gfk_vendor_contract_generator_form_service_product_start_date'].save(this.request.serviceProductStartDate)
    },
    clickOkServiceProductCompleteDate () {
      this.$refs['gfk_vendor_contract_form_service_product_complete_date'].save(this.request.serviceProductCompleteDate)
    },
    clickOkServiceProductCompleteDateGen () {
      this.$refs['gfk_vendor_contract_generator_form_service_product_complete_date'].save(this.request.serviceProductCompleteDate)
    },
    clickUploadNewDraftedContract () {
      this.newDraftedContract.files = []
      this.newDraftedContract.comments = ''
      this.isNewDraftedContractUploadCardDisplayed = true
    },
    clickConfirmNewDraftedContract () {
      if (this.newDraftedContract.files.length < 1) {
        this.$eventBus.$emit('snackbar', { text: 'Please upload new contract', type: 'warning' })
        return
      }
      this.isNewDraftedContractUploadConfirming = true
    },
    async clickSubmitRequestFinal () {
      // submit
      this.$eventBus.$emit('snackbar', { text: 'Sending Request for Approval', type: 'loading' })

      // save
      let token = await this.saveDraftRequest(null, true)
      if (!token) {
        this.$eventBus.$emit('snackbar', { text: 'Fail to send request', type: 'error' })
        return
      }

      let result = await this.postComment(token, true)

      if (result === 'success') {
        this.closeSendComments()
        this.$eventBus.$emit('snackbar', { text: 'Request has been sent for approval', type: 'success' })
      } else if (result === 'failure stage') {
        this.$eventBus.$emit('snackbar', { text: 'Fail to send request, since request status has been changed.', type: 'error' })
      } else if (result === 'failure') {
        this.$eventBus.$emit('snackbar', { text: 'Fail to send request', type: 'error' })
      }
    },
    async clickSendComments () {
      this.$eventBus.$emit('snackbar', { text: 'Sending Comments', type: 'loading' })
      
      let result = await this.postComment(this.token, false)

      if (result === 'success') {
        this.closeSendComments()
        this.$eventBus.$emit('snackbar', { text: 'Comments Sent', type: 'success' })
      } else if (result === 'failure stage') {
        this.$eventBus.$emit('snackbar', { text: 'Fail to send comments, since request status has been changed.', type: 'error' })
      } else if (result === 'failure') {
        this.$eventBus.$emit('snackbar', { text: 'Fail to send comments', type: 'error' })
      }
    },
    clickRecall () {
      if (this.canRecall) {
        if (this.cntCanRecall === 1) {
          this.recall.type = 
            this.canRecallSubmit ? 1 :
            this.canRecallSign ? 2 : null
        } else {
          if (this.$refs['gfk-vendor-contract-recall-form']) {
            this.$refs['gfk-vendor-contract-recall-form'].resetValidation()
          }          
          this.recall.type = null
        }
        this.isRequestRecalling = true
      }
    },

    clickAddSubcontractor () {
      this.request.personalDataSubcontractorDetails.push({
        name: null,
        processDetails: null,
      })
    },
    clickRemoveSubcontractor (index) {
      this.request.personalDataSubcontractorDetails.splice(index, 1)
    },

    blurEstimatedContractValue () {
      let floAmount = parseFloat(this.request.estimatedContractValue)
      if (isNaN(floAmount)) return ''
      this.request.estimatedContractValue = floAmount.toFixed(2).toString() || null
    },
    blurContractValueIncludeTax () {
      let floAmount = parseFloat(this.request.contractValueIncludeTax)
      if (isNaN(floAmount)) return ''
      this.request.contractValueIncludeTax = floAmount.toFixed(2).toString() || null
    },

    inputExtSignerOrganizeName () {
      this.extSignerOrganizeNameKey = this.signFlow.extSigner.organize.name
    },
    inputExtSignerAgentName () {
      this.extSignerAgentNameKey = this.signFlow.extSigner.agent.name
    },
    inputExtSignerAgentMobile () {
      this.extSignerAgentMobileKey = this.signFlow.extSigner.agent.mobile
    },
    inputExtSignerAgentEmail () {
      this.extSignerAgentEmailKey = this.signFlow.extSigner.agent.email
    },
    
    deleteAdditionalAttachment (data) {
      this.request.additionalAttachments.splice(data.index, 1)
      if (this.isGeneratorDisplayed) { this.additionalAttachmentHtmls.splice(data.index, 1) }
    },    
    deleteAttachmentThirteen (data) {
      this.request.attachmentThirteens.splice(data.index, 1)
      if (this.isGeneratorDisplayed) { this.attachmentThirteenHtmls.splice(data.index, 1) }
    },
    deleteDraftedContract (data) {
      this.request.draftedContract.splice(data.index, 1)
    },
    deleteNewDraftedContract (data) {
      this.newDraftedContract.files.splice(data.index, 1)
    },
    deleteOriginalContracts (data) {
      this.request.uploadedOriginalContracts.splice(data.index, 1)
    },
    deleteAttachment (data) {
      this.comments.files.splice(data.index, 1)
    },
    deleteSvpGlobalProcurementApprovalPdf (data) {
      this.request.listSvpGlobalProcurementApprovalPdf.splice(data.index, 1)
    },
    
    async downloadFile (data) {
      try {
        const res = await xDownload(
          data.item.url,
          data.item.name,
          { 
            loginStatus: this.loginStatus
          }
        )
        try {
          if (res.data.message === 'Authorize Failed') {
            this.$router.push('/login')
            return
          }
        } catch (e) {throw ''}
      } catch (e) {
        console.log('Error [download file]')
        // console.log(e)
      }
    },
    async downloadContractDoc() {
      let url = (
        await this.$refs[this.refMsalFunction].getDownloadUrlByPath(
          this.isApprovedRequest ? this.request.contractPdfMsPath : this.request.contractDocMsPath
        )
      ) || null
      if (!url) return
      const elemIF = document.createElement('iframe')
      elemIF.src = url
      elemIF.style.display = 'none'
      document.body.appendChild(elemIF)
    },
    async downloadSignFile (index) {
      let url = await this.postGetEsignDownloadUrl(index)
      if (!url) return

      const elemIF = document.createElement('iframe')
      elemIF.src = url
      elemIF.style.display = 'none'
      document.body.appendChild(elemIF)
    },

    // on change - check / generator focus
    changeGfkEntityName (value) {
      // do nothing
    },
    changeVendorEntityName (value) {
      // do nothing
    },
    changeTaxRate (value) {
      // do nothing
    },
    changeInvoiceType (value) {
      // do nothing
    },
    changeBillingCondition (value) {
      // do nothing
    },
    changePaymentTerm (value) {
      // do nothing
    },
    changeNameOfServiceProduct (value) {
      // do nothing
    },
    changeSpecificationOfServiceProduct (value) {
      // do nothing
    },
    changeServiceProductDeliveryPlace (value) {
      // do nothing
    },
    changeOtherRequirements (value) {
      // do nothing
    },
    changeCurrency (value) {
      // do nothing
    },
    changeEstimatedContractValue (value) {
      let tmpEstimatedContractValue = parseFloat(this.request.estimatedContractValue)
      if (isNaN(tmpEstimatedContractValue)) {
        tmpEstimatedContractValue = null
      } else {
        tmpEstimatedContractValue = tmpEstimatedContractValue.toFixed(2).toString()
      }
      tmpEstimatedContractValue = this.needEstimatedContractValue ? tmpEstimatedContractValue : null

      // no need in template
      // // do nothing
    },
    changeContractValueIncludeTax (value) {
      let tmpContractValueIncludeTax = parseFloat(this.request.contractValueIncludeTax)
      if (isNaN(tmpContractValueIncludeTax)) {
        tmpContractValueIncludeTax = null
      } else {
        tmpContractValueIncludeTax = tmpContractValueIncludeTax.toFixed(2).toString()
      }
      tmpContractValueIncludeTax = this.needContractValueIncludeTax ? tmpContractValueIncludeTax : null

      // do nothing
    },
    changeProjectName (value) {
      // do nothing
    },

    // ====== functions ======
    // 页面初始化
    async init () {
      this.isPageLoading = true
      if (this.isNewRequest) {
        await this.postGetParams()
        this.presetData()
      } else {
        this.$eventBus.$emit('clearNotification', {
          type: 'GfK Vendor Contract',
          token: this.token
        })
        await this.refreshPage()
      }
      if (!this.canView) {
        this.$router.push('/legal/request/list')
      }
      this.isPageLoading = false
    },
    // new case 设置初始数据
    presetData () {
      this.request.requestor.email = this.userEmail
      this.request.requestor.name = this.userName
      this.request.requestor.icon = this.userPhotoUrl
      this.request.team = this.userTeam
      this.request.draftStep = '1'
      this.history = []
    },
    // 重载页面
    async refreshPage () {
      this.isRequestInfoChecked = false

      await this.postGetParams()

      let postGetRequestInfo = this.postGetRequestInfo()
      let postSignFlowInfo = this.postSignFlowInfo()
      let postGetHistory = this.postGetHistory()
      await postGetRequestInfo
      await postSignFlowInfo
      await postGetHistory
    },
    // 保存 draft request
    async saveDraftRequest (stepTo, silent) {
      stepTo = stepTo || null
      silent = silent || false

      if (!silent) this.$eventBus.$emit('snackbar', { text: 'Saving', type: 'loading' })

      let token = await this.postSaveDraftRequest(stepTo)
      if (!!token) { await this.refreshPage() }

      if (!silent) {
        if (!!token) {
          this.$eventBus.$emit('snackbar', { text: 'Request Saved', type: 'success' })
        } else {
          this.$eventBus.$emit('snackbar', { text: 'Fail to save request', type: 'error' })
        }
      }

      return token
    },
    async getVendorEntityNameItems () {
      let keyword = this.vendorEntityNameKey
      let postGetVendorByKeyword = this.postGetVendorByKeyword(keyword)
      let resp = await postGetVendorByKeyword
      if ((resp instanceof Array) && keyword === this.vendorEntityNameKey) {
        this.vendorEntityNameItems = resp
      }
    },
    async getOriginalContractRequestIdKeyItems () {
      let keyword = this.originalContractRequestIdKey
      let postGetRequestIdByKeyword = this.postGetRequestIdByKeyword(keyword)
      let resp = await postGetRequestIdByKeyword
      if ((resp instanceof Array) && keyword === this.originalContractRequestIdKey) {
        this.originalContractRequestIdItems = resp
      }
    },
    
    async getIntOrgs () {
      this.isIntSignerLoading = true
      let postGetIntOrgs = this.postGetIntOrgs()
      this.intOrganizes = await postGetIntOrgs
      this.isIntSignerLoading = false
    },
    async getExtOrgsWithAgents (val) {
      this.isExtSignerLoading = true
      let postGetExtOrgs = this.postGetExtOrgs(val)
      let resp = await postGetExtOrgs
      if ((resp instanceof Array) && val === this.extSignerOrganizeNameKey) {
        this.extOrganizesXAgents = resp
      }
      this.isExtSignerLoading = false
    },
    async getExtAgentsWithOrgs (val, type) {
      this.isExtSignerLoading = true
      type = type || ''
      this.flagPostExtAgents[0] = val
      this.flagPostExtAgents[1] = type
      let postGetExtAgents = this.postGetExtAgents(val, type)
      let resp = await postGetExtAgents
      if ((resp instanceof Array) && val === this.flagPostExtAgents[0] && type === this.flagPostExtAgents[1]) {
        this.extAgentsXOrganizes = resp
      }
      this.isExtSignerLoading = false
    },    

    checkUserAccess () {
      let warning = ''

      // try {        
      //   if (
      //     (
      //       typeof this.costCenter === 'undefined'
      //       || this.costCenter.length < 7
      //     ) 
      //     && (this.extraRequestors.map(x => x.toLowerCase()).indexOf(this.loginStatus.userInfo.primaryEmail.toLowerCase()) === -1)
      //   ) {
      //     warning = 'You don\'t have right to handle request.'
      //   }
      // } catch (err) {
      //   console.log('Error [warn user access]')
      //   // console.log(err)
      // }

      if (warning) this.$eventBus.$emit('snackbar', { text: warning, type: 'warning' })
      return !warning
    },
    
    showSendComments(isToSumbit) {
      this.resetComments()
      if (!!isToSumbit) {this.isContractSubmitting = true}
      this.isCommentsSending = true
    },
    closeSendComments() {
      this.isCommentsSending = false
      this.isContractSubmitting = false
    },
    resetComments () {
      if (this.$refs['contract-comment-form']) {
        this.$refs['contract-comment-form'].resetValidation()
      }
      this.comments.content = null
      this.comments.files = []
    },

    beforeUploadDraftedContract(file) {
      this.isDraftedContractUploading = true
    },
    errorUploadDraftedContract(err, file) {
      console.log('Error [upload drafted contract]')
      // console.log(err)
      // console.log(file)
      this.isDraftedContractUploading = false
    },
    successUploadDraftedContract(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.request.draftedContract.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 1,
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload drafted contract]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isDraftedContractUploading = false
    },

    beforeUploadNewDraftedContract(file) {
      this.isNewDraftedContractUploading = true
    },
    errorUploadNewDraftedContract(err, file) {
      console.log('Error [upload new drafted contract]')
      // console.log(err)
      // console.log(file)
      this.isNewDraftedContractUploading = false
    },
    successUploadNewDraftedContract(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.newDraftedContract.files.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 1,
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload new drafted contract]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isNewDraftedContractUploading = false
    },

    beforeUploadAdditionalAttachment(file) {
      this.isAdditionalAttachmentUploading = true
    },
    errorUploadAdditionalAttachment(err, file) {
      console.log('Error [upload additional attachment]')
      // console.log(err)
      // console.log(file)
      this.isAdditionalAttachmentUploading = false
    },
    async successUploadAdditionalAttachment(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.request.additionalAttachments = [{
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 2,
          UploadTime: time,
          token: this.token
        }]
        this.isAdditionalAttachmentUploadConfirming = false

        if (this.isGeneratorDisplayed) {
          await this.postSaveDraftRequest()
          // let token = await this.postSaveDraftRequest()
        }
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload additional attachment]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isAdditionalAttachmentUploading = false
    },

    beforeUploadAttachmentThirteen(file) {
      this.isAttachmentThirteenUploading = true
    },
    errorUploadAttachmentThirteen(err, file) {
      console.log('Error [upload attachment 9]')
      // console.log(err)
      // console.log(file)
      this.isAttachmentThirteenUploading = false
    },
    async successUploadAttachmentThirteen(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.request.attachmentThirteens = [{
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 3,
          UploadTime: time,
          token: this.token
        }]
        this.isAttachmentThirteenUploadConfirming = false

        if (this.isGeneratorDisplayed) {
          await this.postSaveDraftRequest()
          // let token = await this.postSaveDraftRequest()
        }
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload attachment 9]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isAttachmentThirteenUploading = false
    },

    beforeUploadOriginalContract(file) {
      this.isOriginalContractUploading = true
    },
    errorUploadOriginalContract(err, file) {
      console.log('Error [upload original contract]')
      // console.log(err)
      // console.log(file)
      this.isOriginalContractUploading = false
    },
    async successUploadOriginalContract(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.request.uploadedOriginalContracts = [{
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 4,
          UploadTime: time,
          token: this.token
        }]
        this.isOriginalContractUploadConfirming = false

        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload original]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isOriginalContractUploading = false
    },

    beforeUploadAttachment(file) {
      this.isAttachmentUploading = true
    },
    errorUploadAttachment(err, file) {
      console.log('Error [upload attachment]')
      // console.log(err)
      // console.log(file)
      this.isAttachmentUploading = false
    },
    successUploadAttachment(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.comments.files.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 0,
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload attachment]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isAttachmentUploading = false
    },

    beforeUploadSvpGlobalProcurementApprovalPdf(file) {
      this.isSvpGlobalProcurementApprovalPdfUploading = true
    },
    errorUploadSvpGlobalProcurementApprovalPdf(err, file) {
      console.log('Error [upload svp global procurement approval pdf]')
      // console.log(err)
      // console.log(file)
      this.isSvpGlobalProcurementApprovalPdfUploading = false
    },
    successUploadSvpGlobalProcurementApprovalPdf(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.request.listSvpGlobalProcurementApprovalPdf = [{
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 1,
          UploadTime: time,
          token: this.token
        }]
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload svp global procurement approval pdf]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isSvpGlobalProcurementApprovalPdfUploading = false
    },

    showShareTo () {
      this.isAccessSharing = true
    },
    closeShareTo () {
      this.isAccessSharing = false
    },

    clickStartSign () {
      const activeSalesOrg = this.salesOrgsForSelect.find(el => el.value === this.request.gfkEntityName)
      // check GfK entity
      if (this.needGfkEntityName && !activeSalesOrg) {
        this.isGfkEntityExpiredAlertDisplaying = true
      } else if (this.needGfkEntityName && !activeSalesOrg?.signable) {
        this.isGfkEntityPhysicalSignOnlyDisplaying = true
      } else {
        this.startSign()
      }
    },
    async startSign () {
      await this.postGetSignConf()
      if (!this.signConf.defSignType) {
        this.isSignTypeSelecting = true
      } else {
        this.signFlow.type = this.signConf.defSignType
        await this.finishSelectType()
      }
    },
    async finishSelectType () {
      this.isSignTypeNextLoading = true
      await this.getIntOrgs()
      this.isSignTypeNextLoading = false

      // if manual selecting
      this.isSignTypeSelecting = false

      this.signFlow.extSigner.need = (this.signConf.needExtSign && (this.signFlow.type === 2))
      this.startSignerSelecting()
    },
    startSignerSelecting () {
      if (this.$refs['gfk-vendor-contract-signer-form']) {
        this.$refs['gfk-vendor-contract-signer-form'].resetValidation()
      }
      this.signFlow.intSigner.organize.name = null
      this.signFlow.extSigner.organize.name = null
      this.signFlow.extSigner.agent.name = null
      this.signFlow.extSigner.agent.mobile = null
      this.signFlow.extSigner.agent.email = null
      this.isSignerSelecting = true
    },
    async createSignFlow () {
      let validate = true
      if (!!this.$refs['gfk-vendor-contract-signer-form']) {
        validate = validate && this.$refs['gfk-vendor-contract-signer-form'].validate()
      }
      if (!validate) return

      this.isSignFlowCreating = true
      // ids
      let intOrg = this.intOrganizes.find(el => el.name === this.signFlow.intSigner.organize.name)
      
      if (this.signFlow.extSigner.need) {
        let validationExtSigner = true
        let extOrg = this.extOrganizesXAgents.find(el => el.orgName === this.signFlow.extSigner.organize.name)
        if (!extOrg) {
          this.msgExtOrg = 'Legal Entity Not Found: New legal entity will be created.'
          validationExtSigner = false
        }
        let extAgent = this.extAgentsXOrganizes.find(el => el.agentName === this.signFlow.extSigner.agent.name && el.agentMobile === this.signFlow.extSigner.agent.mobile && el.agentEmail === this.signFlow.extSigner.agent.email)
        if (!extAgent) {
          this.msgExtAgent = 'Liaison (Name/Mobile/Email) Not Found: New liaison will be created.'
          validationExtSigner = false
        }
        let extOrgXAgent = this.extAgentsXOrganizes.find(el => el.orgName === this.signFlow.extSigner.organize.name && el.agentName === this.signFlow.extSigner.agent.name && el.agentMobile === this.signFlow.extSigner.agent.mobile && el.agentEmail === this.signFlow.extSigner.agent.email)
        if (!extOrgXAgent) {
          this.msgExtOrgXAgent = 'Liaison (Name/Mobile/Email) Not Found in Legal Entity: Liaison will be added into Legal Entity.'
          validationExtSigner = false
        }
        this.signFlow.extSigner.binded = validationExtSigner
        if (!validationExtSigner) {
          this.isExtSignerConfirming = true
          return
        }
      }

      await this.confirmCreateSignFlow()
    },
    async clickConfirmExtSigner () {
      this.isExtSignerConfirming = false
      await this.confirmCreateSignFlow()
    },
    cancelConfirmExtSigner () {
      this.isSignFlowCreating = false
    },
    closeConfirmExtSigner () {
      this.isSignFlowCreating = false
    },
    async confirmCreateSignFlow () {
      // post
      let postCreateSignFlow = await this.postCreateSignFlow()
      this.isSignFlowCreating = false
      if (postCreateSignFlow) {
        this.isSignerSelecting = false
        await this.refreshPage()
      } else {
        await this.getExtOrgsWithAgents(this.signFlow.extSigner.organize.name)
        await this.getExtAgentsWithOrgs('', '')
      }
    },
    async clickViewSign () {
      const activeSalesOrg = this.salesOrgsForSelect.find(el => el.value === this.request.gfkEntityName)
      if (!(await this.postGetIntSignPwd())) {
        this.$eventBus.$emit('snackbar', { text: 'Fail to get signing information', type: 'error' })
      } else if (this.needGfkEntityName && !activeSalesOrg) {
        this.isGfkEntityExpiredAlertDisplaying = true
      } else if (this.needGfkEntityName && !activeSalesOrg?.signable) {
        this.isGfkEntityPhysicalSignOnlyDisplaying = true
      } else {
        this.isSignUrlDisplayed = true
      }
    },
    clickIntLink () {
      // DO NOT go to sign when code review
      if (!isProductEnv && isCdRevEnv) return;
      window.open(this.signFlow.intSigner.url)
    },
    clickExtLink () {
      // DO NOT go to sign when code review
      if (!isProductEnv && isCdRevEnv) return;
      window.open(this.signFlow.extSigner.url)
    },
    async clickArchive () {
      this.isContractArchiving = true
    },

    clickApprove () {
      this.approval.config = {}
      this.isRequestApproving = true
    },
    clickCyberSecurityNeedImprovement () {
      this.approval.config = {
        type: 'cyber_security_need_improvement',
        title: 'Need Improvement',
        text: 'Please confirm the improvement need of this request.',
        button: 'Confirm'
      }
      this.isRequestApproving = true
    },

    autoExtSigner (type) {
      let auto = false
      switch (type) {
        case 'orgName':
          auto = (this.extSignerOrganizeNames instanceof Array && !!this.extSignerOrganizeNames.find(el => el.name === this.signFlow.extSigner.organize.name))
          break;
      
        case 'agentName':
          auto = (this.extSignerAgents instanceof Array && !!this.extSignerAgents.find(el => el.name === this.signFlow.extSigner.agent.name))
          break;
      
        case 'agentMobile':
          auto = (this.extSignerAgents instanceof Array && !!this.extSignerAgents.find(el => el.mobile === this.signFlow.extSigner.agent.mobile))
          break;
      
        case 'agentEmail':
          auto = (this.extSignerAgents instanceof Array && !!this.extSignerAgents.find(el => el.email === this.signFlow.extSigner.agent.email))
          break;
      
        default:
          // do nothing
          break;
      }
      if (auto) {
        if (!this.signFlow.extSigner.organize.name && this.extSignerOrganizeNames instanceof Array && this.extSignerOrganizeNames.length === 1) {
          this.signFlow.extSigner.organize.name = this.extSignerOrganizeNames[0].name
        }
        if (!this.signFlow.extSigner.agent.name && this.extSignerAgents instanceof Array && this.extSignerAgents.length === 1) {
          this.signFlow.extSigner.agent.name = this.extSignerAgents[0].name
        }
        if (!this.signFlow.extSigner.agent.mobile && this.extSignerAgents instanceof Array && this.extSignerAgents.length === 1) {
          this.signFlow.extSigner.agent.mobile = this.extSignerAgents[0].mobile
        }
        if (!this.signFlow.extSigner.agent.email && this.extSignerAgents instanceof Array && this.extSignerAgents.length === 1) {
          this.signFlow.extSigner.agent.email = this.extSignerAgents[0].email
        }
      }
    },

    // ====== http request ======
    // --- posts ---
    // 获取列表选项
    async postGetParams () {
      try {
        const req = xPost(
          'gfk_vendor_contract',
          'params',
          {
            loginStatus: this.loginStatus
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          this.salesOrgs = res.data.salesOrgs
            .filter(el => el.id && el.en && el.cn)
            .map(el => ({
              value: el.id,
              active: !!parseInt(el.active ?? 0),
              signable: !!parseInt(el.signable ?? 0),
              textEn: el.en,
              textCn: el.cn,
              text: el.en + ' ' + el.cn,
            }))
          this.currencies = res.data.currencies
            .filter(el => el.id && el.en && el.cn)
            .map(el => ({
              value: el.id,
              active: !!parseInt(el.active),
              textEn: el.en,
              textCn: el.cn,
              text: el.en + ' ' + el.cn,
            }))
          this.fxRate = res.data.fxRate
            .map(el => ({
              currency: el.currency,
              rate: parseFloat(el.rate),
            }))
          this.leaderTitles = res.data.leaderTitles
            .map(el => el.Title)
          this.commercialLeaderL1Approvers = res.data.commercialLeaderL1Approvers
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.commercialLeaderL2Approvers = res.data.commercialLeaderL2Approvers
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.financeL1Approvers = res.data.financeL1Approvers
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.financeL2Approvers = res.data.financeL2Approvers
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.legalApprovers = res.data.legalApprovers
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.requestTypes = res.data.requestTypes
            .filter(el => el.id && (el.en || el.cn))
            .map(el => {
              let en = el.en || ''
              let cn = el.cn || ''
              return {
                value: el.id,
                active: !!parseInt(el.active),
                text: [en, cn].join(' '),
                selectGeneratorUsage: !!parseInt(el.selectGeneratorUsage),
                generatorUsage: !!parseInt(el.generatorUsage),
                needForm: parseInt(el.needForm),
                needProductType: parseInt(el.needProductType),
                needContractType: parseInt(el.needContractType),
                needSubProductType: parseInt(el.needSubProductType),
                needLanguage: parseInt(el.needLanguage),
                needCategory: parseInt(el.needCategory),
                needGfkEntityName: parseInt(el.needGfkEntityName),
                needVendorEntityName: parseInt(el.needVendorEntityName),
                needContractEffectiveDate: parseInt(el.needContractEffectiveDate),
              }
            })            
          this.productTypes = res.data.productTypes
            .filter(el => el.id && (el.en || el.cn))
            .map(el => ({
              value: el.id,
              active: !!parseInt(el.active),
              textEn: el.en,
              textCn: el.cn,
              text: [el.en, el.cn].join(' '),
            }))         
          this.contractTypes = res.data.contractTypes
            .filter(el => el.id && (el.en || el.cn))
            .map(el => {
              let en = el.en || ''
              let cn = el.cn || ''
              return {
                value: el.id,
                active: !!parseInt(el.active),
                textEn: en,
                textCn: cn,
                text: [en, cn].join('/'),
              }
            })
          this.categories = res.data.categories
            .filter(el => el.id && (el.en || el.cn))
            .map(el => {
              let en = el.en || ''
              let cn = el.cn || ''
              return {
                value: el.id,
                active: !!parseInt(el.active),
                textEn: en,
                textCn: cn,
                text: [en, cn].join(' '),
              }
            })
          this.listIsSharedData = res.data.listIsSharedData
            .filter(el => el.id && el.text)
            .map(el => ({
                value: el.id,
                active: !!parseInt(el.active),
                text: el.text,
                na: el.na,
              })
            )
          this.urls = res.data.urls
          this.templates = res.data.templates
            .map(el => ({
              value: el.id,
              fkey: el.fkey,
              content: el.content,
              matchRequestType: el.matchRequestType,
              matchProductType: el.matchProductType,
              matchContractType: el.matchContractType,
              matchSubProductType: el.matchSubProductType,
              matchLanguage: el.matchLanguage,
              needGfkEntityName: parseInt(el.needGfkEntityName) || 0,
              needVendorEntityName: parseInt(el.needVendorEntityName) || 0,
              needTaxRate: parseInt(el.needTaxRate) || 0,
              needInvoiceType: parseInt(el.needInvoiceType) || 0,
              needBillingCondition: parseInt(el.needBillingCondition) || 0,
              needPaymentTerm: parseInt(el.needPaymentTerm) || 0,
              needNameOfServiceProduct: parseInt(el.needNameOfServiceProduct) || 0,
              needSpecificationOfServiceProduct: parseInt(el.needSpecificationOfServiceProduct) || 0,
              needServiceProductDeliveryTime: parseInt(el.needServiceProductDeliveryTime) || 0,
              needServiceProductDeliveryPlace: parseInt(el.needServiceProductDeliveryPlace) || 0,
              needServiceProductStartDate: parseInt(el.needServiceProductStartDate) || 0,
              needServiceProductCompleteDate: parseInt(el.needServiceProductCompleteDate) || 0,
              needOtherRequirements: parseInt(el.needOtherRequirements) || 0,
              needCurrency: parseInt(el.needCurrency) || 0,
              needContractValueIncludeTax: parseInt(el.needContractValueIncludeTax) || 0,
              needProjectName: parseInt(el.needProjectName) || 0,
              needEstimatedContractValue: parseInt(el.needEstimatedContractValue) || 0,
              needConfirmPersonalInfo: parseInt(el.needConfirmPersonalInfo) || 0,
              needGfkContactAddress: parseInt(el.needGfkContactAddress) || 0,
              needGfkZipCodes: parseInt(el.needGfkZipCodes) || 0,
              needGfkContactPerson: parseInt(el.needGfkContactPerson) || 0,
              needGfkContactEmail: parseInt(el.needGfkContactEmail) || 0,
              needVendorContactAddress: parseInt(el.needVendorContactAddress) || 0,
              needVendorZipCodes: parseInt(el.needVendorZipCodes) || 0,
              needVendorContactPerson: parseInt(el.needVendorContactPerson) || 0,
              needVendorContactEmail: parseInt(el.needVendorContactEmail) || 0,
              needPersonalDataPurposeAndUse: parseInt(el.needPersonalDataPurposeAndUse) || 0,
              needPersonalDataSubjectCategory: parseInt(el.needPersonalDataSubjectCategory) || 0,
              needPersonalDataProcessType: parseInt(el.needPersonalDataProcessType) || 0,
              needPersonalDataProcessTerm: parseInt(el.needPersonalDataProcessTerm) || 0,
              needPersonalDataSubcontractor: parseInt(el.needPersonalDataSubcontractor) || 0,
              needAdditionalAttachment: parseInt(el.needAdditionalAttachment) || 0,
              needAttachmentThirteen: parseInt(el.needAttachmentThirteen) || 0,
              needOriginalContract: parseInt(el.needOriginalContract) || 0,
              needOtherSupportInfo: parseInt(el.needOtherSupportInfo) || 0,
              needCommercialLeaderL1Approval: parseInt(el.needCommercialLeaderL1Approval) || 0,
              needCommercialLeaderL2Approval: parseInt(el.needCommercialLeaderL2Approval) || 0,
              needFinanceL1Approval: parseInt(el.needFinanceL1Approval) || 0,
              needFinanceL2Approval: parseInt(el.needFinanceL2Approval) || 0,
              needLegalApproval: parseInt(el.needLegalApproval) || 0,
            }))
          this.archiveApprovers = res.data.archiveApprovers
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.stages = res.data.stages

          this.extraRequestors = res.data.extraRequestors || []
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [post load data - vendor contract options]')
        console.log(err)
        this.$router.push('/legal/request/list')
      }
    },
    async postGetRequestInfo () {
      try {
        const res = await xPost(
          'gfk_vendor_contract',
          'infotoken',
          {
            loginStatus: this.loginStatus,
            token: this.token
          },
        )
        if (res.data.status === 200) {
          this.request.requestId = res.data.info.requestId

          this.request.requestor.email = res.data.info.requestorEmail
          this.request.requestor.name = res.data.info.requestorName
          this.request.requestor.icon = res.data.info.requestorAvatar
          
          this.request.team = res.data.info.requestorTeam

          this.request.draftStep = res.data.info.draftStep
          this.request.type = res.data.info.type
          
          this.request.selectGeneratorUsage = !!parseInt(res.data.info.selectGeneratorUsage)
          this.request.generatorUsage = !!parseInt(res.data.info.generatorUsage)

          this.request.needForm = !!parseInt(res.data.info.needForm)
          this.request.needProductType = !!parseInt(res.data.info.needProductType)
          this.request.needContractType = !!parseInt(res.data.info.needContractType)
          this.request.needSubProductType = !!parseInt(res.data.info.needSubProductType)
          this.request.needCategory = !!parseInt(res.data.info.needCategory)
          this.request.needLanguage = !!parseInt(res.data.info.needLanguage)
          this.request.needGfkEntityName = !!parseInt(res.data.info.needGfkEntityName)
          this.request.needVendorEntityName = !!parseInt(res.data.info.needVendorEntityName)
          this.request.needContractEffectiveDate = !!parseInt(res.data.info.needContractEffectiveDate)
          this.request.needTaxRate = !!parseInt(res.data.info.needTaxRate)
          this.request.needInvoiceType = !!parseInt(res.data.info.needInvoiceType)
          this.request.needBillingCondition = !!parseInt(res.data.info.needBillingCondition)
          this.request.needPaymentTerm = !!parseInt(res.data.info.needPaymentTerm)
          this.request.needNameOfServiceProduct = !!parseInt(res.data.info.needNameOfServiceProduct)
          this.request.needSpecificationOfServiceProduct = !!parseInt(res.data.info.needSpecificationOfServiceProduct)
          this.request.needServiceProductDeliveryTime = !!parseInt(res.data.info.needServiceProductDeliveryTime)
          this.request.needServiceProductDeliveryPlace = !!parseInt(res.data.info.needServiceProductDeliveryPlace)
          this.request.needServiceProductStartDate = !!parseInt(res.data.info.needServiceProductStartDate)
          this.request.needServiceProductCompleteDate = !!parseInt(res.data.info.needServiceProductCompleteDate)
          this.request.needOtherRequirements = !!parseInt(res.data.info.needOtherRequirements)
          this.request.needCurrency = !!parseInt(res.data.info.needCurrency)
          this.request.needContractValueIncludeTax = !!parseInt(res.data.info.needContractValueIncludeTax)
          this.request.needProjectName = !!parseInt(res.data.info.needProjectName)
          this.request.needEstimatedContractValue = !!parseInt(res.data.info.needEstimatedContractValue)
          this.request.needConfirmPersonalInfo = !!parseInt(res.data.info.needConfirmPersonalInfo)
          this.request.needGfkContactAddress = !!parseInt(res.data.info.needGfkContactAddress)
          this.request.needGfkZipCodes = !!parseInt(res.data.info.needGfkZipCodes)
          this.request.needGfkContactPerson = !!parseInt(res.data.info.needGfkContactPerson)
          this.request.needGfkContactEmail = !!parseInt(res.data.info.needGfkContactEmail)
          this.request.needVendorContactAddress = !!parseInt(res.data.info.needVendorContactAddress)
          this.request.needVendorZipCodes = !!parseInt(res.data.info.needVendorZipCodes)
          this.request.needVendorContactPerson = !!parseInt(res.data.info.needVendorContactPerson)
          this.request.needVendorContactEmail = !!parseInt(res.data.info.needVendorContactEmail)
          this.request.needPersonalDataPurposeAndUse = !!parseInt(res.data.info.needPersonalDataPurposeAndUse)
          this.request.needPersonalDataSubjectCategory = !!parseInt(res.data.info.needPersonalDataSubjectCategory)
          this.request.needPersonalDataProcessType = !!parseInt(res.data.info.needPersonalDataProcessType)
          this.request.needPersonalDataProcessTerm = !!parseInt(res.data.info.needPersonalDataProcessTerm)
          this.request.needPersonalDataSubcontractor = !!parseInt(res.data.info.needPersonalDataSubcontractor)
          this.request.needAdditionalAttachment = !!parseInt(res.data.info.needAdditionalAttachment)
          this.request.needAttachmentThirteen = !!parseInt(res.data.info.needAttachmentThirteen)
          this.request.needOriginalContract = !!parseInt(res.data.info.needOriginalContract)
          this.request.needOtherSupportInfo = !!parseInt(res.data.info.needOtherSupportInfo)
          this.request.needSvpGlobalProcurementApprovalPdf = !!parseInt(res.data.info.needSvpGlobalProcurementApprovalPdf)
          this.request.needCommercialLeaderL1Approval = !!parseInt(res.data.info.needCommercialLeaderL1Approval)
          this.request.needCommercialLeaderL2Approval = !!parseInt(res.data.info.needCommercialLeaderL2Approval)
          this.request.needFinanceL1Approval = !!parseInt(res.data.info.needFinanceL1Approval)
          this.request.needFinanceL2Approval = !!parseInt(res.data.info.needFinanceL2Approval)
          this.request.needLegalApproval = !!parseInt(res.data.info.needLegalApproval)

          this.request.productType = res.data.info.productType || null
          this.request.contractType = res.data.info.contractType || null
          this.request.subProductType = res.data.info.subProductType || null
          this.request.language = res.data.info.language || null
          this.request.category = res.data.info.category || null
          this.request.gfkEntityName = res.data.info.gfkEntityName || this.defaultSalesOrg || null
          this.request.vendorEntityName = res.data.info.vendorEntityName || null
          this.request.contractEffectiveDate = res.data.info.contractEffectiveDate || null
          this.request.taxRate = res.data.info.taxRate || null
          this.request.invoiceType = res.data.info.invoiceType || null
          this.request.billingCondition = res.data.info.billingCondition || null
          this.request.paymentTerm = res.data.info.paymentTerm || null
          this.request.nameOfServiceProduct = res.data.info.nameOfServiceProduct || null
          this.request.specificationOfServiceProduct = res.data.info.specificationOfServiceProduct || null
          this.request.serviceProductDeliveryTime = res.data.info.serviceProductDeliveryTime || null
          this.request.serviceProductDeliveryPlace = res.data.info.serviceProductDeliveryPlace || null
          this.request.serviceProductStartDate = res.data.info.serviceProductStartDate || null
          this.request.serviceProductCompleteDate = res.data.info.serviceProductCompleteDate || null
          this.request.otherRequirements = res.data.info.otherRequirements || null
          this.request.currency = res.data.info.currency || null
          this.request.contractValueIncludeTax = res.data.info.contractValueIncludeTax || null
          this.request.projectName = res.data.info.projectName || null
          this.request.estimatedContractValue = res.data.info.estimatedContractValue || null

          this.request.isObtainPersonalInfo =
            isNaN(parseInt(res.data.info.isObtainPersonalInfo))
            ? null
            : parseInt(res.data.info.isObtainPersonalInfo)
          this.request.isAuthorizePersonalInfo =
            isNaN(parseInt(res.data.info.isAuthorizePersonalInfo))
            ? null
            : parseInt(res.data.info.isAuthorizePersonalInfo)
            
          this.request.gfkContactAddress = res.data.info.gfkContactAddress || null
          this.request.gfkZipCodes = res.data.info.gfkZipCodes || null
          this.request.gfkContactPerson = res.data.info.gfkContactPerson || null
          this.request.gfkContactEmail = res.data.info.gfkContactEmail || null
          this.request.vendorContactAddress = res.data.info.vendorContactAddress || null
          this.request.vendorZipCodes = res.data.info.vendorZipCodes || null
          this.request.vendorContactPerson = res.data.info.vendorContactPerson || null
          this.request.vendorContactEmail = res.data.info.vendorContactEmail || null

          this.request.personalDataPurposeAndUse = res.data.info.personalDataPurposeAndUse || null
          this.request.personalDataSubjectCategory = res.data.info.personalDataSubjectCategory || null
          this.request.personalDataProcessType = res.data.info.personalDataProcessType || null
          this.request.personalDataProcessTerm = res.data.info.personalDataProcessTerm || null
          
          let tmpPersonalDataSubcontractorDetails = [];
          for (let index = 0; index < 7; index++) {
            if (
              res.data.info['personalDataSubcontractorName' + (index + 1)]
              && res.data.info['personalDataSubcontractorProcessDetails' + (index + 1)]
            ) {
              tmpPersonalDataSubcontractorDetails.push({
                name: res.data.info['personalDataSubcontractorName' + (index + 1)],
                processDetails: res.data.info['personalDataSubcontractorProcessDetails' + (index + 1)],
              })
            }
          }
          if (tmpPersonalDataSubcontractorDetails.length) {
            this.request.personalDataSubcontractorDetails = tmpPersonalDataSubcontractorDetails
          }

          this.request.contractPdfMsPath = res.data.info.contractPdfMsPath || null
          this.request.contractPdfLcPath = res.data.info.contractPdfLcPath || null
          this.request.contractDocMsPath = res.data.info.contractDocMsPath || null
          this.request.contractDocLcPath = res.data.info.contractDocLcPath || null
          
          this.request.isForAdvancePayment = res.data.info.isForAdvancePayment || null

          this.request.originalContractType = res.data.info.originalContractType || null
          this.request.originalContractRequestId = res.data.info.originalContractRequestId || null
          this.originalContractRequestIdKey = this.request.originalContractRequestId
          this.originalContractRequestIdItems = [this.request.originalContractRequestId]
          
          this.request.backgroundBriefing = res.data.info.backgroundBriefing || null
          
          this.request.isSharedData = res.data.info.isSharedData || null
          this.request.cyberSecurityType = res.data.info.cyberSecurityType || null
          this.request.servicenowTicketNumber = res.data.info.servicenowTicketNumber || ''
          
          if (this.needCommercialLeaderL1Approval) {
            this.request.commercialLeaderL1Approver.email = res.data.info.commercialLeaderL1ApproverEmail   || null
            this.request.commercialLeaderL1Approver.name  = res.data.info.commercialLeaderL1ApproverName      || null
            this.request.commercialLeaderL1Approver.icon  = res.data.info.commercialLeaderL1ApproverAvatar  || null
          }
          if (this.needCommercialLeaderL1Approval) {
            this.request.commercialLeaderL2Approver.email = res.data.info.commercialLeaderL2ApproverEmail   || null
            this.request.commercialLeaderL2Approver.name  = res.data.info.commercialLeaderL2ApproverName    || null
            this.request.commercialLeaderL2Approver.icon  = res.data.info.commercialLeaderL2ApproverAvatar  || null
          }
          if (this.needFinanceL1Approval) {
            this.request.financeL1Approver.email  = res.data.info.financeL1ApproverEmail  || null
            this.request.financeL1Approver.name   = res.data.info.financeL1ApproverName   || null
            this.request.financeL1Approver.icon   = res.data.info.financeL1ApproverAvatar || null
          }
          if (this.needFinanceL2Approval) {
            this.request.financeL2Approver.email  = res.data.info.financeL2ApproverEmail  || null
            this.request.financeL2Approver.name   = res.data.info.financeL2ApproverName   || null
            this.request.financeL2Approver.icon   = res.data.info.financeL2ApproverAvatar || null
          }
          if (this.needLegalApproval) {
            this.request.legalApprover.email = res.data.info.legalApproverEmail || null
            this.request.legalApprover.name = res.data.info.legalApproverName || null
            this.request.legalApprover.icon = res.data.info.legalApproverAvatar || null
          }

          this.request.signType = !!parseInt(res.data.info.signType)
          this.request.needExtSign = !!parseInt(res.data.info.needExtSign)
          this.request.needArchive = !!parseInt(res.data.info.needArchive)
          
          if (this.request.needArchive) {
            this.request.archiveApprover.email = res.data.info.archiveApproverEmail || null
            this.request.archiveApprover.name = res.data.info.archiveApproverName || null
            this.request.archiveApprover.icon = res.data.info.archiveApproverIcon || null
          }

          this.request.canRecallArchive = res.data.recallArchv || false

          let files = res.data.files || []

          let draftedContract = files.filter(el => el.File_Type === '1')
          if (draftedContract.length) {
            this.request.draftedContract = draftedContract.map(el => ({
              url: el.File_Name,
              name: el.Display_Name,
              time: el.Uploaded_Time,
              download: true,
              delete: true,
              DispName: el.Display_Name,
              FileName: el.File_Name,
              type: 1,
              UploadTime: el.Uploaded_Time,
              token: this.token
            })) 
          } else {
            this.request.draftedContract = []
          }

          let additionalAttachments = files.filter(el => el.File_Type === '2')
          if (additionalAttachments.length) {
            this.request.additionalAttachments = additionalAttachments.map(el => ({
              url: el.File_Name,
              name: el.Display_Name,
              time: el.Uploaded_Time,
              download: true,
              delete: true,
              DispName: el.Display_Name,
              FileName: el.File_Name,
              type: 2,
              UploadTime: el.Uploaded_Time,
              token: this.token
            })) 
          } else {
            this.request.additionalAttachments = []
          }

          let attachmentThirteens = files.filter(el => el.File_Type === '3')
          if (attachmentThirteens.length) {
            this.request.attachmentThirteens = attachmentThirteens.map(el => ({
              url: el.File_Name,
              name: el.Display_Name,
              time: el.Uploaded_Time,
              download: true,
              delete: true,
              DispName: el.Display_Name,
              FileName: el.File_Name,
              type: 3,
              UploadTime: el.Uploaded_Time,
              token: this.token
            })) 
          } else {
            this.request.attachmentThirteens = []
          }

          let uploadedOriginalContracts = files.filter(el => el.File_Type === '4')
          if (uploadedOriginalContracts.length) {
            this.request.uploadedOriginalContracts = uploadedOriginalContracts.map(el => ({
              url: el.File_Name,
              name: el.Display_Name,
              time: el.Uploaded_Time,
              download: true,
              delete: true,
              DispName: el.Display_Name,
              FileName: el.File_Name,
              type: 4,
              UploadTime: el.Uploaded_Time,
              token: this.token
            })) 
          } else {
            this.request.uploadedOriginalContracts = []
          }

          let listSvpGlobalProcurementApprovalPdf = files.filter(el => el.File_Type === '5')
          if (listSvpGlobalProcurementApprovalPdf.length) {
            this.request.listSvpGlobalProcurementApprovalPdf = listSvpGlobalProcurementApprovalPdf.map(el => ({
              url: el.File_Name,
              name: el.Display_Name,
              time: el.Uploaded_Time,
              download: true,
              delete: true,
              DispName: el.Display_Name,
              FileName: el.File_Name,
              type: 1,
              UploadTime: el.Uploaded_Time,
              token: this.token
            })) 
          } else {
            this.request.listSvpGlobalProcurementApprovalPdf = []
          }

          let share = res.data.share || []

          this.collaborators = share
            .filter(el => el.role === '1')
            .map(el => ({
              email: el.email,
              name: el.name,
              icon: el.icon,
            }))

          this.ats = share
            .filter(el => el.role === '2')
            .map(el => ({
              email: el.email,
              name: el.name,
              icon: el.icon,
            }))

          this.roles = res.data.roles
        }
      } catch (err) {
        console.log('Error [post load data - get request info]')
        console.log(err)
        this.$router.push('/legal/request/list')
      }
    },
    async postSignFlowInfo () {
      try {
        const res = await xPost(
          'gfk_vendor_contract',
          'signflow',
          {
            loginStatus: this.loginStatus,
            token: this.token
          },
        )
        if (res.data.status === 200) {
          let signFlow = res.data.signFlow
          this.signFlow.esignFlowId = signFlow.esignFlowId || null
          this.signFlow.type = parseInt(signFlow.type) || null
          this.signFlow.extSigner.need = (signFlow.needExtSign !== '0')

          let intSigner = signFlow.signer.find(el => el.type === '1')
          if (!!intSigner) {
            this.signFlow.intSigner.signed = (intSigner.signed !== '0')
            this.signFlow.intSigner.url = intSigner.url
          }

          let extSigner = signFlow.signer.find(el => el.type === '2')
          if (!!extSigner) {
            this.signFlow.extSigner.signed = (extSigner.signed !== '0')
            this.signFlow.extSigner.url = extSigner.url
          }
        }
      } catch (err) {
        console.log('Error [post load data - get sign flow info]')
        console.log(err)
        this.$router.push('/legal/request/list')
      }
    },
    async postGetHistory () {
      try {
        const res = await xPost(
          'gfk_vendor_contract',
          'history',
          {
            loginStatus: this.loginStatus,
            token: this.token
          },
        )
        if (res.data.status === 200) {
          this.history = []
          res.data.history.forEach(el => {
            let index = this.history
              .findIndex(el2 =>
                el2.user.email === el.userEmail &&
                el2.time === el.time &&
                el2.action === el.action &&
                el2.comment === el.comment
              )
            if (index < 0) {
              index = this.history.length
              this.history.push({
                user: {
                  email: el.userEmail || '',
                  name: el.userName || '',
                  icon: el.userIcon || ''
                },
                time: el.time,
                action: el.action,
                display: !!parseInt(el.display),
                stageId: el.stageId,
                comment: el.comment,
                files: [],
                receivers: []
              })
            }
            if (!!el.fileName && !!el.fileDisplayName) {
              this.history[index].files.push({
                url: el.fileName,
                name: el.fileDisplayName
              })
            }
            if (!!el.receiverEmail) {
              this.history[index].receivers.push({
                email: el.receiverEmail || '',
                name: el.receiverName || '',
                icon: el.receiverIcon || ''
              })
            }
          });
        }
      } catch (err) {
        console.log('Error [post load data - get request history]')
        // console.log(err)
        this.$router.push('/legal/request/list')
      }
    },
    async postGetVendorByKeyword (keyword) {
      try {
        keyword = keyword || ''
        const req = xPost(
          'vendor',
          'getbykey',
          {
            loginStatus: this.loginStatus,
            keyword
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          return res.data.response.map(el => el.Name || '').filter((el, i) => !!el)
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get SAP]')
        // console.log(err)
        return
      }
    },
    async postSaveDraftRequest (stepTo) {
      // 返回值 [string]: request token
      let ret = ''

      stepTo = stepTo || null

      let approval = {
        needCommercialLeaderL1Approval: !!this.needCommercialLeaderL1Approval,
        needCommercialLeaderL2Approval: !!this.needCommercialLeaderL2Approval,
        needFinanceL1Approval: !!this.needFinanceL1Approval,
        needFinanceL2Approval: !!this.needFinanceL2Approval,
        needLegalApproval: !!this.needLegalApproval,
      }
      let extraProps = {
        needConfirmPersonalInfo: !!this.needConfirmPersonalInfo,
        needSvpGlobalProcurementApprovalPdf: !!this.needSvpGlobalProcurementApprovalPdf,
      }

      try {
        const res = await xPost(
          'gfk_vendor_contract',
          'savedraft',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            form: this.request,
            default: this.requestTypeObj,
            template: this.templateObj,
            approval,
            extraProps,
            stepTo,
          },
        )
        if (res.data.status === 200) {
          if (this.isNewRequest && res.data.token) {
            this.$router.push('/legal/request/details/gfk-vendor/' + res.data.token)
            ret = res.data.token
          } else {
            ret = this.token
          }
          // this.$eventBus.$emit('refreshGapi')
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [save data - draft request]')
        // console.log(err)
      }

      return ret
    },
    async postGenerateContractPreview () {
      this.isRequestInfoChecked = true
      let valFields = this.$refs['gfk-vendor-contract-generator-form'].validate()
      let valFiles = true
      if (this.needAttachmentThirteen && !this.request.attachmentThirteens.length) { valFiles = false }
      if (!valFields || !valFiles) {
        this.$eventBus.$emit('snackbar', { text: 'Missing information or attachement. Please check.', type: 'warning' })
        return
      }

      this.tfPreviewingContract = new Date()
      let tfPreviewingContract = this.tfPreviewingContract
      this.isPreviewContractLoading = true
      
      try {
        let bigContractValueIncludeTax = !!this.request.contractValueIncludeTax ? transAmountToBig(this.request.contractValueIncludeTax) : ''
        let form = {
          ...this.request,
          token: this.token,
          templateKey: this.templateObj.fkey,
          gfkEntityNameText: this.gfkEntityNameInLanguage,
          invoiceTypeText: this.invoiceTypeInLanguage,
          billingConditionText: this.billingConditionInLanguage,
          currencyText: this.currencyInLanguage,
          bigContractValueIncludeTax,
          needAdditionalAttachment: this.needAdditionalAttachment,
          needAttachmentThirteen: this.needAttachmentThirteen,
        }
        const res = await xPost(
          'gfk_vendor_contract',
          'contractprev',
          {
            loginStatus: this.loginStatus,
            form,
          }
        )
        if (this.isGeneratorDisplayed && tfPreviewingContract === this.tfPreviewingContract) {
          if (res.data.status === 200) {
            this.request.contractPdfMsPath = res.data.pdf
            this.request.contractDocMsPath = res.data.doc
            this.request.contractPdfLcPath = res.data.pdfl
            this.request.contractDocLcPath = res.data.docl
            // this.$eventBus.$emit('refreshGapi')
            setTimeout(async () => {
              if (
                this.request.contractPdfMsPath
                && this.request.contractDocMsPath
                && this.request.contractPdfLcPath
                && this.request.contractDocLcPath
              ) {
                await this.saveDraftRequest('next', false)
                this.isPreviewContractLoading = false
                this.isFreshContractPreviewing = true
              }
            }, 2500);
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
                return
              }
            } catch (e) {}
            throw ''
          }
        }
      } catch (err) {
        console.log('Error [generate preview contract]')
        // console.log(err)
        this.$eventBus.$emit('snackbar', { text: 'Fail to generate contract preview', type: 'error' })
        this.isPreviewContractLoading = false
      }
    },
    async postComment (token, isToSubmit) {
      let ret = 'failure'
      if (!token || token === 'new') { return ret }
      isToSubmit = !!isToSubmit || false
      
      try {
        const res = await xPost(
          'gfk_vendor_contract',
          'comment',
          {
            loginStatus: this.loginStatus,
            token: token,
            submit: isToSubmit,
            stage: this.currStageId,
            content: this.comments.content,
            files: this.comments.files.map(el => el).reverse(),
          }
        )
        if (res.data && res.data.status === 200) {
          await this.refreshPage()
          ret = 'success'
        } else if (res.data.status == 401 && res.data.detail == 'stage') {
          await this.refreshPage()
          ret = 'failure stage'
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return null
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [submit contract]')
        //  console.log(err)
      }

      return ret
    },
    async postRecallRequest () {
      this.isRequestRecalling = false
      this.$eventBus.$emit('snackbar', { text: 'Recalling Contract', type: 'loading' })
      let snackbarParams = { text: 'Fail to recall request', type: 'error' }
      
      try {
        const res = await xPost(
          'gfk_vendor_contract',
          'recall',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.currStageId,
            type: this.recall.type,
          }
        )
        if (res.data.status === 200) {
          await this.refreshPage()
          snackbarParams = { text: 'Contract Recalled', type: 'success' }
          this.isRequestRecalling = false
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to recall request, since request status has been changed.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [recall contract]')
        //  console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async postAbandonRequest () {
      this.isRequestAbandoning = false
      this.$eventBus.$emit('snackbar', { text: 'Abandoning Contract', type: 'loading' })
      let snackbarParams = { text: 'Fail to abandon request', type: 'error' }
      
      try {
        const res = await xPost(
          'gfk_vendor_contract',
          'abandon',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.currStageId,
          }
        )
        if (res.data.status === 200) {
          this.$router.push('/legal/request/list')
          snackbarParams = { text: 'Contract Abandoned', type: 'success' }
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to abandon request, since request status has been changed.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [abandon contract]')
        //  console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async postGetRequestIdByKeyword (keyword) {
      try {
        keyword = keyword || ''
        const req = xPost(
          'workflow',
          'dispidkey',
          {
            loginStatus: this.loginStatus,
            keyword
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          return res.data.data
            .filter(el => !!el.RequestId)
            .map(el => el.RequestId)
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get request id]')
        // console.log(err)
        return
      }
    },
    async postGetContractSignedInfoByRequestId (requestId) {
      try {
        requestId = requestId || ''
        const req = xPost(
          'workflow',
          'csi_rid',
          {
            loginStatus: this.loginStatus,
            requestId
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          this.originalContractRequestContracts =
            res.data.contracts
              .filter(el => !!el && el.FileName)
              .map(el => el.FileName)
          this.originalContractRequestTime = res.data.stage.Time
          this.originalContractRequestStage = res.data.stage.Stage
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get request id]')
        // console.log(err)
        return
      }
    },
    async postReviseDraftedContract () {
      this.isNewDraftedContractUploadConfirming = false
      this.$eventBus.$emit('snackbar', { text: 'Updating Draft Contract', type: 'loading' })
      let snackbarParams = { text: 'Fail to revise draft contract', type: 'error' }
      
      try {
        const res = await xPost(
          'gfk_vendor_contract',
          'revisedraftcontract',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.currStageId,
            content: this.newDraftedContract.comments || '',
            draftedContract: this.newDraftedContract.files
          }
        )
        if (res.data.status === 200) {
          await this.refreshPage()
          snackbarParams = { text: 'Draft Contract Updated', type: 'success' }
          this.isNewDraftedContractUploadCardDisplayed = false
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to revise draft contract, since request status has been changed.', type: 'error' }
          this.isNewDraftedContractUploadCardDisplayed = false
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [update drafted contract]')
        //  console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async postUpdatedCollaborator (members) {
      this.$eventBus.$emit('snackbar', { text: 'Saving Access', type: 'loading' })

      let change = this.updateCollaborator(members)
      if (!change.length) {
        this.$eventBus.$emit('snackbar', { text: 'Change not found', type: 'warning' })
      } else {
        let snackbarParams = { text: 'Fail to save access', type: 'error' }
        try {
          const res = await xPost(
            'gfk_vendor_contract',
            'updateshare',
            {
              loginStatus: this.loginStatus,
              token: this.token,
              role: 1,
              added: change.filter(el => el.caseType === 'added'),
              deleted: change.filter(el => el.caseType === 'deleted'),
            }
          )
          if (res.data.status === 200) {
            snackbarParams = { text: 'Access Saved', type: 'success' }
            this.closeShareTo()
            // this.$eventBus.$emit('refreshGapi')
            setTimeout(async () => {
              await this.refreshPage()
            }, 100);
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
                return
              }
            } catch (e) {}
            throw ''
          }
        } catch (err) {
          console.log('Error [save access]')
          // console.log(err)
        }
        this.$eventBus.$emit('snackbar', snackbarParams)
      }
    },
    async postApproveRequest () {
      this.$eventBus.$emit('snackbar', { text: 'Approving Request', type: 'loading' })
      let snackbarParams = { text: 'Fail to approve request', type: 'error' }
      
      try {
        const res = await xPost(
          'gfk_vendor_contract',
          'approve',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.currStageId,
            content: this.approval.comments.content,
            type: this.approval.config.type || '',
          }
        )
        if (res.data.status === 200) {
          await this.refreshPage()
          this.isRequestApproving = false
          this.isGeneratorDisplayed = false
          snackbarParams = { text: 'Contract Approved', type: 'success' }
          if (!(this.isRequestor || this.isCollaborator || this.canApprove)) {
            this.$router.push('/approval')
          } 
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to approve request, since request status has been changed.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [approve request]')
        //  console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },

    async postGetSignConf () {
      try {
        const res = await xPost(
          'gfk_vendor_contract',
          'signconf',
          {
            loginStatus: this.loginStatus,
            token: this.token
          }
        )
        if (res.data.status === 200) {
          this.signConf.defSignType = res.data.defSignType
          this.signConf.needExtSign = res.data.needExtSign
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get sign conf]')
        //  console.log(err)
      }
    },
    async postCreateSignFlow () {
      let ret = false

      this.$eventBus.$emit('snackbar', { text: 'Creating Sign Flow', type: 'loading' })
      let snackbarParams = { text: 'Fail to create sign flow', type: 'error' }
      
      try {
        const res = await xPost(
          'gfk_vendor_contract',
          'createsign',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.currStageId,
            signFlow: this.signFlow,
            needArchive: this.signConf.needArchive,
          }
        )
        if (res.data.status === 200) {
          await this.refreshPage()
          snackbarParams = { text: 'Sign Flow Created', type: 'success' }
          ret = true
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to create sign flow, since request status has been changed.', type: 'error' }
        } else if (res.data.status === 401 && res.data.detail === 'create_ext_signer_user_exist') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to create liaison, since email address as account exists.', type: 'error' }
        } else if (res.data.status === 401 && res.data.detail === 'create_ext_signer_user_new') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to create liaison. Please confirm the information (Mobile No./ Email) available.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return ret
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [create sign]')
        //  console.log(err)
      }

      this.$eventBus.$emit('snackbar', snackbarParams)
      return ret
    },
    async postGetIntOrgs () {
      try {        
        const res = await xPost(
          'esign',
          'intorgs',
          {
            loginStatus: this.loginStatus,
            rt: 'gfk-vendor',
          }
        )
        if (res.data.status === 200) {
          return res.data.data || []
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get external organizes]')
        // console.log(err)
      }
      return []
    },
    async postGetExtOrgs (val) {
      try {        
        const res = await xPost(
          'esign',
          'extorgsxagents',
          {
            loginStatus: this.loginStatus,
            name: val || null,
          }
        )
        if (res.data.status === 200) {
          return res.data.data || []
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get external organizes]')
        // console.log(err)
      }
      return []
    },
    async postGetExtAgents (val, type) {
      try {        
        let name = this.signFlow.extSigner.agent.name
        let mobile = this.signFlow.extSigner.agent.mobile
        let email = this.signFlow.extSigner.agent.email

        switch (type) {        
          case 'name':
            name = val
            break;
        
          case 'mobile':
            mobile = val
            break;
        
          case 'email':
            email = val
            break;
        
          default:
            break;
        }

        const res = await xPost(
          'esign',
          'extagentsxorgs',
          {
            loginStatus: this.loginStatus,
            name: name || null,
            mobile: mobile || null,
            email: email || null,
          }
        )
        if (res.data.status === 200) {
          return res.data.data || []
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get external organizes]')
        // console.log(err)
      }
      return []
    },
    async postGetIntSignPwd () {
      let ret = false

      try {
        const res = await xPost(
          'esign',
          'intsignpwd',
          {
            loginStatus: this.loginStatus,
          }
        )
        if (res.data.status === 200) {
          this.signFlow.intSigner.pwd = res.data.data || null
          ret = true
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get internal signer pwd]')
        // console.log(err)
      }

      return ret
    },
    async postArchive () {
      this.isContractArchiving = false
      let ret = false

      this.$eventBus.$emit('snackbar', { text: 'Archiving', type: 'loading' })
      let snackbarParams = { text: 'Fail to archive', type: 'error' }
      
      try {
        const res = await xPost(
          'gfk_vendor_contract',
          'archive',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.currStageId,
          }
        )
        if (res.data.status === 200) {
          await this.refreshPage()
          snackbarParams = { text: 'Contract Archived', type: 'success' }
          ret = true
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to archive, since request status has been changed.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return ret
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [archive]')
        //  console.log(err)
      }

      this.$eventBus.$emit('snackbar', snackbarParams)
      return ret
    },
    async postGetEsignDownloadUrl (index) {
      try {
        index = index || 0

        const res = await xPost(
          'esign',
          'esigndownurl',
          {
            loginStatus: this.loginStatus,
            id: this.signFlow.esignFlowId,
            idx: index,
          }
        )
        if (res.data.status === 200) {
          return res.data.data
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return null
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get esign download url]')
        //  console.log(err)
      }

      return null
    },

    // ====== tools ======
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    getRequestProps (prop, propReq) {
      propReq = propReq || prop
      if (this.isPendingRequest) {
        if (this.requestTypeObj) {
          switch (prop) {
            case 'needProductType':
              switch (this.requestTypeObj[prop]) {
                case 0:
                  return false
                  break;
              
                case 1:
                case 2:
                  return true
                  break;
              
                default:
                  // do nothing
                  break;
              }
              break;
              
            case 'needContractType':
              switch (this.requestTypeObj[prop]) {
                case 0:
                  return false
                  break;
              
                case 1:
                  return true
                  break;

                case 2:
                  return (this.needProductType && this.request.productType === '1')
                  break;
              
                default:
                  // do nothing
                  break;
              }
              break;
              
            case 'needSubProductType':
              switch (this.requestTypeObj[prop]) {
                case 0:
                  return false
                  break;
              
                case 1:
                  return true
                  break;

                case 2:
                  return (
                    (this.needProductType && this.request.productType === '1')
                    && (this.needContractType && this.request.contractType === '1')
                  )
                  break;
              
                default:
                  // do nothing
                  break;
              }
              break;
              
            case 'needLanguage':
              switch (this.requestTypeObj[prop]) {
                case 0:
                  return false
                  break;
              
                case 1:
                case 2:
                  return true
                  break;

                case 3:
                  // return (this.needProductType && this.request.productType === '2')
                  return this.needProductType
                  break;
              
                default:
                  // do nothing
                  break;
              }
              break;
          
            default:
              // do nothing
              break;
          }

          return !!this.requestTypeObj[prop]
        } else {
          return false
        }
      } else if (this.request) {
        return this.request[propReq]
      } else {
        return false
      }
    },
    getTemplateProps (prop, propReq) {
      propReq = propReq || prop
      if (this.isPendingRequest) {
        if (this.templateObj) {
          switch (prop) {
            case 'needCommercialLeaderL1Approval':
              switch (this.templateObj[prop]) {
                case 1:
                  return true
              
                default:
                  // do nothing
                  break;
              }
              break;

            case 'needCommercialLeaderL2Approval':
              switch (this.templateObj[prop]) {
                case 1:
                  return true
              
                default:
                  // do nothing
                  break;
              }
              break;

            case 'needFinanceL1Approval':
              switch (this.templateObj[prop]) {
                case 1:
                  return !this.checkContractValueGreater('CNY', 100000, true)
              
                default:
                  // do nothing
                  break;
              }
              break;

            case 'needFinanceL2Approval':
              switch (this.templateObj[prop]) {
                case 1:
                  return this.checkContractValueGreater('CNY', 100000, true)
              
                default:
                  // do nothing
                  break;
              }
              break;
          
            case 'needLegalApproval':
              switch (this.templateObj[prop]) {
                case 1:
                  return !this.request.generatorUsage
                  break;
              
                default:
                  // do nothing
                  break;
              }
              break;

            case 'needGfkContactAddress':
            case 'needGfkZipCodes':
            case 'needGfkContactPerson':
            case 'needGfkContactEmail':
            case 'needVendorContactAddress':
            case 'needVendorZipCodes':
            case 'needVendorContactPerson':
            case 'needVendorContactEmail':
            case 'needPersonalDataPurposeAndUse':
            case 'needPersonalDataSubjectCategory':
            case 'needPersonalDataProcessType':
            case 'needPersonalDataProcessTerm':
            case 'needPersonalDataSubcontractor':
              return this.needConfirmPersonalInfo && (!!this.request.isObtainPersonalInfo || !!this.request.isAuthorizePersonalInfo)

            case 'needSvpGlobalProcurementApprovalPdf':
              return (this.isStandardPayment === 'N') || (this.request.isForAdvancePayment === 'Y')

            default:
              // do nothing
              break;
          }

          return !!this.templateObj[prop]
        } else {
          return false
        }
      } else if (this.request) {
        return this.request[propReq]
      } else {
        return false
      }
    },
    checkContractValueLess(currency, value, orEqualTo) {
      orEqualTo = orEqualTo || false

      let rValue
      if (this.needEstimatedContractValue) {
        rValue = parseFloat(this.request.estimatedContractValue) || null
      }
      if (this.needContractValueIncludeTax) {
        rValue = parseFloat(this.request.contractValueIncludeTax) || null
      }
      if (!rValue) { return false }
      let currencyObj = this.currenciesForSelect.find(el => el.value === this.request.currency)
      if (!currencyObj || !currencyObj.textEn) { return false }

      
      let srcRate = this.fxRate.find(el => el.currency === currencyObj.textEn)
      let tgtRate = this.fxRate.find(el => el.currency === currency)
      if (!srcRate || !tgtRate || !srcRate.rate || !tgtRate.rate) { return false }
      return orEqualTo ? ((rValue * ( srcRate.rate / tgtRate.rate )) <= value) : ((rValue * ( srcRate.rate / tgtRate.rate )) < value)
    },
    checkContractValueGreater(currency, value, orEqualTo) {
      orEqualTo = orEqualTo || false

      let rValue
      if (this.needEstimatedContractValue) {
        rValue = parseFloat(this.request.estimatedContractValue) || null
      }
      if (this.needContractValueIncludeTax) {
        rValue = parseFloat(this.request.contractValueIncludeTax) || null
      }
      if (!rValue) { return false }
      let currencyObj = this.currenciesForSelect.find(el => el.value === this.request.currency)
      if (!currencyObj || !currencyObj.textEn) { return false }

      
      let srcRate = this.fxRate.find(el => el.currency === currencyObj.textEn)
      let tgtRate = this.fxRate.find(el => el.currency === currency)
      if (!srcRate || !tgtRate || !srcRate.rate || !tgtRate.rate) { return false }
      return orEqualTo ? ((rValue * ( srcRate.rate / tgtRate.rate )) >= value) : ((rValue * ( srcRate.rate / tgtRate.rate )) > value)
    },
    checkDoAutoLanguage () {
      if (
        this.isPendingRequest
        && this.request.generatorUsage
        && (
          (
            this.requestTypeObj.needLanguage === 2
            && this.needProductType
            && this.request.productType === '1'
            && this.needContractType
            && this.request.contractType === '1'
          )
          || this.requestTypeObj.needLanguage === 4
        )
      ) {
        this.request.language = 'Chinese'
      }
    },

    // 获取包含内容的 contract html element
    getContractElementWithText(el, text, mode) {
      // 获取子节点
      let children = el.childNodes
      if (children.length) {
        for (var i = 0; i < children.length; i++) {
          // 子节点递归
          let elc = this.getContractElementWithText(children[i], text, mode)
          if (!!elc) return elc
        }
      }

      // 自检
      if (el.tagName && el.tagName.toString().toLowerCase() !== 'style' && el.innerText) {
        if (mode === 0 && el.innerText.indexOf(text) !== -1) { return el }
        if (mode === 1 && el.innerText === text) { return el }
        // if (mode === 2 && el.innerText.indexOf(text) === 0) { return el }
      }
      return null
    },

    updateCollaborator (members) {
      let newList = []
      let change = []
      try {
        newList = members
          .map(el => ({
            email: el.email,
            name: el.name,
            icon: el.icon,
          }))
        change = newList
          .filter(el => this.collaborators.findIndex(el2 => el.email == el2.email) < 0)
          .map(el => ({
              email: el.email,
              name: el.name,
              icon: el.icon,
              caseType: 'added'
            }))
          .concat(
            this.collaborators
              .filter(el => newList.findIndex(el2 => el.email == el2.email) < 0)
              .map(el => ({
              email: el.email,
              name: el.name,
              icon: el.icon,
              caseType: 'deleted'
            }))
          )
        this.collaborators = newList
      } catch (e) {
        return []
      }
      return change
    },

    commentInput (item) {
    },
    commentAt (item) {
    },
    commentAtInsert (item) {
    },

    draftCommentInput (item) {
    },
    draftCommentAt (item) {
    },
    draftCommentAtInsert (item) {
    },

    approvalCommentInput (item) {
    },
    approvalCommentAt (item) {
    },
    approvalCommentAtInsert (item) {
    },

    isEmail (email) {
      return (email.indexOf('@nielsen.com') !== -1) || (email.indexOf('@nielseniq.com') !== -1)
    },

    clickCyberSecurityClassificationGuide () {
      if (this.cyberSecurityClassificationGuide.isRead) { return }
      this.cyberSecurityClassificationGuide.isClicked = true
      this.cyberSecurityClassificationGuide.timeCounterToggle += 1
    },
    doneReadCountCyberSecurityClassificationGuide () {
      this.cyberSecurityClassificationGuide.isRead = true
    },

    clickSevicenowTicket () {
      if (this.servicenowTicket.isDone) { return }
      this.servicenowTicket.isClicked = true
      this.servicenowTicket.timeCounterToggle += 1
    },
    doneServicenowTicket () {
      this.servicenowTicket.isDone = true
    }
  },
  async created () {
    // 设置顶部标题
    this.setMainTitle('GfK Vendor Contract')
    // 页面初始化
    await this.init()
  },  
  watch: {
    token (nv, ov) {
      if (nv !== ov) {
        this.init()
      }
    },
    'request.type' (nv, ov) {
      if (this.isDraftStepInRequestType) {
        let index1 = this.requestTypes.findIndex(el => el.value === nv)
        let index2 = this.requestTypes.findIndex(el => el.value === ov)
        if (index1 !== -1) {
          if (!this.requestTypes[index1].selectGeneratorUsage) {
            this.request.generatorUsage = this.requestTypes[index1].generatorUsage
          } else if (index2 !== -1 && !this.requestTypes[index2].selectGeneratorUsage) {
            this.request.generatorUsage = null
          }
        }
      }
    },
    isPendingRequest: {
      handler (nv, ov) {
        if (!!nv && nv !== ov) {
          this.request.productType = '1'
          // if (this.requestTypeObj.needProductType === 2) {
          //   this.request.productType = '1'
          // }
          this.checkDoAutoLanguage()
        }
      }
    },
    isGeneratorDisplayed: {
      async handler (nv, ov) {
        if (!!nv && nv !== ov) {
          this.contractPreviewSrc = null
          if (this.request.contractPdfMsPath) {
            this.contractPreviewSrc = (
              await this.$refs[this.refMsalFunction].getItemPreviewUrlByPath(this.request.contractPdfMsPath)
            ) || null
          }
        }
        if (!nv) {
          this.isFreshContractPreviewing = false
        }
      }
    },
    'requestTypeObj.needProductType': {
      handler (nv, ov) {
        if (nv !== ov && nv === 2) {
          if (this.isPendingRequest) {
            this.request.productType = '1'
          }
        }
      }
    },
    'request.generatorUsage': {
      handler (nv, ov) {
        if (nv !== ov) {
          this.checkDoAutoLanguage()
        }
      }
    },
    // set sub-product type same as category for GfK vendor form
    'request.category': {
      handler (val) {
        if (this.needSubProductType) {
          this.request.subProductType = val
        }
      }
    },
    'requestTypeObj.needLanguage': {
      handler (nv, ov) {
        if (nv !== ov) {
          this.checkDoAutoLanguage()
        }
      }
    },
    needProductType: {
      handler (nv, ov) {
        if (nv !== ov) {
          this.checkDoAutoLanguage()
        }
      }
    },
    'request.productType': {
      handler (nv, ov) {
        if (nv !== ov) {
          this.checkDoAutoLanguage()
        }
      }
    },
    needContractType: {
      handler (nv, ov) {
        if (nv !== ov) {
          this.checkDoAutoLanguage()
        }
      }
    },
    'request.contractType': {
      handler (nv, ov) {
        if (nv !== ov) {
          this.checkDoAutoLanguage()
        }
      }
    },
    'request.language': {
      handler (nv, ov) {
        if (!!nv && nv !== ov) {
          if (['english', 'en'].indexOf(nv.toLowerCase()) !== -1) {
            let index1 = this.billingConditionsForSelect.findIndex(el => el.cn === this.request.billingCondition)
            if (index1 !== -1) {
              setTimeout(() => {
                this.request.billingCondition = this.billingConditionsForSelect[index1].en
              }, 50);
            }
            
            let index2 = this.arrayGfkAddress.findIndex(el => el.cn === this.request.serviceProductDeliveryPlace)
            if (index2 !== -1) {
              setTimeout(() => {
                this.request.serviceProductDeliveryPlace = this.arrayGfkAddress[index2].en
              }, 50);
            }
          }
          if (['chinese', 'cn'].indexOf(nv.toLowerCase()) !== -1) {
            let index1 = this.billingConditionsForSelect.findIndex(el => el.en === this.request.billingCondition)
            if (index1 !== -1) {
              setTimeout(() => {
                this.request.billingCondition = this.billingConditionsForSelect[index1].cn
              }, 50);
            }

            let index2 = this.arrayGfkAddress.findIndex(el => el.en === this.request.serviceProductDeliveryPlace)
            if (index2 !== -1) {
              setTimeout(() => {
                this.request.serviceProductDeliveryPlace = this.arrayGfkAddress[index2].cn
              }, 50);
            }
          }
        }
      }
    },
    vendorEntityNameKey (val) {
      if (!!val && this.request.vendorEntityName !== val) this.getVendorEntityNameItems()
    },
    originalContractRequestIdKey (val) {
      if (!!val && this.request.originalContractRequestId !== val) this.getOriginalContractRequestIdKeyItems()
    },
    extSignerOrganizeNameKey: {
      async handler (val) {
        await this.getExtOrgsWithAgents(val)
      }
    },
    extSignerAgentNameKey: {
      async handler (val) {
        await this.getExtAgentsWithOrgs(val, 'name')
      }
    },
    extSignerAgentMobileKey: {
      async handler (val) {
        await this.getExtAgentsWithOrgs(val, 'mobile')
      }
    },
    extSignerAgentEmailKey: {
      async handler (val) {
        await this.getExtAgentsWithOrgs(val, 'email')
      }
    },
    'signFlow.extSigner.organize.name': {
      async handler (val, oval) {
        if (!!val) {
          this.autoExtSigner('orgName')
        }
      }
    },
    'signFlow.extSigner.agent.name': {
      async handler (val, oval) {
        if (!!val) {
          this.autoExtSigner('agentName')
        }
      }
    },
    'signFlow.extSigner.agent.mobile': {
      async handler (val, oval) {
        if (!!val) {
          this.autoExtSigner('agentMobile')
        }
      }
    },
    'signFlow.extSigner.agent.email': {
      async handler (val, oval) {
        if (!!val) {
          this.autoExtSigner('agentEmail')
        }
      }
    },
    contractTemplateHtml (nv) {
      this.takeOutPageFoot()
    },
    'request.originalContractRequestId' (nv, ov) {
      if (nv !== ov) {
        this.postGetContractSignedInfoByRequestId(nv)
      }
    },
    'request.contractPdfMsPath': {
      async handler () {
        if (this.isGeneratorDisplayed) { 
          this.contractPreviewSrc = (
            await this.$refs[this.refMsalFunction].getItemPreviewUrlByPath(this.request.contractPdfMsPath)
          ) || null
        }
      }
    },
  },
}
</script>

<style lang="sass">
.gfk-vendor-contract-detail
  width: 100% !important

  button
    margin-top: 3px
    margin-bottom: 3px
  

  .approver-selector-with-details
    display: flex
    align-items: center
  

  .approver-selector-without-details
    padding-right: 40px
  
  
  .extra-button-text
    padding: 8px 14px 8px 8px !important

  .v-input.v-textarea.v-input--is-readonly
    textarea
      color: rgba(0,0,0,.4) !important
  


.gfk-vendor-contract-detail-loading
  width: 100% !important
  text-align: center
  padding-top: calc(50vh - 85px)


.gfk-vendor-contract-radio-group-vertical
  .v-input__control
    width: 100% !important

    .v-radio
      width: 100% !important
      align-items: start

      label
        top: 2px
      
    
  

  .v-input--selection-controls
    margin-top: 0
  


.gfk-vendor-contract-comments-card,
.gfk-vendor-contract-approve-card,
.gfk-vendor-contract-sign-type-card,
.gfk-vendor-contract-contract-preview-card,
.gfk-vendor-contract-generate-contract-card,
.gfk-vendor-contract-new-drafted-contract-card,
.gfk-vendor-contract-gfk-entity-alert-card
  width: 600px !important
  max-width: 100% !important
  // overflow: hidden


.gfk-vendor-contract-signer-card
  width: 450px !important
  max-width: 100% !important


.gfk-vendor-contract-comments-card,
.gfk-vendor-contract-new-drafted-contract-card
  height: 450px !important


.gfk-vendor-contract-confirm-card,
.gfk-vendor-contract-sign-type-card
  width: 400px !important


.gfk-vendor-contract-approve-card
  width: 500px !important
  height: 270px !important


.gfk-vendor-contract-contract-preview-card,
.gfk-vendor-contract-generate-contract-card
  width: 1200px !important
  height: 90vh !important

.gfk-vendor-contract-generate-contract-card,
#gfk-vendor-contract-form-card
  .gfk-responsible-contact-person,
  .payer-entity-contact-person
    .v-autocomplete
      .v-select__slot
        padding: 9px 0

        .v-select__selections
          & > div
            padding-left: 0px !important

            .v-avatar
              margin-right: 12px !important
            
          
        
      
    
  

  .retailer-responsible-contact-person
    & > .v-input
      margin-top: 0px

      input
        height: 50px
        max-height: 50px
      
    
  


.gfk-vendor-contract-comments-container-icon
  display: flex

  i
    margin: 0 10px 0 0
  

  &-bold
    font-weight: 500
  


.gfk-vendor-contract-radio-group-horizonal
  .v-input__control
    width: 100% !important

    .v-radio
      width: calc(50% - 16px) !important
      align-items: start

      label
        top: 2px
      
    
  


.gfk-vendor-contract-approver-no-need
  margin-right: 40px
  padding: 28px 0 13px
  position: relative
  color: rgba(0, 0, 0, .68) !important

  &:before
    bottom: -1px
    content: ""
    left: 0
    position: absolute
    transition: .3s cubic-bezier(.25,.8,.5,1)
    width: 100% !important
    border-color: #d2d2d2!important
    border-style: solid
    border-width: thin 0 0
    border-image: repeating-linear-gradient(90deg,rgba(0,0,0,.38),rgba(0,0,0,.38) 2px,transparent 0,transparent 4px) 1 repeat
  


.gfk-vendor-contract-details-approver-tooltip
  text-align: left


.gfk-vendor-contract-stepper
  // height: 24px

  .v-stepper,
  .v-stepper--vertical
    background: none
    box-shadow: none
    min-height: 0
    margin: 0 -12px
    padding-bottom: 0

    .v-stepper__header
      height: auto
    

    .v-stepper__step
      padding: 0 24px 3px 24px
    

    .v-stepper__header .v-divider
      border-color: hsla(0,0%,100%,.5)
    

    .v-stepper__content
      height: 10px
    
  


.gfk-vendor-contract-comments-container
  .v-input
    padding-top: 0px
  

  .v-input__slot
    padding: 0 9px 9px
  

  .person-select
    .v-input__slot
      padding: 9px
    
    .v-select__selections
      padding-left: 6px
    
  

  textarea
    margin-top: 0px !important
    padding-top: 8px !important

.gfk-vendor-contract-recall-radio-group
  .gfk-vendor-contract-recall-radio-description
    color:  grey
    font-size: 11px
    line-height: 12px
  


.gfk-vendor-contract-sign-con-type-radio-group
  .gfk-vendor-contract-sign-con-type-radio-description
    color:  grey
    font-size: 11px
    line-height: 12px
  


.sign-flow-signer-header,
.sign-flow-info-sign-header
  text-align: left
  font-size: 16px


.sign-flow-ext-signer-error-msg
  color: red
  text-align: left
  font-size: 12px


.sign-flow-info-sign-content
  text-align: left
  font-size: 12px


.v-card-x-layout
  .v-card-x-title
    font-size: 14px
    padding-left: 9px !important
    padding-right: 9px !important
    padding-bottom: 6px !important
  

  .v-card-x-cell
    text-align: left

    .v-card-x-cell-title
      font-size: 14px
      color: rgba(0,0,0,1)
      transform: translateX(-12.5%) scale(.75)
      -webkit-transform: translateX(-12.5%) scale(.75)
      width: 132%

    .v-card-x-cell-title-absolute
      position: absolute
    
  

  .v-card-contract-amount
    .v-label
      font-size: 14px
    

    // .v-messages__message
    //   color: rgba(0, 0, 255, 0.5)
    // 
  


.v-card-x-buttons
  text-align: left


.v-flex-row-breaker
  padding: 0 !important


.bottom-btn-container
  text-align: left

  &-right
    text-align: right
  

  .v-btn,
  &-right .v-btn
    max-width: 150px
    min-width: 150px
  

</style>
